

import Link from 'next/link';
import _ from 'lodash';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';

const Item = ({data, link}) => {

  if(data.customLink){
    return(
      <a href={data.customLink} target="_blank" className="building-developer-item">
        <div>
          <div className="building-developer-item__img" style={{ backgroundImage: `url('${data.img}')` }}/>
        </div>
        <div>
          <div className="building-developer-item__text">{data.text}</div>
          <div className="building-developer-item__date">{data.date}</div>
        </div>
      </a>
    )
  }

  return(
    <Link href={link}>
      <a className="building-developer-item">
        <div>
          <div className="building-developer-item__img" style={{ backgroundImage: `url('${data.img}')` }}/>
        </div>
        <div>
          <div className="building-developer-item__text">{data.text}</div>
          <div className="building-developer-item__date">{data.date}</div>
        </div>
      </a>
    </Link>
  )
}

const News = ({data}) => {

  const mobile = useSelector(state => state.mobile);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const promos = data.promos ? _.chunk(data.promos, 3) : null;
  const news = mobile ? _.chunk(data.news, 3) : _.chunk(data.news, 6);

  return(
    <div>
      {(promos || news) && (
        <div className="building-developer__bottom">
          <div className="building-developer__bottom-in">
            {promos && (
              <div>
                <div className="title">Спецпредложения</div>
                <Slider {...settings} className="building-developer-slider">
                  {promos && promos.map((item, index) => (
                    <div key={`item${index}`}>
                      <div className="building-developer-slide-promos">
                        {item.map(k => <Item key={`item${k.code}`} data={k} link={`/actions/${k.code}`} />)}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            {news && (
              <div className={promos ? '' : 'building-developer__bottom-in-news'}>
                <div className="title">Новости объекта</div>
                <Slider {...settings} className="building-developer-slider">
                  {news && news.map((item, index) => (
                    <div key={`item${index}`}>
                      <div className="building-developer-slide-news">
                        {item.map(k => <Item key={`item${k.code}`} data={k} link={`/articles/${k.code}`}/>)}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default News;
