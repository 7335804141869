import { useEffect } from 'react';

let myMapBuilding = null;

const MapTop = ({latLng, mobile}) => {

  useEffect(() => {
    ymaps.ready(function () {
      if(!myMapBuilding){
        myMapBuilding = new ymaps.Map('building-map', {
          center: latLng,
          zoom: 14,
          controls: ['zoomControl'],
        })

        if(mobile){
          myMapBuilding.behaviors.disable('scrollZoom');
          myMapBuilding.behaviors.disable('drag');
        }
      }

      const myPlacemark = new ymaps.Placemark(latLng, {
        hintContent: '',
      }, {
          iconLayout: 'default#image',
          iconImageHref: '/static/img/apartment-search-by-id/marker.svg',
          iconImageSize: [40, 40],
          iconImageOffset: [-20, -20]
      });

      myMapBuilding.geoObjects.add(myPlacemark);

    });

    return () => {
      if(myMapBuilding){
        myMapBuilding.destroy();
      }
      myMapBuilding = null;
    }
  },[]);

  return(
    <div id="building-map" />
  )
}

export default MapTop;