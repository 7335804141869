import { useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';

const Agents = ({data, api}) => {
  const mobile = useSelector(state => state.mobile);
  const [active, setActive] = useState(false);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if(mobile){
    return(
      <div className="building-agents">
        <div className="title">Агенты</div>
        <Slider {...settings}>
          {data && data.map((item, index) => (
            <div key={`item${index}`}>
              <div className={active ? 'building-agents__item active' : 'building-agents__item'} onClick={() => setActive(!active)}>
                <div className="building-agents__item-img" style={{ backgroundImage: `url(${api}${item.img})` }}>
                  <div>
                    {item.phone && <a className="building-agents__item-phone" href={`tel:${item.phone}`}>{item.phone}</a>}
                    {item.email && <a href={`mailto:${item.email}`}>{item.email}</a>}
                  </div>
                </div>
                <div className="building-agents__item-text">
                  <div dangerouslySetInnerHTML={{ __html: item.name }} />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  }

  return(
    <div className="building-agents">
      <div className="title">Агенты</div>
      <div className="frame">
        <div className={data.length <= 3 ? 'building-agents__list building-agents__list-center' : 'building-agents__list'}>
          {data && data.map((item, index) => (
            <div key={`item${index}`} className="building-agents__item">
              <div className="building-agents__item-img" style={{ backgroundImage: `url(${api}${item.img})` }}>
                <div>
                  {item.phone && <a className="building-agents__item-phone" href={`tel:${item.phone}`}>{item.phone}</a>}
                  {item.email && <a href={`mailto:${item.email}`}>{item.email}</a>}
                </div>
              </div>
              <div className="building-agents__item-text">
                <div dangerouslySetInnerHTML={{ __html: item.name }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Agents;