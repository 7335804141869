import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Breadcrumbs from '../_app/breadcrumbs'
import { useScrollAnimation } from '../../hooks/use-scroll-animation'
import Slider from 'react-slick';
import Item from '../apartment-search/Item';
import {SvgShare, SvgDownload, SvgPedestrian, SvgAuto} from '../../icons';
import Infra from '../apartment-search-by-id/Infra';
//import MortgageProgramm from '../apartment-search-by-id/MortgageProgramm';
//import CalcApartment from '../apartment-search-by-id/CalcApartment';
import CalcApartment from '../apartment-search-by-id/CalcApartmentFlat';
import GalleryTop from './GalleryTop';
import MapTop from './MapTop';
import Filter from './Filter';
import Result from './result';
import Charts from './Charts';
import About from './About';
import Photo from './Photo';
import Video from './Video';
import Ready from './Ready';
import Docs from './Docs';
import Developer from './Developer';
import Agents from './Agents';
import Info from '../index/info';
import Genplan from './Genplan';
import EventsNew from '../index/events/EventsNew';
import Sharing from '../_app/Sharing';
import Elite from '../_app/elite'
import Programms from '../mortgage/Programms';
import News from './News';
import AddFavorite from '../_app/AddFavorite';
import AddRecent from '../_app/AddRecent';
import DiscountForm from '../form/discount-form';
import PromoLabel from './PromoLabel';
import VideoCallForm from '../form/video-call-form'
import BoockingPopupAuth from './BoockingPopupAuth';
import BoockingPopupNoAuth from './BoockingPopupNoAuth';
import HeadMeta from '../_app/HeadMeta';
import CountDownTimer from './CountDownTimer';
import {openModal} from '../../redux/modules/form'
import {connect} from 'react-redux'
import Modal from '../form/modal'
import dynamic from 'next/dynamic'
const PrintNoSSR = dynamic(
    () => import('./Print'),
    { ssr: false }
)

const Building = ({data, api, query,openModal, build_flats, build_events}) => {
    const router = useRouter();
    const mobile = useSelector(state => state.mobile);
  const [flats, setFlats] = useState(build_flats);
  const [flatsLoad, setFlatsLoad] = useState(false);

  const auth = useSelector(state => state.auth);

    const [fixed, setFixed] = useState(false);
    const [upscroll, setUp] = useState(false);
    const [downscroll, setDown] = useState(false);
    const [type, setType] = useState(0);
    const format = new Intl.NumberFormat('ru-RU');
    const [tab, setTab] = useState(0);
    const [subscr, setSubscr] = useState(false);
    const [stickyTop, setStickyTop] = useState(0);
    const [hash, setHash] = useState(null);

    const [popupBookingAuth, setPopupBookingAuth] = useState(false);
    const [popupBookingNoAuth, setPopupBookingNoAuth] = useState(false);

    const [activeMenu, setActiveMenu] = useState(null);

    useEffect(() => {
        if(router.query.type){
            setType(router.query.type);
        }
        /*        if(router.query.slug[1]==='seliger_city'){
                    import('react-facebook-pixel')
                        .then((x) => x.default)
                        .then((ReactPixel) => {
                            ReactPixel.init('439493191183854');
                            ReactPixel.pageView();

                            /!*
                            Router.events.on('routeChangeComplete', () => {
                              ReactPixel.pageView();
                            });

                             *!/
                        });
                }*/


        window.scrollTo(0,0);

        if(document.location.hash){
            const hash = document.location.hash.substr(1);
            setTimeout(() => {
                const elementTop = document.querySelector(`[data-id="${hash}"]`);
                scrollTo(hash)
                if(elementTop){
                    window.scrollTo({
                        top: elementTop.offsetTop,
                        behavior: "smooth"
                    });
                }
            }, 100)
        }

        const detectElement = document.querySelector('.appartment-menu-fixed-detect');

        let lastScrollTop = 0;

        const scroll = () => {
            const st = window.pageYOffset || document.documentElement.scrollTop;
            const scr = window.scrollY;
            const resultTableHeader = document.querySelectorAll('.building-result-table__head')
            const fe = document.querySelector('.appartment-menu-fixed__in');
            const mh = fe ? fe.getBoundingClientRect().height : 0;

            if(resultTableHeader.length){
                for(let i = 0; i < resultTableHeader.length; i++){
                    const rect = resultTableHeader[i].getBoundingClientRect();
                    const treshold = rect.top + window.scrollY;

                    if(scr > treshold - mh){
                        if(mobile){
                            resultTableHeader[i].firstChild.style.transform = `translateY(${(scr - treshold + mh)}px)`;
                        }
                        else{
                            resultTableHeader[i].firstChild.style.transform = `translateY(${(scr - treshold + mh + (st > lastScrollTop ? 0 : 80))}px)`;
                        }
                    }
                    else{
                        resultTableHeader[i].firstChild.style.transform = `translateY(0px)`
                    }
                }
            }


            if(detectElement){
                if(scr >= detectElement.offsetTop){
                    setFixed(true);
                }
                else{
                    setFixed(false);
                }
            }

            const hfix = document.querySelector('.appartment-menu-fixed');
            const hfix2 = document.querySelector('.header-menu__links');

            if (st > lastScrollTop){
                setUp(false)
                setDown(true)

                if(hfix && hfix2){
                    setStickyTop(hfix.offsetHeight);
                }
            }
            else {
                setUp(true)
                setDown(false)

                if(hfix && hfix2){
                    setStickyTop(hfix.offsetHeight + hfix2.offsetHeight);
                }
            }
            lastScrollTop = st;

            const boxes = document.querySelectorAll('div[data-id-boxes]');

            boxes.forEach(item => {
                const hfix = document.querySelector('.appartment-menu-fixed');
                const hfix2 = document.querySelector('.header-menu__links');
                const height = item.getBoundingClientRect().height;

                const h1 = hfix ? hfix.offsetHeight : 0
                const h2 = hfix2 ? hfix2.offsetHeight : 0

                const top = item.getBoundingClientRect().top + scr - h1 - h2;
                const end = top + height;

                if(scr > top - 50 && scr < end - 50){
                    setActiveMenu(item.dataset.id)
                }
            })

        }
        scroll();
        window.addEventListener('scroll', scroll);

        return () => window.removeEventListener('scroll', scroll);
    },[])

    useEffect(() => {
        if(mobile){
            if(document.location.hash){
                const hash = document.location.hash.substr(1);
            }
            changeType(0);


            if(hash){
                setTimeout(function (){
                    const elementTop = document.querySelector(`[data-id="${hash}"]`);
                    scrollTo(hash)
                    if(elementTop){
                        window.scrollTo({
                            top: elementTop.offsetTop,
                            behavior: "smooth"
                        });
                    }
                },0);
            }
        }
    },[mobile])


  useEffect(() => {
    if(build_flats){
      //setFlats(build_flats);
      setFlatsLoad(true);
    }
  },[build_flats])

    const changeType = value => {
        setType(value);
        router.push({
            pathname: `/${router.query.slug[0]}/${router.query.slug[1]}`,
            query: { type: value },
        }, undefined, { shallow: true });

    }

    useScrollAnimation();

    const settings = {
        dots: true,
        infinite: mobile,
        speed: 500,
        slidesToShow: mobile ? 1 : 4,
        slidesToScroll: mobile ? 1 : 4,
        arrows: false,
    };

    const setPopup = trigger => {
        setPopupBookingAuth(trigger);
        if(typeof gtag != 'undefined'){
            gtag('event', 'order_click', {'event_category': 'order_click'});
        }
        if(typeof ym != 'undefined'){
            ym(4831804, 'reachGoal', 'click_on_order');
        }
        // if(trigger){
        //   if(auth){
        //     setPopupBookingAuth(trigger);
        //   }
        //   else{
        //     setPopupBookingNoAuth(trigger);
        //   }
        // }
        // else{
        //   setPopupBookingAuth(trigger);
        //   setPopupBookingNoAuth(trigger);
        // }
    }

    const scrollTo = type => {
        setHash(type);

        let t = type;
        if(type === 'carplace'){
            t = 'filter';
        }
        if(type === 'carplace' || type === 'filter'){

        }

        const el = document.querySelector(`[data-id="${t}"]`);

        router.push(`/${router.query.slug[0]}/${router.query.slug[1]}/#${type}`, undefined, { shallow: true })

        if(el){
            let pad_top = 40;
            const top = el.offsetTop - pad_top;
            window.scrollTo({
                top,
                behavior: "smooth"
            });
            // setTimeout(function (){
            //     const top = el.offsetTop - pad_top;
            //     window.scrollTo({
            //         top,
            //         behavior: "smooth"
            //     });
            // },0)
        }
    }

    const menuData = data.filter(item => item.type === 'menu')[0];

    const unisenderData = data.filter(item => item.type === 'info')[0];


    const  current_video_obj = Array.isArray(unisenderData.videocall_objects) ? unisenderData.videocall_objects.filter(item => item.CODE === router.query.slug[1])[0] : null;


    const current_name = current_video_obj ? current_video_obj.NAME : null;
    const current_id = current_video_obj ? current_video_obj.ID : null;

    const create = () => {
        const array = [];

        const selectData = {
            catalog_new: 'Жилая недвижимость',
          catalog_cre: 'Коммерческая недвижимость',
          catalog_country: 'Загородная недвижимость',
        }

        data.forEach((item => {
            if(item.type === 'seo'){
                array.push(<HeadMeta key={`item${item.type}`} data={item} />);
            }

            if(item.type === 'info'){
                if(!mobile){
                    array.push(
                        <div className="page-title frame">
                            <Breadcrumbs api={api} items={[{name:'Главная',link:'/'},{name:selectData[router.query.slug[0]],link:'/' + router.query.slug[0]},{name:item.name}]} />
                        </div>);
                }
                array.push(
                    <div key={`item${item.type}`}>
                        <div data-id={item.type} className="appartment-by-id__header-wrap" data-id-boxes={item.type} itemScope={"true"} itemType="http://schema.org/Product">
                            <div className="appartment-by-id__header animate">
                                <div className="frame">
                                    <div className="appartment-by-id__header-in">
                                      <div>
                                        {mobile && <div className="appartment-by-id__header-catalog">{selectData[router.query.slug[0]]}</div>}
                                        <div className="appartment-by-id__header-title" itemprop="name"><h1>{item.name}</h1></div>
                                        <div className="appartment-by-id__header-name noline">
                                          {item.addr_city ? item.addr_city : ''}{item.addr_district ? ', '+item.addr_district : ''}{item.addr_area ? ', '+item.addr_area : ''}{', '+item.addr}
                                        </div>

                                        <div className="appartment-by-id__header-metro-wrap">
                                          {item.metro && item.metro.map((i, ind) => (
                                            <div key={`item${ind}`} className="appartment-by-id__header-metro">
                                              <div>
                                                <span style={{backgroundColor: i.color}}>м</span>
                                              </div>
                                              <div>{i.name}</div>
                                              {i.pedestrian &&
                                                <div className={'app_metro_distance'}>
                                                  <SvgPedestrian/>
                                                  <div>{i.pedestrian} мин.</div>
                                                </div>
                                              }
                                              {i.auto &&
                                                <div className={'app_metro_distance'}>
                                                  <SvgAuto/>
                                                  <div>{i.auto} мин.</div>
                                                </div>
                                              }
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="appartment-by-id__header-in-last">
                                          <CountDownTimer priceUpDate={item.priceUpDate} priceUpText={item.priceUpText}/>
                                          <div>
                                            <div>
                                              {item.fromPrice && item.toPrice && item.fromPrice === item.toPrice && (
                                                <div className="appartment-by-id__header-price-wrap-first">
                                                                <div className="appartment-by-id__header-price noline">{format.format(item.fromPrice)} ₽</div>
                                                            </div>
                                                        )}
                                                        {item.fromPrice && item.fromPrice !== item.toPrice  && (
                                                            <div className="appartment-by-id__header-price-wrap-first">
                                                                <div className="appartment-by-id__header-price noline">от {format.format(item.fromPrice)} ₽</div>
                                                            </div>
                                                        )}
                                                        {item.toPrice && item.fromPrice !== item.toPrice && (
                                                            <div className="appartment-by-id__header-price-wrap-first">
                                                                <div className="appartment-by-id__header-price noline">
                                                                    до {format.format(item.toPrice)} ₽
                                                                </div>
                                                            </div>
                                                        )}
                                                        {!!item.fromRentPrice && (
                                                            <div className="appartment-by-id__header-calc">
                                                                <div>от {format.format(item.fromRentPrice)} ₽/месяц</div>
                                                            </div>
                                                        )}
                                                        {!!item.fromRentPriceMeter && (
                                                            <div className="appartment-by-id__header-calc">
                                                                <div>от {format.format(item.fromRentPriceMeter)} ₽/кв.м в месяц</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="appartment-by-id__header-button-wrap ">
                                                        {unisenderData.videocall_objects && <div className="appartment-by-id__header-button appartment-by-id__header-buuton_video_call" onClick={() => openModal('videoCall')}><span>Заявка на видеозвонок</span></div>}
                                                        <div className="appartment-by-id__header-button appartment-by-id__header-button--red" onClick={() => setPopup(true)}><div>Оставить заявку</div><div /></div>
                                                    </div>
                                                    {item.bought && <div className="building-for-buy"><Link href={`/for-those-who-bought/${router.query.slug[0]}/${router.query.slug[1]}`}><a>Для купивших</a></Link></div>}
                                                    {mobile && (
                                                        <div className="appartment-by-id__header-bottom-icons">
                                                            <div>
                                                                <AddFavorite data={{code: router.query.slug[1]}} type={1} api={api} />
                                                                <span>Избранное</span>
                                                            </div>
                                                            <PrintNoSSR url={`${api}/pdf/?page=object&catalog=${router.query.slug[0]}&code=${router.query.slug[1]}`} />
                                                            <a
                                                                target="_blank"
                                                                href={`${api}/pdf/?page=object&catalog=${router.query.slug[0]}&code=${router.query.slug[1]}`}
																																onClick={() => {if(window.ym) ym(4831804,'reachGoal','webit_click_dowload')}}
                                                            >
                                                                <SvgDownload />
                                                                <span>Скачать</span>
                                                            </a>
                                                            <Sharing />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="appartment-by-id__header-bottom">
                                        <div>
                                            <div className="appartment-by-id__header-bottom-menu">
                                                {menuData.data.map((item, i) => {
                                                    const genplan = data.find(el => el.type === "genplan3d")
                                                    return <>
                                                        {genplan && genplan.data && i === 2 && <div  onClick={() => {
                                                            changeType(2);
                                                            document.querySelector('.filter').scrollIntoView({behavior: "smooth"})
                                                        }}>3d-план</div>}
                                                        <div className={item.value == activeMenu ? 'active' : '' } data-key={item.value} key={`item${item.value}`} onClick={() => scrollTo(item.value)}>{item.name}</div>
                                                    </>
                                                })}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="appartment-by-id__header-bottom-icons">
                                                <div>
                                                    <AddFavorite data={{code: router.query.slug[1]}} type={1} api={api} />
                                                    <span>Избранное</span>
                                                </div>
                                                <PrintNoSSR url={`${api}/pdf/?page=object&catalog=${router.query.slug[0]}&code=${router.query.slug[1]}`} />
                                                <a
                                                    target="_blank"
                                                    href={`${api}/pdf/?page=object&catalog=${router.query.slug[0]}&code=${router.query.slug[1]}`}
																										onClick={() => {if(window.ym) ym(4831804,'reachGoal','webit_click_dowload')}}
                                                >
                                                    <SvgDownload />
                                                    <span>Скачать</span>
                                                </a>
                                                <Sharing />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="appartment-menu-fixed-detect" />

                            <div className={fixed ? (upscroll ? 'appartment-menu-fixed scrollup active' : 'appartment-menu-fixed scrolldown active') : 'appartment-menu-fixed'}>
                                <div className="frame">
                                    <div className="appartment-menu-fixed__in">
                                        <div>
                                            <div className="appartment-menu-fixed__top">
                                                <div>
                                                    <div className="appartment-menu-fixed__addr">{item.name}</div>
                                                    <div className="appartment-menu-fixed__name">{item.addr}</div>
                                                </div>
                                                <div>
                                                    <div className="appartment-menu-fixed__top-right">
                                                        <div>
                                                            <CountDownTimer priceUpDate={item.priceUpDate} priceUpText={item.priceUpText} />
                                                        </div>
                                                        <div>
                                                            {item.fromPrice && item.toPrice && item.fromPrice === item.toPrice && <div className="appartment-by-id__header-price noline">{format.format(item.fromPrice)} ₽</div>}
                                                        </div>
                                                        <div>
                                                            {item.fromPrice && item.fromPrice !== item.toPrice  && <div className="appartment-by-id__header-price noline">от {format.format(item.fromPrice)} ₽</div>}
                                                        </div>
                                                        <div>
                                                            {item.toPrice && item.fromPrice !== item.toPrice  && <div className="appartment-by-id__header-price noline">до {format.format(item.toPrice)} ₽</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="appartment-menu-fixed__menu">
                                                {menuData.data.map((item, i) => {
                                                    const genplan = data.find(el => el.type === "genplan3d")
                                                    return <>
                                                        {genplan && genplan.data && i === 2 && <div  onClick={() => {
                                                            changeType(2);
                                                            document.querySelector('.filter').scrollIntoView({behavior: "smooth"})
                                                        }}>3d-план</div>}
                                                        <div
                                                            key={`item${item.value}`}
                                                            data-key={item.value}
                                                            className={item.value == activeMenu ? 'appartment-menu-fixed__menu-item active' : 'appartment-menu-fixed__menu-item'}
                                                            onClick={() => scrollTo(item.value)}
                                                        >
                                                            {item.name}
                                                        </div>
                                                    </>
                                                })}

                                                <div>
                                                    <div className="appartment-by-id__header-bottom-icons">
                                                        <div>
                                                            <AddFavorite data={{code: router.query.slug[1]}} type={1} api={api} />
                                                            <span>Избранное</span>
                                                        </div>
                                                        <PrintNoSSR url={`${api}/pdf/?page=object&catalog=${router.query.slug[0]}&code=${router.query.slug[1]}`} />
                                                        <a
                                                            target="_blank"
                                                            href={`${api}/pdf/?page=object&catalog=${router.query.slug[0]}&code=${router.query.slug[1]}`}
																														onClick={() => {if(window.ym) ym(4831804,'reachGoal','webit_click_dowload')}}
                                                        >
                                                            <SvgDownload />
                                                            <span>Скачать</span>
                                                        </a>
                                                        <Sharing />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="appartment-by-id__header-button-wrap appartment-by-id-video-call-wrap">
                                            {/*<div className="appartment-menu-fixed-button" onClick={() => setPopup(true)}><div>Оставить заявку</div><div /></div>*/}
                                            {unisenderData.videocall_objects && <div className="appartment-by-id__header-button appartment-by-id__header-buuton_video_call" onClick={() => openModal('videoCall')}><span>Заявка на видеозвонок</span></div>}
                                            <div className="appartment-by-id__header-button appartment-by-id__header-button--red" onClick={() => setPopup(true)}><div>Оставить заявку</div><div /></div>
                                            {item.bought && <div className="building-for-buy"><Link href={`/for-those-who-bought/${router.query.slug[0]}/${router.query.slug[1]}`}><a>Для купивших</a></Link></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {item.latLng && (
                                <div className="building-top animate">
                                    <div>
                                        <MapTop latLng={item.latLng} mobile={mobile} />
                                    </div>
                                    <div>
                                        {item.elite && <Elite />}
                                        <GalleryTop data={item.gallery} api={api} />
                                    </div>
                                </div>
                            )}

                            {!item.latLng && <div className="building-top single animate">{item.elite && <Elite />}<GalleryTop data={item.gallery} single api={api} /></div>}

                            {(item.promo || item.optionsHead.length >= 1) && (
                                <>

                                    <div className="frame animate">
                                        <div className="building-options-wrap">
                                            {item.promo && (
                                                <div>
                                                    <PromoLabel data={item.promo} />
                                                </div>
                                            )}
                                            <div>
                                                {item.optionsHead.length >= 1 && (
                                                    <div className="building-options">
                                                        {item.optionsHead && item.optionsHead.map((item, index) => (
                                                            <div key={`item${index}`} >
                                                                {item.name}
                                                                <div dangerouslySetInnerHTML={{ __html: item.value}} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )}
                        </div>
                    </div>
                )
            }

            if(item.type === 'no_filter'){
                array.push(
                    <div className="no_filter_block">
                        <div className='no_filter_text' dangerouslySetInnerHTML={{ __html: item.text}} />
                        <div className="appartment-by-id__header-button appartment-by-id__header-button--red" onClick={() => setPopup(true)}><div>Оставить заявку</div><div /></div>
                    </div>
                )

            }


            if(item.type === 'filter'){

                const dataGenplan = data.filter(i => i.type === 'genplan')[0];
                const dataGenplan3d = data.filter(i => i.type === 'genplan3d')[0];

                array.push(
                    <div key={item.type} className="animate building-filter-wrap" data-id={item.type} data-id-boxes={item.type}>
                        <Filter
                          flatsLoad={flatsLoad}
                            tab={tab}
                            setTab={setTab}
                            setFlats={setFlats}
                            api={api}
                            filter={item.filter}
                            mobile={mobile}
                            hash={hash}
                            filterTop={
                                <div className="building-filter-top">
                                    <div>
                                        <div className="building-filter-menu">
                                            <div className={type == 0 ? 'active' : ''} onClick={() => changeType(0)}>По параметрам</div>
                                            {dataGenplan && (
                                                <>
                                                    {dataGenplan.data && <div className={type == 1 ? 'active' : ''} onClick={() => changeType(1)}>На генплане</div>}
                                                </>
                                            )}
                                            {dataGenplan3d && (
                                                <>
                                                    {dataGenplan3d.data && <div className={type == 2 ? 'active' : ''} onClick={() => changeType(2)}>3d-план</div>}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        {unisenderData.unisender_id && <div className="appartment-by-id__header-button" onClick={() => setSubscr(true)}><div>{unisenderData.subscribe_name || 'Подписаться на скидку'}</div><div /></div>}
                                    </div>
                                </div>
                            }
                            mobileSubscr={unisenderData.unisender_id && <div className="appartment-by-id__header-button" onClick={() => setSubscr(true)}><div>{unisenderData.subscribe_name || 'Подписаться на скидку'}</div><div /></div>}
                            //selectButton={
                            //  <div className="filter__more"><div>Выбрать планировку</div></div>
                            //}
                        />
                        {!flats && <div className="apartment-search-loader"></div>}
                        {type == 0 && <Result filter={item.filter} tab={tab} data={flats} api={api} name_list={item.filter[tab].name_list}  />}
                        {( type == 1 && dataGenplan ) && <Genplan flats={flats} data={dataGenplan} api={api} stickyTop={stickyTop} />}
                        {( type == 2 && dataGenplan3d ) && <Genplan flats={flats} data={dataGenplan3d} api={api} stickyTop={stickyTop} />}
                    </div>
                )
            }

            if(item.type === 'similar-appartments'){
                array.push(
                    <div className="animate" key={`item${item.type}`} data-id={item.type} data-id-boxes={item.type}>
                        <div className="frame">
                            <div className="apartment-search__view">
                                <div className="apartment-search__view-title">
                                    <div className="apartment-search__view-title-line" />
                                    <div>Рекомендуем посмотреть</div>
                                </div>
                            </div>

                            <Slider {...settings} className="apartment-search__slider">
                                {item.list.map(i => <div className="apartment-search__view-slide" key={`item${i.id}`}><Item data={i} slide api={api} /></div>)}
                            </Slider>
                        </div>
                    </div>
                )
            }
            if(item.type === 'charts'){
                array.push(<div className="animate" data-id={item.type} key={`item${item.type}`}><Charts data={item[0]} api={api} query={query} /></div>)
            }
            if(item.type === 'about'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><About data={item} api={api} /></div>)
            }
            if(item.type === 'photo'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Photo data={item.list} api={api} /></div>)
            }
            if(item.type === 'video'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Video data={item} api={api} /></div>)
            }
            if(item.type === 'ready'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Ready data={item.list} api={api} /></div>)
            }
            if(item.type === 'docs'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Docs data={item.list} /></div>)
            }
            if(item.type === 'developer'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Developer data={item} /></div>)
            }
            if(item.type === 'news'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><News data={item} /></div>)
            }
            if(item.type === 'agents'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Agents data={item.list} api={api} /></div>)
            }
            if(item.type === 'estatet'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Info data={item.list} api={api} /></div>)
            }
            if(item.type === 'events'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><EventsNew api={api} articles={build_events} filterDefault={item.filter || []} type={'more1'} /></div>)
            }
            if(item.type === 'infra'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Infra markers={item.list} menu={item.menu} dsc1={item.dsc1} dsc2={item.dsc2} /></div>)
            }
            /*if(item.type === 'mortgage-programm'){
              array.push(<div className="animate" data-id={item.type} key={`item${item.type}`}><MortgageProgramm data={item.table} /></div>)
            }*/
            /*
            if(item.type === 'banks'){
                array.push(<div className="animate" data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Programms key={item.type} list={item.list} title="Ипотечные программы" /></div>)
            }*/

            if(item.type === 'apartment-calc'){
                const info = data.filter(k => k.type === 'info')[0];
                let min = 0;
                let max = 0;
                if(info){
                    if(info.toPrice){
                        max = info.toPrice;
                    }
                    if(info.fromPrice){
                        min = info.fromPrice;
                    }
                }
                array.push(<div className="animate" data-id={item.type} key={`item${item.type}`} data-id-boxes={item.type}><CalcApartment api={api} buildingId={item.id} defaultPriceMax={max} defaultPriceMin={min} /></div>)
            }
/*            if(item.toPrice && item.fromPrice){
              array.push(<div className="animate" data-id={item.type} key={`item${item.type}`} data-id-boxes={item.type}><CalcApartment api={api} buildingId={item.id} defaultPriceMax={item.toPrice} defaultPriceMin={item.fromPrice} /></div>)
            }*/

        }));

        return array;
    }

    const getSubscribeButton = () => {
        if(data){
            if(unisenderData){
                return {
                    subscribe_name: unisenderData.subscribe_name,
                    unisender_id: unisenderData.unisender_id
                }
            }
        }

        return {
            subscribe_name: null,
            unisender_id: null,
        }
    }

    if(!data) return null;

    const info = data.filter(item => item.type === 'info')[0];
    const zhkGuid = info ? info.guid : '';

    const objects = JSON.stringify([{"zhk":zhkGuid}]);

    return(
        <>

            <AddRecent data={{code: router.query.slug[1]}} type={1} api={api} />
            {mobile && (
                <div className="appartment-by-id__mobile-menu">
                    <div>
                        {menuData.data.map(item => (
                            <div className={item.value == activeMenu ? 'active' : ''} key={`item${item.value}`} onClick={() => scrollTo(item.value)}>{item.name}</div>
                        ))}
                    </div>
                </div>
            )}
            {subscr && (<div className="popup">
                <div className="popup__in">
                    <div className="popup__close" onClick={() => setSubscr(false)} />
                    <DiscountForm api={api} code={router.query.slug[1]} data={getSubscribeButton()} />
                </div>
            </div>)}
            {popupBookingAuth && <BoockingPopupAuth api={api} close={() => setPopup(false)} objects={objects} />}
            {popupBookingNoAuth && <BoockingPopupNoAuth api={api} close={() => setPopup(false)} objects={objects} />}
            <div className="inner-page building apartment-search">
                {create()}
            </div>
            {  Array.isArray(unisenderData.videocall_objects) && <Modal type="videoCall"><VideoCallForm   data={{code: router.query.slug[1]}} type={1} api={api} current_id={current_id} current_name={current_name} /></Modal> }
        </>
    )
}


export default connect(
    state => ({mobile: state.mobile}), {openModal}
)(Building)
