import Item from './Item';
import {useState} from "react";

const List = ({data, api, parking, tabindex}) => {
    const [limit, setLimit] = useState(8);
    return(
        <div>
            <div className="apartment-search__list building-result__list">
                {data.map((item, index) =>{
                        if(index < limit){
                            return <Item key={`item${item.id}`} data={item} api={api} parking={parking} />
                        }
                    }
                )}
            </div>
            {data.length > limit && <div className="btn btn--border news-feed__load-more no-border-link apartment-mortgage-programm__button ap-but-tab" onClick={() => setLimit(limit + 8)}><span>Показать еще</span></div>}
        </div>
    )
}

export default List;
