import { useState } from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';

const Docs = ({data}) => {
  const [limit, setLimit] = useState(3);

  const mobile = useSelector(state => state.mobile);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if(mobile){
    return(
      <div className="reportsBlock frame">
        <div className="title">Документы</div>
        <Slider {...settings}>
          {data && data.map((el, i) => (
            <div key={`item${i}`}>
                <a href={el.link} target="_blank" download className="reportsConsalt__item" key={i}>
                  <div className="reportsConsalt__name" dangerouslySetInnerHTML={{__html: el.name}}></div>
                  <div className="reportsConsalt__size" dangerouslySetInnerHTML={{__html: el.size}}></div>
                  <span className="reportsConsalt__link">Скачать</span>
                </a>
            </div>
          ))}
        </Slider>
      </div>
    )
  }

  return (
    <div className="reportsBlock frame">
        <div className="title">Документы</div>
        <div className="reportsConsalt">
          {data && data.map((el, i) => {
            if (i < limit) {
              return (
                <a href={el.link} target="_blank" download className="reportsConsalt__item" key={i}>
                  <div className="reportsConsalt__name" dangerouslySetInnerHTML={{__html: el.name}}></div>
                  <div className="reportsConsalt__size" dangerouslySetInnerHTML={{__html: el.size}}></div>
                  <span className="reportsConsalt__link">Скачать</span>
                </a>
              )
            }
          })}
        </div>
        {data.length > limit && <div className="btn btn--border no-border-link portfolioConsalt-more" onClick={() => setLimit(limit + 3)}><span>Показать еще</span></div>}
      </div>
  )
}

export default Docs;