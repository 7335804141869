import { declOfNum } from '../../../utils';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { SvgNoPhoto } from '../../../icons';
import AddFavorite from "../../_app/AddFavorite";

const Item = ({data, api, parking}) => {
  const mobile = useSelector(state => state.mobile);
  const router = useRouter();
  const format = Intl.NumberFormat('ru-RU');

  //console.log(data);

  if(parking){
    if(mobile){
      return(
        <div className="apartment-search__item">
          <div
            className="apartment-search__item-image apartment-search__item-image-mobile-list"
          >
            <div className="apartment-search__item-mobile-list">
              <div>
                <div>
                  {data.plan && (
                    <div
                      className="apartment-search__item-slide"
                      style={{ backgroundImage: `url('${api}${data.plan}')` }}
                    />
                  )}
                  {!data.plan && (
                    <div className="apartment-search__item-slide empty">
                      <SvgNoPhoto />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="apartment-search__item-metro">
                  <div>{data.metro}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="apartment-search__item-row">
            <div className="apartment-search__item-price">
                <div>{typeof data.price == 'number' ? `${format.format(data.price)} ₽` : data.price}</div>
                {data.sale && <span>%{data.discountName && <i>{data.discountName}</i>}</span>}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="apartment-search__item">
        <div>
          <div className="apartment-search__item-slider">
            {data.plan && (<div
              className="apartment-search__item-slide"
              style={{ backgroundImage: `url('${api}${data.plan}')` }}
            />)}
            {!data.plan && <div className="apartment-search__item-slide"><SvgNoPhoto /></div>}
          </div>
        </div>
        <div className="apartment-search__item-metro">
          <div>{data.metro}</div>
        </div>
        <div className="apartment-search__item-row">
          <div className="apartment-search__item-price">
              <div>{typeof data.price == 'number' ? `${format.format(data.price)} ₽` : data.price}</div>
              {data.sale && <span>%{data.discountName && <i>{data.discountName}</i>}</span>}
          </div>
        </div>
      </div>
    )
  }

  if(mobile){
    return(
      <div className="apartment-search__item">

        <div
          className="apartment-search__item-image apartment-search__item-image-mobile-list"
        >

          <div className="apartment-search__item-mobile-list">
            <div>
              <a href={`/${router.query.slug[0]}/${router.query.slug[1]}/${data.id}`} target="_blank">
                {data.plan && (
                  <div
                    className="apartment-search__item-slide"
                    style={{ backgroundImage: `url('${api}${data.plan}')` }}
                  />
                )}
                {!data.plan && (
                  <div className="apartment-search__item-slide empty">
                    <SvgNoPhoto />
                  </div>
                )}
              </a>
            </div>
            <div>
              <div className="apartment-search__item-metro">
                <div>{data.metro}</div>
              </div>
              {!parking && (
                <>
                  {data.rooms && data.rooms == 0 && <div className="apartment-search__item-info">{data.area} м&sup2;<br />студия<br />{data.finishing && `${data.finishing}`}<AddFavorite data={{id: data.id}} type={0} api={''} ky="flats" /></div>}
                  {data.rooms && data.rooms != 0 && <div className="apartment-search__item-info">{data.area} м&sup2;<br />{data.rooms} {declOfNum(data.rooms, ['комната','комнаты','комнат'])}<br />{data.finishing && `${data.finishing}`}<AddFavorite data={{id: data.id}} type={0} api={''} ky="flats" /></div>}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="apartment-search__item-row">
          <div className="apartment-search__item-price">
              <div>{typeof data.price == 'number' ? `${format.format(data.price)} ₽` : data.price}</div>
              {data.sale && <span>%{data.discountName && <i>{data.discountName}</i>}</span>}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="apartment-search__item">
      <a className="apartment-search__link" href={`/${router.query.slug[0]}/${router.query.slug[1]}/${data.id}`} target="_blank" />
          <div className="apartment-search__item-image">
            <div className="apartment-search__item-slider">
              {data.plan && (<div
                className="apartment-search__item-slide"
                style={{ backgroundImage: `url('${api}${data.plan}')` }}
              />)}
              {!data.plan && <div className="apartment-search__item-slide"><SvgNoPhoto /></div>}
            </div>
          </div>
          <div className="apartment-search__item-metro">
            <div>{data.metro}</div>
          </div>
          {!parking && (
            <>
              {data.rooms && data.rooms == 0 && <div className="apartment-search__item-info fav_inline">{data.area} м&sup2;&nbsp;·&nbsp;студия {data.finishing && ` · ${data.finishing}`}<AddFavorite data={{id: data.id}} type={0} api={''} ky="flats" /></div>}
              {data.rooms && data.rooms != 0 && <div className="apartment-search__item-info fav_inline">{data.area} м&sup2;&nbsp;·&nbsp;{data.rooms} {declOfNum(data.rooms, ['комната','комнаты','комнат'])} {data.finishing && ` · ${data.finishing}`}<AddFavorite data={{id: data.id}} type={0} api={''} ky="flats" /></div>}
            </>
          )}

          <div className="apartment-search__item-row">
            <div className="apartment-search__item-price">
                <div>{typeof data.price == 'number' ? `${format.format(data.price)} ₽` : data.price}</div>
                {data.sale && <span>%{data.discountName && <i>{data.discountName}</i>}</span>}
            </div>
          </div>

    </div>
  )
}

export default Item;
