import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router'
import Slider from 'react-slick';
import { SvgSliderArrowLeft, SvgSliderArrowRight, SvgNoPhoto } from '../../icons';
import { declOfNum } from '../../utils';
import AddFavorite from  '../_app/AddFavorite';
import Elite from '../_app/elite'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowLeft />
    </div>
  );
}

const ItemRecent = ({data, api}) => {
  const mobile = useSelector(state => state.mobile);
  const router = useRouter();

  const format = Intl.NumberFormat('ru-RU');
  const [index, setIndex] = useState(0);

  const settings = {
    swipe: !mobile,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    draggable: mobile,
    afterChange: e => setIndex(e)
  };

  const checkPlan = () => {
    if(data.plan){
      if(data.plan.length > 0){
        return true;
      }
    }

    return false;
  }

  const createImages = () => {

    const imgs = checkPlan() ? data.plan : data.image;

    return(
      <div className={!checkPlan() ? 'apartment-search__item-slider cover' : 'apartment-search__item-slider'}>
        {imgs.length > 0 && (
          <Slider {...settings}>
            {imgs.map((item, index) => (
              <div key={`item${index}`}>
                <div
                  className="apartment-search__item-slide"
                  style={{ backgroundImage: `url('${api}${item}')` }}
                />
              </div>
            ))}
          </Slider>
        )}
        {imgs.length === 0 && (
          <div className="apartment-search__item-slide empty">
            <SvgNoPhoto />
          </div>
        )}

        {imgs.length > 1 && (
          <div className="apartment-search__item-slider-values">
            {index + 1} / {imgs.length}
          </div>
        )}
      </div>
    )
  }

  const createNameAddr = () => {
    if(data.resale){
      return(
        <>
          {data.addr && <div className="apartment-search__item-addr">{data.addr}</div>}
          {data.name && <div className="apartment-search__item-name">{data.name}</div>}
        </>
      )
    }
    else{
      return(
        <>
          {data.name && <div className="apartment-search__item-addr">{data.name}</div>}
          {data.addr && <div className="apartment-search__item-name">{data.addr}</div>}
        </>
      )
    }
  }

  if(data.type == 1){
    return (
      <div className="apartment-search__item">
        {data.elite && <Elite />}
        <a className="apartment-search__link"
          href={`/${data.slug}/${data.code}`}
          target="_blank"
        />
          <div className="apartment-search__item-image">
            {createImages()}
          </div>
          <div className="apartment-search__item-metro">
            <div>{data.metro}</div>
            <AddFavorite data={data} type={data.type} api={api} />
          </div>
          {createNameAddr()}
          {data.rooms && (
            <>
              {data.rooms.length !== 0 && <div className="apartment-search__item-info">Комнат: {data.rooms.join(' / ')}</div>}
            </>
          )}

          {!!data.price && (
            <div className="apartment-search__item-row">
              <div className="apartment-search__item-price"><div>{typeof data.price == 'number' ? `от ${format.format(data.price)} ₽` : data.price}</div></div>
            </div>
          )}
          {!!data.rent && (
            <div className="apartment-search__item-row">
              <div className="apartment-search__item-price"><div>от {format.format(data.rent)} ₽ / мес</div></div>
            </div>
          )}

      </div>
    )
  }

  return (
    <div className="apartment-search__item">
      <a className="apartment-search__link"
        href={`/${data.slug}/${data.code}/${data.id}`}
        target="_blank"
      />
        <div className="apartment-search__item-image">
          {createImages()}
          {data.saleText && <div className="apartment-search__item-label">{data.saleText}</div>}
        </div>
        <div className="apartment-search__item-metro">
          <div>{data.metro}</div>
          <AddFavorite data={data} type={data.type} api={api} />
        </div>
        {createNameAddr()}
        <div className="apartment-search__item-info">
          {!!data.area && data.area + ' м²'}
          {(!!data.rooms && data.rooms != 0) && ` · ${data.rooms} ${data.rooms.toLowerCase() == 'студия' ? '' : declOfNum(data.rooms, ['комната','комнаты','комнат'])}`}
          {(!!data.floor && data.floor != 0) && ` · ${data.floor}${data.floorTotal ? '/'+data.floorTotal : ''} этаж`}
          {!!data.status && ` · ${data.status}`}
        </div>

        <div className="apartment-search__item-row">
          {!!data.price && <div className="apartment-search__item-price"><div>{typeof data.price == 'number' ? `${format.format(data.price)} ₽` : data.price} </div>{data.saleText && <span>%<i>{data.saleText}</i></span>}</div>}
          {!!data.rent && <div className="apartment-search__item-price"><div>от {format.format(data.rent)} ₽ / мес</div></div>}
        </div>

    </div>
  )
}

export default ItemRecent;
