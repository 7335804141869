import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { SvgSliderArrowLeft, SvgSliderArrowRight } from '../../icons';
import { setPopupPhoto } from '../../redux/modules/site';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowLeft />
    </div>
  );
}


const Ready = ({data, api}) => {
  const mobile = useSelector(state => state.mobile);
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);

  const settings = {
    arrows: true,
    dots: false,
    lazyLoad: true,
    infinite: mobile || (!mobile && data.length > 4),
    speed: 500,
    slidesToShow: mobile ? 1 : 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (oldIndex, newIndex) => {
      setActive(newIndex);
    }
  };

  const setPopup = album => {
    if(album){
      dispatch(setPopupPhoto(
        {
          current: 0,
          list: album
        }
      ))
    }
  }

  return(
    <div className="building-ready">
      <div className="frame">
        <div className="title">Строительная готовность</div>
        <Slider {...settings} className="building-ready-slider">
          {data && data.map((item, index) => (
            <div key={`item${index}`}>
              <div className="building-ready-slide" style={{ backgroundImage: `url('${api}${item.img}')`}} onClick={() => setPopup(item.album)}>{Array.isArray(item.album) && <div>1/{item.album.length}</div>}</div>
              <div className={index == active ? 'building-ready-dsc active' : 'building-ready-dsc'}>
                <div className="building-ready-label">{item.label}</div>
                <div className="building-ready-date">{item.date}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default Ready;