import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router'
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Filter from './Filter';
import Item from './Item';
import ListItemMoble from './ListItemMoble';
import TableRow from './TableRow';
import { declOfNum } from '../../utils';
import { SvgRightArrow, SvgSortTiles, SvgSortLines, SvgSelectArrow, SvgSortArrow } from '../../icons';
import UiSelectField from '../_app/filter-fields/UiSelectField';
import Slider from 'react-slick';
import SimilarItems from './SimilarItems';
import SimilarRows from './SimilarRows';
//import Link from 'next/link';
import Link from '../_app/link';
import Breadcrumbs from '../_app/breadcrumbs'

import {
  moduleName,
  changeTab,
  fetchData,
} from '../../redux/modules/apartment-search';
import HeadMeta from '../_app/HeadMeta';
import Recent from '../apartment-search-by-id/Recent';

const MapNoSSR = dynamic(
    () => import('./Map'),
    { ssr: false }
)

const ApartmentSearch = ({api}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const mobile = useSelector(state => state.mobile);
  const values = useSelector(state => state[moduleName].values);
  const tab = useSelector(state => state[moduleName].tab);
  const list = useSelector(state => state[moduleName].list);
  const page = useSelector(state => state[moduleName].page);
  const page_count = useSelector(state => state[moduleName].page_count);
  const count = useSelector(state => state[moduleName].count_all);
  const filter = useSelector(state => state[moduleName].filter);
  const mapData = useSelector(state => state[moduleName].mapData);
  let seo = useSelector(state => state[moduleName].seo);
  const typeTmp = filter.filter(item => item.type === router.query.slug[0])[0];

  /*
  console.log(router);
  if(!typeTmp){
    return false;
  }

   */
  const [type, setType] = useState(router.query.view || (typeTmp?.types[1] ? typeTmp.types[1].value : typeTmp?.types[0].value));

  const pendingMap = useSelector(state => state[moduleName].pendingMap);
  const pendingList = useSelector(state => state[moduleName].pendingList);

  const [minMaxLatLng, setMinMaxLatLng] = useState(null);

  useEffect(() => {
    const f = filter.filter(item => item.type == router.query.slug[0]);
    if(f.length){
      dispatch(changeTab(f[0].index))
    }
  },[filter])

  useEffect(() => {
    const typeTmp = filter.filter(item => item.type === router.query.slug[0])[0];
    if (!router.query.view) {
      if(typeTmp?.types[1]){
        setType(typeTmp.types[1].value);
      }
      else {
        setType(typeTmp?.types[0].value);
      }
    }
  },[router.query.slug[0]]);

/*  useEffect(() => {
    console.log(page);
    if(page == '1'){
      router.replace(router.query.slug[0], undefined, { shallow: true });
    }
  },[page]);*/

  const types = filter[tab]

  const [active, setActive] = useState(mobile);
  const [sort, setSort] = useState('0');
  const [map, setMap] = useState(router.query.hash == 'hidemap' ? false : true);
  const [similar, setSimilar] = useState(null);

  const setQueries = (params, type) => {
    const catalog = filter[tab].type;
    router.push({
      pathname: `/${catalog}`,
      query: {...params, view: type},
    })
  }


  const changePage = page => {
    //router?.query?.page = page;
    //router.push(`/catalog_new?page=${page}`);
    if(values){
/*      let currentUrlParams = new URLSearchParams(window.location.search);
      currentUrlParams.set('page', page);
      window.history.push(window.location.pathname + "?" + currentUrlParams.toString());
      history.push({
        pathname: '/dresses',
        search: '?color=blue'
      })*/
      router.replace(router.query.slug[0]+(page>1 ? '?page='+page : ''), undefined, { shallow: true });
      //router.push('/catalog_new?page='+page);

      setSimilar(null);

      const fd = new FormData();
      fd.append('pg', page);
      fd.append('sort', sort);
      fd.append('view', type);
      fd.append('catalog', router.query.slug[0]);

      const a = filter[tab].filters.filter(item => item.type === 'slider');
      const b = filter[tab].extras.filter(item => item.type === 'slider');
      const r = a.concat(b)

      for(let i in values){
        if(Array.isArray(values[i])){
          values[i].forEach(k => {
            const t = r.filter(item => item.name == i)[0]
            if(t){
              if(values[i][0] != t.data[0] || values[i][1] != t.data[1]){
                fd.append(i + '[]', k);
              }
            }
            else{
              fd.append(i + '[]', k);
            }
          });
        }
        else{
          if(values[i] && values[i] != 0){
            fd.append(i, values[i]);
          }
        }
      }

      for(let i in router.query){
        if(values){
          if(i != 'slug' && i != 'view'){
            if(values[i] === undefined){
              fd.append(i, router.query[i]);
            }
          }
        }
      }

      dispatch(fetchData(api, fd));

      if(mobile){
        const element = document.querySelector('.apartment-search__frame');
        element.scrollIntoView();
      }
      else{
        const element = document.querySelector('.apartment-search')
        window.scrollTo(0, element.offsetTop);
      }
      setSimilar(null);
    }
  }

  const changeSort = value => {
    setSort(value);
    setSimilar(null);
    if(values){
      const fd = new FormData();
      fd.append('pg', 1);
      fd.append('sort', value);
      fd.append('view', type);
      fd.append('catalog', router.query.slug[0]);



      const a = filter[tab].filters.filter(item => item.type === 'slider');
      const b = filter[tab].extras.filter(item => item.type === 'slider');
      const r = a.concat(b)

      for(let i in values){
        if(Array.isArray(values[i])){
          values[i].forEach(k => {
            const t = r.filter(item => item.name == i)[0]
            if(t){
              if(values[i][0] != t.data[0] || values[i][1] != t.data[1]){
                fd.append(i + '[]', k);
              }
            }
            else{
              fd.append(i + '[]', k);
            }
          });
        }
        else{
          if(values[i] && values[i] != 0){
            fd.append(i, values[i]);
          }
        }
      }

      for(let i in router.query){
        if(values){
          if(i != 'slug' && i != 'view'){
            if(values[i] === undefined){
              fd.append(i, router.query[i]);
            }
          }
        }
      }

      router.replace(router.query.slug[0], undefined, { shallow: true });
      dispatch(fetchData(api, fd));
    }
  }

  const closeMap = () => {
    setMap(false);
    setSimilar(null);
  }

  const openMap = () => {
    setMap(true);
    setSimilar(null);
  }

  const createListSimilar = () => {
    if(similar){
      let first = [];
      let last = [];
      let count = 1;
      let detect = false;
      const s = map ? 2 : 4;

      for(let i = 0; i < list.length; i++){
        if(list[i].id === similar){
          detect = true;
        }

        if(detect && !(count % s)){
          break;
        }
        count++;
      }

      first = list.slice(0, count);
      last = list.slice(count);

      return [first, last];
    }

    return null;
  }

  const creatRowsSimilar = () => {
    if(similar){
      let first = [];
      let last = [];
      let count = 1;
      for(let i = 0; i < list.length; i++){
        if(list[i].id === similar){
          break;
        }
        count++;
      }

      first = list.slice(0, count);
      last = list.slice(count);

      return [first, last];
    }
    return null;
  }

  const creteMap = useMemo(() => {
    if(mapData){
      return (
          <MapNoSSR list={mapData.list} api={api} setMinMaxLatLng={setMinMaxLatLng}>
            <div className="apartment-search__button-close-map" onClick={closeMap}>
              Скрыть карту
              <SvgRightArrow />
            </div>
          </MapNoSSR>
      )
    }
  },[mapData]);

  const data = list;
  const similarData = mobile ? creatRowsSimilar() : createListSimilar();
  const creatRowsSimilarData = creatRowsSimilar();

  const settings = {
    dots: true,
    infinite: mobile,
    speed: 500,
    slidesToShow: mobile ? 1 : 4,
    slidesToScroll: mobile ? 1 : 4,
    arrows: false,
    draggable: mobile
  };




  if (!filter) return null;

  const createTableView = () => {
    if(active && !mobile && data){

      const areaSize = data.filter(item => !!item.area && item.area != 0).length > 0;
      const roomsSize = data.filter(item => !!item.rooms && item.rooms != 0).length > 0;
      const floorSize = data.filter(item => !!item.floor && item.floor != 0).length > 0;
      const priceSize = data.filter(item => (!!item.price && item.price != 0) || (!!item.rent && item.rent != 0)).length > 0;

      const checkData = {
        areaSize,
        roomsSize,
        floorSize,
        priceSize
      }

      return (
          <div className="apartment-search__table-wrap">
            <table className="apartment-search__table">
              <thead>
              <tr>
                <td width="130"></td>
                <td width="18"></td>
                <td></td>
                {areaSize && <td align="center">Площадь,м&sup2;</td>}
                {roomsSize && <td align="center">Комнат</td>}
                {floorSize && <td align="center">Этаж</td>}
                {priceSize && <td align="right">Цена, ₽</td>}
              </tr>
              </thead>
              <tbody>
              {!similar && data.map((item, index) => <TableRow key={`item${item.id}-${index}`} data={item} api={api} setSimilar={setSimilar} similar={similar} checkData={checkData} values={values} types={types} />)}
              {similar && creatRowsSimilarData && (
                  <>
                    {creatRowsSimilarData[0].map((item, index) => <TableRow key={`item${item.id}-${index}`} data={item} api={api} setSimilar={setSimilar} similar={similar} checkData={checkData} values={values} types={types} />)}
                    <SimilarRows api={api} similar={similar} checkData={checkData} values={values} types={types} />
                    {creatRowsSimilarData[1].map((item, index) => <TableRow key={`item${item.id}-${index}`} data={item} api={api} setSimilar={setSimilar} similar={similar} checkData={checkData} values={values} types={types} />)}
                  </>
              )}
              </tbody>
            </table>
          </div>
      )
    }
  }

  const createContent = () => {
    //console.log(data)
    if(data){
      if(data.length){
        return (
            <>
              {!active && data && (
                  <div className="apartment-search__list">
                    {!similar && data.map((item, index) => <Item key={`item${item.id}-${index}`} data={item} api={api} setSimilar={setSimilar} similar={similar} values={values} types={types} />)}
                    {similar && similarData && (
                        <>
                          {similarData[0].map((item, index) => <Item key={`item${item.id}-${index}`} data={item} api={api} setSimilar={setSimilar} similar={similar} values={values} types={types} />)}
                          <SimilarItems api={api} similar={similar} active={active} values={values} types={types} />
                          {similarData[1].map((item, index) => <Item key={`item${item.id}-${index}`} data={item} api={api} setSimilar={setSimilar} similar={similar} values={values} types={types} />)}
                        </>
                    )}
                  </div>
              )}

              {createTableView()}

              {active && mobile && data && (
                  <div className="apartment-search__list">
                    {!similar && data.map((item, index) => <Item key={`item${item.id}-${index}`} data={item} api={api} setSimilar={setSimilar} similar={similar} values={values} types={types} />)}
                    {similar && similarData && (
                        <>
                          {similarData[0].map((item, index) => <Item key={`item${item.id}-${index}`} data={item} api={api} setSimilar={setSimilar} similar={similar} values={values} types={types} />)}
                          <SimilarItems api={api} similar={similar} active={active} values={values} types={types} />
                          {similarData[1].map((item, index) => <Item key={`item${item.id}-${index}`} data={item} api={api} setSimilar={setSimilar} similar={similar} values={values} types={types} />)}
                        </>
                    )}
                  </div>
              )}
            </>
        )
      }
      return (
          <div className="apartment-search__not-found">
            Введите более гибкие условия поиска
            {type == 1 && (
                <>
                  <br /> или посмотрите <span onClick={() => setType(0)}>квартиры</span>
                </>
            )}
            {type == 0 && (
                <>
                  <br /> или посмотрите <span onClick={() => setType(1)}>жилые комплексы</span>
                </>
            )}
          </div>
      )
    }
  }

  const createMapButton = () => {
    if(data){
      if(data.length){
        return(
            <div className="apartment-search__button-show-map" onClick={openMap}>
              Показать карту
              <SvgRightArrow />
            </div>
        )
      }
    }
  }

  const createSortArrow = () => {
    if(filter[tab].sort){
      const current = filter[tab].sort.filter(item => item.value == sort)[0];
      if(current.arrow){
        return <div className={current.arrow == 'down' ? 'apartment-search__sort-select-icon active' : 'apartment-search__sort-select-icon'}><SvgSortArrow /></div>
      }
    }
  }

  const createSortSelect = () => {
    if(filter[tab].sort){

      const d = {
        'up': ' ↑',
        'down': ' ↓'
      }

      const options = filter[tab].sort.map(item => ({name: item.name + `${d[item.arrow] ? d[item.arrow] : ''}`, value: item.value}))

      return(
          <>
            <UiSelectField
                options={filter[tab].sort}
                value={sort}
                onChange={e => changeSort(e.target.value)}
            />
            {createSortArrow()}
          </>
      )
    }
  }

  const createSort = () => {
    if(data){
      if(data.length){
        return(
            <div className="apartment-search__sort">
              <div className="apartment-search__sort-icons">
                {!mobile && (
                    <>
                      <div className={!active ? 'apartment-search__sort-tales active' : 'apartment-search__sort-tales'} onClick={() => setActive(false)}><SvgSortTiles /></div>
                      <div className={active ? 'apartment-search__sort-lines active' : 'apartment-search__sort-lines'} onClick={() => setActive(true)}><SvgSortLines /></div>
                    </>
                )}
              </div>
              <div className="apartment-search__sort-select">
                {'Сортировать:'}
                {filter[tab] && (
                    <div className="apartment-search__sort-select-wrap">
                      {createSortSelect()}
                    </div>
                )}
              </div>
            </div>
        )
      }
    }
  }


/*  useEffect(() => {
    if(router?.query?.page && router?.query?.page!=page){
      changePage(router?.query?.page);
    }
  },[data]);*/

  //console.log(router?.query?.page);
  //changePage(router?.query?.page);

/*  seo[router.query.slug[0]].canonical = api+'/catalog_new';*/
  if(seo[router.query.slug[0]]){
    seo[router.query.slug[0]].canonical = api+'/'+router.query.slug[0];
  }

  const title = filter.find(el => el.index == tab)?.name + ' недвижимость'

  return (
      <>
        {seo && <HeadMeta api={api} data={seo[router.query.slug[0]]} />}
        <div className={map && !mobile ? 'inner-page apartment-search apartment-search__showmap' : 'inner-page apartment-search'} >
          {!mobile && <div className="page-title frame">
            <Breadcrumbs api={api} items={[{name:'Главная',link:'/'},{name:title}]} />
          </div>}
          <Filter filter={filter} mobile={mobile} api={api} sort={sort} type={type} setSimilar={setSimilar} setType={setType} minMaxLatLng={minMaxLatLng} />
          <div className="apartment-search-map-detect" />
          <div className="apartment-search__list-wrap">

            {map && (
                <div className="apartment-search__map">
                  {pendingMap && <div className="apartment-search-loader"></div>}
                  {creteMap}
                </div>
            )}

            {mobile && !map && (
                <div className="frame apartment-search-mobile-showmap-wrap">
                  <div className="apartment-search-mobile-showmap" onClick={openMap}>
                    Показать карту <SvgSelectArrow />
                  </div>
                </div>
            )}

            <div className="apartment-search__frame">
              {pendingList && <div className="apartment-search-loader"></div>}
              <div className="apartment-search__top">
                <div className="filter__menu">
                  {types.types.map(item => (
                      <div
                          key={`item${item.value}`}
                          className={type == item.value ? 'active' : ''}
                          onClick={() => {
                            setType(item.value)
                            //setQueries({...values}, item.value)
                          }}
                      >
												{item.name}
                      </div>
                  ))}
                </div>
                <div className="apartment-search__result-count">{count} {declOfNum(count, ['результат','результата','результатов'])}</div>
              </div>
              <div className="apartment-search__bottom">
                {!map && !mobile && createMapButton()}
                {createSort()}
              </div>
              {createContent()}
              <div className="apartment-search__footer">
                <div>
                  {!map && !mobile && createMapButton()}
                </div>
                <div>
                  {/*<div className="btn btn--border no-border-link apartment-search__btn">
                  <span>Показать еще</span>
                </div>*/}
                </div>
                <div>

                </div>
              </div>

{/*
              {page_count > 1 && <Pagination count={page_count} page={page) || 1} onChange={(e, page) => changePage(page)} siblingCount={mobile ? 0 : 1} />}
*/}
              {page_count > 1 &&
/*                <Pagination
                  count={10}
                  renderItem={(item) => (
                    <Link href={'#'}><a><PaginationItem {...item}/></a></Link>
                  )}
                />*/
/*
              <Pagination count={page_count} page={page} onChange={(e, page) => changePage(page)} siblingCount={mobile ? 0 : 1}/>
*/
                <Pagination count={page_count} page={page} onChange={(e, page) => changePage(page)} siblingCount={mobile ? 0 : 1}
                            renderItem={(item) => (
                              <a onClick={(e)=>{e.preventDefault()}} className={'pag_a'} href={`/${router.query.slug[0]}${item.page === 1 ? '' : `?page=${item.page}`}`}><PaginationItem {...item}/></a>
                            )}
                />
            }

            </div>
          </div>

          {/*<div className="frame apartment-search__view-wrapper">
          <div className="apartment-search__view">
            <div className="apartment-search__view-title">
              <div className="apartment-search__view-title-line" />
              <div>Вы смотрели</div>
            </div>
          </div>

          {list && (
            <Slider {...settings} className="apartment-search__slider">
              {list.map(item => <div className="apartment-search__view-slide" key={`item${item.id}`}><Item data={item} slide api={api} /></div>)}
            </Slider>
          )}
        </div>*/}

          <Recent api={api} />
        </div>
      </>
  )
}

export default ApartmentSearch;
