import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { SvgSliderArrowLeft, SvgSliderArrowRight } from '../../icons';
import { setPopupPhoto } from '../../redux/modules/site';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowLeft />
    </div>
  );
}

const GalleryTop = ({data, single, api}) => {
  const dispatch = useDispatch();
  const mobile = useSelector(state => state.mobile);
  const [active, setActive] = useState(0);

  if(!data) return null;

  const settings = {
    centerPadding: 0,
    centerMode: ( single || mobile ) ? true : false,
    arrows: !mobile,
    dots: !mobile,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (oldIndex, newIndex) => {
      setActive(newIndex);
    }
  };

  const setPopup = index => {
    dispatch(setPopupPhoto(
      {
        current: index,
        list: data
      }
    ))
  }

  if(data.length === 1){
    return(
      <div className="building-top-slide single">
        <img src={`${api}${data[0].img}`} className="hidden_list" itemProp="image" alt=""/>
        <div className="building-top-slide-bg" style={{ backgroundImage: `url('${api}${data[0].img}')`}} />
        <div className="building-top-slide-label">{data[0].label}</div>
      </div>
    )
  }

  return (
    <Slider {...settings} className="building-top-slider">
      {data.map((item, index) => (
        <div key={`item${index}`}>
          {index===0 && <img src={`${api}${item.img}`} className="hidden_list" itemProp="image" alt=""/>}
          <div className={index == active ? 'building-top-slide active' : 'building-top-slide'} onClick={() => setPopup(index)}>
            <div className="building-top-slide-bg" style={{ backgroundImage: `url('${api}${item.img}')`}} />
            <div className="building-top-slide-label">{item.label}</div>
          </div>
        </div>
      ))}
    </Slider>
  )
}

export default GalleryTop;
