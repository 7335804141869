import { useSelector } from 'react-redux';

const About = ({data, api}) => {

  const mobile = useSelector(state => state.mobile);

  return(
    <div className="building-about">
      <div className="building-about__top">
        <div className="frame">
          <div className="building-about__top-in">
            <div>
              <div className="title">О проекте</div>
              {data.dsc1 && (
                <ul>
                  {data.dsc1.map((item, index) => <li key={`item${index}`}>{item}</li>)}
                </ul>
              )}
            </div>
            {data.img1 && (
              <div>
                <div className="building-about__img1" style={{ backgroundImage: `url(${api + data.img1})`}} />
              </div>
            )}
          </div>
        </div>
      </div>

      {(data.img2 || data.title2 || data.dsc2 || data.img3 || data.title3 || data.dsc3) && (
        <div className="frame">
          <div className="building-about__box">
            {data.img2 && <div><img src={api + data.img2} /></div>}
            <div>
              {data.title2 && <div className="building-about__box-title" dangerouslySetInnerHTML={{ __html: data.title2}} />}
              {data.dsc2 && <div className="building-about__box-text" dangerouslySetInnerHTML={{ __html: data.dsc2}} />}
            </div>
            {mobile && data.img3 && <div><img src={api + data.img3} /></div>}
            <div>
              {data.title3 && <div className="building-about__box-title" dangerouslySetInnerHTML={{ __html: data.title3}} />}
              {data.dsc3 && <div className="building-about__box-text" dangerouslySetInnerHTML={{ __html: data.dsc3}} />}
            </div>
            {!mobile && data.img3 && <div><img src={api + data.img3} /></div>}

            {data.img4 && <div><img src={api + data.img4} /></div>}
            <div>
              {data.title4 && <div className="building-about__box-title" dangerouslySetInnerHTML={{ __html: data.title4}} />}
              {data.dsc4 && <div className="building-about__box-text" dangerouslySetInnerHTML={{ __html: data.dsc4}} />}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default About;