import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import ItemRecent from './ItemRecent';
import { fetchAll } from '../../redux/modules/recent';

const Recent = ({api}) => {
  const dispatch = useDispatch();
  const recent = useSelector(state => state.recent);
  const mobile = useSelector(state => state.mobile);

  useEffect(() => {
    if(!recent){
      dispatch(fetchAll(api));
    }
  },[recent]);

  const create = () => {
    const data = [];
    if(recent){
      if(Array.isArray(recent.flats)){
        recent.flats.forEach(item => {
          data.push({...item, slug: 'catalog_new'})
        })
      }
      if(Array.isArray(recent.objects)){
        recent.objects.forEach(item => {
          data.push({...item, slug: 'catalog_new'})
        })
      }
      if(Array.isArray(recent.flatsCommerce)){
        recent.flatsCommerce.forEach(item => {
          data.push({...item, slug: 'catalog_cre'})
        })
      }
      if(Array.isArray(recent.objectsCommerce)){
        recent.objectsCommerce.forEach(item => {
          data.push({...item, slug: 'catalog_cre'})
        })
      }
      if(Array.isArray(recent.flatsCountry)){
        recent.flatsCountry.forEach(item => {
          data.push({...item, slug: 'catalog_country'})
        })
      }
      if(Array.isArray(recent.objectsCountry)){
        recent.objectsCountry.forEach(item => {
          data.push({...item, slug: 'catalog_country'})
        })
      }
    }

    return data;
  }

  const settings = {
    dots: true,
    infinite: mobile,
    speed: 500,
    slidesToShow: mobile ? 1 : 4,
    slidesToScroll: mobile ? 1 : 4,
    arrows: false,
  };

  if(!recent) return null;

  return(
    <div className="frame apartment-search__view-wrapper-v">
      <div className="apartment-search__view">
        <div className="apartment-search__view-title">
          <div className="apartment-search__view-title-line" />
          <div>Вы смотрели</div>
        </div>
      </div>

      <Slider {...settings} className="apartment-search__slider">
        {create().map(i => <div className="apartment-search__view-slide" key={`item${i.id}`}><ItemRecent data={i} api={api} /></div>)}
      </Slider>
    </div>
  )
}

export default Recent;