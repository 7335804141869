import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchAll } from '../../redux/modules/recent';

const AddRecent = ({data, type, api}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let params = {};
    if(type == 0){
      params = {
        page: 'recent-add',
        type: 'flat',
        id: data.id
      }
    }
    else{
      params = {
        page: 'recent-add',
        type: 'object',
        code: data.code
      }
    }

    axios
    .get(api + '/ajax/json.php', { params })
    .then(({ data }) => data)
    .then(response => {
      dispatch(fetchAll(api));
     })
     .catch(function (error) {
     })
  },[]);

  return null;
}

export default AddRecent;