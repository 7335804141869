import ApartmentSearch from '../apartment-search';
import Building from '../building'
import ApartmentSearchById from '../apartment-search-by-id';
import NotFound from '../error'

const Wrapper = props => {
    if(props.type === 'flat'){
        if(!props.data){
            return <NotFound statusCode={404} />
        }

        return <ApartmentSearchById {...props} />
    }
    if(props.type === 'object'){
        if(!props.data){
            return <NotFound statusCode={404} />
        }

        return <Building {...props} />;
    }
    if(props.type === 'filter'){
        return <ApartmentSearch {...props} />
    }

    return <NotFound statusCode={404} />
}

export default Wrapper;
