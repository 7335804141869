import { useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const Genplan = ({data, api}) => {
  const [size, setSize] = useState((data.sheme && data.sheme.imgSize)
      ? (data.sheme.imgSize.width && data.sheme.imgSize.height ? [data.sheme.imgSize.width, data.sheme.imgSize.height] : data.sheme.imgSize)
      : data.imgSize
        ? (data.imgSize.width && data.imgSize.height ? [data.imgSize.width, data.imgSize.height] : data.imgSize)
        : [0, 0]);

  const img = api + data.data[0]

  useEffect(() => {
    setSize((data.sheme && data.sheme.imgSize)
      ? (data.sheme.imgSize.width && data.sheme.imgSize.height ? [data.sheme.imgSize.width, data.sheme.imgSize.height] : data.sheme.imgSize)
      : data.imgSize
        ? (data.imgSize.width && data.imgSize.height ? [data.imgSize.width, data.imgSize.height] : data.imgSize)
        : [0, 0])

    if(data && img && !size[0]){
      const image = new Image();
      image.src = img;
      image.onload = () => setSize([image.width, image.height])
    }
  },[data]);

  if(!data || !size[0]) return null;

  const item = data.sheme && data.sheme.markers ? data.sheme.markers.find(el => el.selected) : null

  return(
    <div className="building-genplan frame fade-in">
      <div className="building-genplan__in">
        <div style={{width: '100%'}}>
          <TransformWrapper
            onInit={() => {
              const timer = setInterval(() => {
                const text = document.querySelectorAll('.building-genplan__image text');
                if(text.length != 0){
                  clearInterval(timer);
                  for(let i = 0; i < text.length; i++){
                    const width = text[i].getBoundingClientRect().width;
                    const w = text[i].getAttribute('w')
                    const l = (w - width)/2;
                    text[i].style.transform = `translateX(${l}px)`
                  }
                }
              }, 100);
            }}
          >
            <TransformComponent>
              {img && <div className="building-genplan__image" style={{ top: 0 }}>
                <img src={img} width={size[0]} />
                {item && <svg viewBox={`0 0 ${size[0]} ${size[1]}`} xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <rect fill="#ed1c24" x={item.position[0]} y={item.position[1]} width={item.corpus.length <= 3 ? 30 : item.corpus.length * 12} height="30"/>
                    <text x={item.position[0]} w={item.corpus.length <= 3 ? 30 : item.corpus.length * 12} y={item.position[1] + 20}>{item.corpus}</text>
                  </g>
                </svg>}
              </div>}
            </TransformComponent>
          </TransformWrapper>


        </div>
      </div>
    </div>
  )
}

export default Genplan;