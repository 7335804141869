import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import UiSelectField from '../../_app/filter-fields/UiSelectField';
import { SvgSortTiles, SvgSortLines, SvgSortArrow } from '../../../icons';
import Table from './Table';
import List from './List';

const Container = ({data, api, parking, tabindex, tab_active}) => {
  const mobile = useSelector(state => state.mobile);
  const [active, setActive] = useState(true);
  const [sort, setSort] = useState(1);

  useEffect(() => {
    setActive(!mobile);
  },[mobile]);

  const sorting = () => {
    if(sort == 1){
      data.sort((a, b) => {
        if (parseFloat(a.area) < parseFloat(b.area)) return -1;
        if (parseFloat(a.area) > parseFloat(b.area)) return 1;
        return 0;
      })
    }

    if(sort == 2){
      data.sort((a, b) => {
        if (parseFloat(a.area) > parseFloat(b.area)) return -1;
        if (parseFloat(a.area) < parseFloat(b.area)) return 1;
        return 0;
      })
    }

    if(sort == 3){
      data.sort((a, b) => {
        if (parseInt(a.price) < parseInt(b.price)) return -1;
        if (parseInt(a.price) > parseInt(b.price)) return 1;
        return 0;
      })
    }

    if(sort == 4){
      data.sort((a, b) => {
        if (parseInt(a.price) > parseInt(b.price)) return -1;
        if (parseInt(a.price) < parseInt(b.price)) return 1;
        return 0;
      })
    }

    if(sort == 5){
      data.sort((a, b) => {
        if (parseInt(a.corpus) < parseInt(b.corpus)) return -1;
        if (parseInt(a.corpus) > parseInt(b.corpus)) return 1;
        return 0;
      })
    }

    if(sort == 6){
      data.sort((a, b) => {
        if (parseInt(a.corpus) > parseInt(b.corpus)) return -1;
        if (parseInt(a.corpus) < parseInt(b.corpus)) return 1;
        return 0;
      })
    }

    if(sort == 7){
      data.sort((a, b) => {
        if (parseInt(a.term) < parseInt(b.term)) return -1;
        if (parseInt(a.term) > parseInt(b.term)) return 1;
        return 0;
      })
    }

    if(sort == 8){
      data.sort((a, b) => {
        if (parseInt(a.term) > parseInt(b.term)) return -1;
        if (parseInt(a.term) < parseInt(b.term)) return 1;
        return 0;
      })
    }

    if(sort == 9){
      data.sort((a, b) => {
        if (parseInt(a.section) < parseInt(b.section)) return -1;
        if (parseInt(a.section) > parseInt(b.section)) return 1;
        return 0;
      })
    }

    if(sort == 10){
      data.sort((a, b) => {
        if (parseInt(a.section) > parseInt(b.section)) return -1;
        if (parseInt(a.section) < parseInt(b.section)) return 1;
        return 0;
      })
    }

    if(sort == 11){
      data.sort((a, b) => {
        if (parseInt(a.floor) < parseInt(b.floor)) return -1;
        if (parseInt(a.floor) > parseInt(b.floor)) return 1;
        return 0;
      })
    }

    if(sort == 12){
      data.sort((a, b) => {
        if (parseInt(a.floor) > parseInt(b.floor)) return -1;
        if (parseInt(a.floor) < parseInt(b.floor)) return 1;
        return 0;
      })
    }

    if(sort == 13){
      data.sort((a, b) => {
        if (parseInt(a.number) < parseInt(b.number)) return -1;
        if (parseInt(a.number) > parseInt(b.number)) return 1;
        return 0;
      })
    }

    if(sort == 14){
      data.sort((a, b) => {
        if (parseInt(a.number) > parseInt(b.number)) return -1;
        if (parseInt(a.number) < parseInt(b.number)) return 1;
        return 0;
      })
    }

    if(sort == 15){
      data.sort((a, b) => {
        if (parseInt(a.numberOnFloor) < parseInt(b.numberOnFloor)) return -1;
        if (parseInt(a.numberOnFloor) > parseInt(b.numberOnFloor)) return 1;
        return 0;
      })
    }

    if(sort == 16){
      data.sort((a, b) => {
        if (parseInt(a.numberOnFloor) > parseInt(b.numberOnFloor)) return -1;
        if (parseInt(a.numberOnFloor) < parseInt(b.numberOnFloor)) return 1;
        return 0;
      })
    }

    if(sort == 17){
      data.sort((a, b) => {
        if (parseInt(a.finishing) < parseInt(b.finishing)) return -1;
        if (parseInt(a.finishing) > parseInt(b.finishing)) return 1;
        return 0;
      })
    }

    if(sort == 18){
      data.sort((a, b) => {
        if (parseInt(a.finishing) > parseInt(b.finishing)) return -1;
        if (parseInt(a.finishing) < parseInt(b.finishing)) return 1;
        return 0;
      })
    }

    return data;
  }

  const sortData = sorting();

  return (
    <div className={"fade-in building-result-container "+(tab_active ? 'active':'')}>
      <div className="building-result-sort">
        <div>
          {!mobile &&  <div className={!active ? 'apartment-search__sort-tales active' : 'apartment-search__sort-tales'} onClick={() => setActive(false)}><SvgSortTiles /></div>}
          {!mobile && <div className={active ? 'apartment-search__sort-lines active' : 'apartment-search__sort-lines'} onClick={() => setActive(true)}><SvgSortLines /></div>}
        </div>

        {!active && <div className="apartment-search__sort-select">
          Сортировать:
          <div className="apartment-search__sort-select-wrap">
            <UiSelectField
              options={[
                {
                  name: 'Площадь',
                  value: 1,
                  arrow: 'up'
                },
                {
                  name: 'Площадь',
                  value: 2,
                  arrow: 'down'
                },
                {
                  name: 'Цена',
                  value: 3,
                  arrow: 'up'
                },
                {
                  name: 'Цена',
                  value: 4,
                  arrow: 'down'
                },
                {
                  name: 'Корпус',
                  value: 5,
                  arrow: 'up'
                },
                {
                  name: 'Корпус',
                  value: 6,
                  arrow: 'down'
                },
                {
                  name: 'Срок ввода',
                  value: 7,
                  arrow: 'up'
                },
                {
                  name: 'Срок ввода',
                  value: 8,
                  arrow: 'down'
                },
                {
                  name: 'Секция',
                  value: 9,
                  arrow: 'up'
                },
                {
                  name: 'Секция',
                  value: 10,
                  arrow: 'down'
                },
                {
                  name: 'Этаж',
                  value: 11,
                  arrow: 'up'
                },
                {
                  name: 'Этаж',
                  value: 12,
                  arrow: 'down'
                },
                {
                  name: 'Номер',
                  value: 13,
                  arrow: 'up'
                },
                {
                  name: 'Номер',
                  value: 14,
                  arrow: 'down'
                },
                {
                  name: 'Номер на площадке',
                  value: 15,
                  arrow: 'up'
                },
                {
                  name: 'Номер на площадке',
                  value: 16,
                  arrow: 'down'
                },
                {
                  name: 'Отделка',
                  value: 17,
                  arrow: 'up'
                },
                {
                  name: 'Отделка',
                  value: 18,
                  arrow: 'down'
                },
              ]}
              value={sort}
              onChange={e => {
                setSort(e.target.value)
              }}
            />
            {!mobile && <div className={sort % 2 != 0 ? 'apartment-search__sort-select-icon' : 'apartment-search__sort-select-icon active'}><SvgSortArrow /></div>}
          </div>
        </div>}


      </div>
      {!active && <List data={sortData} api={api} parking={parking} />}
      {active && <Table tabindex={tabindex} data={sortData} api={api} parking={parking} />}
    </div>
  )
}

export default Container;
