import {useState} from 'react'
import axios from 'axios';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import {useForm} from '../../hooks/use-form'
import InputField from '../_app/input-field'
import CheckboxField from '../_app/checkbox-field'
import { setAuth } from '../../redux/modules/site'
import { sendBookingRequest } from '../../redux/modules/form'

const OnlineRegistrationForm = ({api, objects, slug, close}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [smsSend, setSmsSend] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [fields, setValue, setFieldValid, formIsValid, trigger, setTrigger, setCheckValue] = useForm({
    fio: {
      value: '',
      name: 'fio',
      type: 'text',
      placeholder: 'Иванов Иван Иванович',
      valid: true
    },
    phone: {
      value: '',
      name: 'phone',
      type: 'tel',
      placeholder: '+7 (123) 456-78-90',
      validate: 'phone',
      valid: false,
      valid_reg: false
    },
    sms: {
      value: '',
      name: 'sms',
      type: 'text',
      placeholder: '123456',
      validate: 'text',
      valid: false
    },
    check: {
      value: false,
      name: 'check',
      valid: false
    }
  })

  const checkSmsButton = () => {
    const regex = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
    return smsSend || !regex.test(fields.phone.value);
  }

  const sendBooking = () => {
    const fd = new FormData();
    fd.append('FIO', fields.fio.value);
    fd.append('PHONE', fields.phone.value);
    fd.append('AGREE', +fields.check.value);
    fd.append('FROM_URL', router.asPath);
    fd.append('OBJECTS', objects);

    if(slug[2]){
      const message = `Благодарим за обращение в Est-a-Tet, ваша заявка принята! Квартира зарезервирована за вами на 2 часа. В течение этого времени мы актуализируем информацию у застройщика по наличию данного лота и вернемся к вам с обратной связью.`
      dispatch(sendBookingRequest(fd, api, close, message, true));
    }
    else{
      dispatch(sendBookingRequest(fd, api, close, '', true));
    }
  }

  const send = () => {
    setTrigger(true)
    if (!formIsValid() || !smsSend) return false

    const fd = new FormData();
    fd.append('phone', fields.phone.value);
    fd.append('code', fields.sms.value);
    fd.append('fio', fields.fio.value);

    if(typeof Comagic != 'undefined' && typeof(Comagic.addOfflineRequest) === "function"){
      Comagic.addOfflineRequest({name: fields.fio.value, phone: fields.phone.value, message: fields.sms.value});
    }

    axios
    .post('/ajax/json.php?page=user-login2', fd)
    .then(({ data }) => data)
    .then(data => {
      if(data){
        if(data.auth){
          axios
          .get('/ajax/json.php?page=user-info')
          .then(({ data }) => data)
          .then(response => {
            if(response.phone){
              dispatch(setAuth(response))
            }
          })
          .catch(function (error) {
            //dispatch(setAuth(true))
          })
          .then(function () {
            //dispatch(setAuth(true))
          });

          if(objects){
            sendBooking();
          }

        }
        if(data.success){

          axios
          .get('/ajax/json.php?page=user-info')
          .then(({ data }) => data)
          .then(response => {
            if(response.phone){
              dispatch(setAuth(response))
            }
          })
          .catch(function (error) {
            //dispatch(setAuth(true))
          })
          .then(function () {
            //dispatch(setAuth(true))
          });

          if(objects){
            sendBooking();
          }
          else{
            router.push('/lk')
          }
        }
        else{
          if(data.message){
            setErrorMessage(data.message)

            setTimeout(() => {
              setErrorMessage('')
            }, 5000);
          }
        }
      }
    })
  }

  const getSmsCode = () => {
    const fd = new FormData();
    fd.append('phone', fields.phone.value);

    axios
    .post('/ajax/json.php?page=user-login', fd)
    .then(({ data }) => data)
    .then(data => {
      if(data){
        if(data.auth){

          axios
          .get('/ajax/json.php?page=user-info')
          .then(({ data }) => data)
          .then(response => {
            if(response.phone){
              dispatch(setAuth(response))
            }
          })
          .catch(function (error) {
            //dispatch(setAuth(true))
          })
          .then(function () {
            //dispatch(setAuth(true))
          });

        }
        if(data.success){
          setSmsSend(true);
        }
        else{
          if(data.message){
            setErrorMessage(data.message)

            setTimeout(() => {
              setErrorMessage('')
            }, 5000);
          }
        }
      }
    })
  }

  return (
    <div className="form-gray call-request">
      <div className="online-registration__fotm-title"><span>Авторизуйтесь,</span> чтобы продолжить работу над вашей заявкой</div>
      {errorMessage && <div className="field-error">{errorMessage}</div>}
      <div className="input-title">ФИО</div>
      <InputField
        trigger={trigger}
        setFieldValid={setFieldValid}
        setValue={setValue}
        {...fields.fio}
      />
      <div className="call-request__field">
        <div className="input-title">Телефон</div>
        <InputField
          trigger={trigger}
          setFieldValid={setFieldValid}
          setValue={setValue}
          {...fields.phone}
        />
        <span className={checkSmsButton() ? "call-request__get-sms call-request__get-sms--sent" : "call-request__get-sms" } onClick={getSmsCode}>Выслать код</span>
      </div>
      {smsSend && (
        <>
          <div className="input-title">Код из СМС</div>
          <InputField
            trigger={trigger}
            setFieldValid={setFieldValid}
            setValue={setValue}
            {...fields.sms}
          />
        </>
      )}
      <CheckboxField
        trigger={trigger}
        setFieldValid={setFieldValid}
        setValue={setCheckValue}
        {...fields.check}
      />
      <div className="btn btn--border no-border-link call-request__btn" onClick={send}><span>Войти</span></div>
    </div>
  )
}

export default OnlineRegistrationForm
