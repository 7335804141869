import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { SvgSliderArrowLeft, SvgSliderArrowRight } from '../../icons';
import { setPopupPhoto } from '../../redux/modules/site';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowLeft />
    </div>
  );
}

const Photo = ({data, api}) => {
  const dispatch = useDispatch();
  const mobile = useSelector(state => state.mobile);

  const settings = {
    arrows: true,
    dots: false,
    infinite: mobile || (!mobile && data.length > 3),
    lazyLoad: true,
    speed: 500,
    slidesToShow: mobile ? 1 : 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const createLine = index => {
    if(index % 2 === 0){
      return <div className="building-photo-slide-line building-photo-slide-line1" />;
    }
    if(index % 3 === 0){
      return <div className="building-photo-slide-line building-photo-slide-line2" />;
    }

    return <div className="building-photo-slide-line building-photo-slide-line3" />;
  }

  const setPopup = index => {
    dispatch(setPopupPhoto(
      {
        current: index,
        list: data
      }
    ))
  }

  return(
    <div className="building-photo">
      <div className="frame">
        <div className="title">Фото</div>

        <Slider {...settings} className="building-photo-slider">
          {data && data.map((item, index) => (
            <div key={`item${index}`}>
              <div className="building-photo-slide-wrap" onClick={() => setPopup(index)}>
                <div className="building-photo-label">{item.label}</div>
                <div className="building-photo-slide" style={{ backgroundImage: `url('${api}${item.img}')`}}></div>
                {createLine(index)}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default Photo;