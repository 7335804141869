import Slider from 'react-slick';
import Link from 'next/link';

const PromoLabel = ({data}) => {

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true
  };

  return (
    <div className="appartment-by-id__promo">
      <div>
        {Array.isArray(data.text) && (
          <div className="appartment-by-id__promo-slider">
            <Slider {...settings}>
              {data.text.map((item, index) => {

                if(item.customLink){
                  return(
                    <div key={`item${index}`}>
                      <a href={item.customLink} target="_blank">
                        <div className="appartment-by-id__promo-title" dangerouslySetInnerHTML={{ __html: item.title }} />
                        <div className="appartment-by-id__promo-text" dangerouslySetInnerHTML={{ __html: item.text }} />
                      </a>
                    </div>
                  )
                }

                return (
                  <div key={`item${index}`}>
                    {item.id && (
                      <Link href={`/actions/${item.id}`}>
                        <a>
                          <div className="appartment-by-id__promo-title" dangerouslySetInnerHTML={{ __html: item.title }} />
                          <div className="appartment-by-id__promo-text" dangerouslySetInnerHTML={{ __html: item.text }} />
                        </a>
                      </Link>
                    )}
                    {!item.id && (
                      <div>
                        <div className="appartment-by-id__promo-title" dangerouslySetInnerHTML={{ __html: item.title }} />
                        <div className="appartment-by-id__promo-text" dangerouslySetInnerHTML={{ __html: item.text }} />
                      </div>
                    )}
                  </div>
                )
              })}
            </Slider>
          </div>
        )}
      </div>
    </div>
  )
}

export default PromoLabel;