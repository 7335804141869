import { SvgCheckbox } from '../../../icons';

const UiCheckbox = ({label, checked, onChange, border, value}) => {
  return(
    <div className="ui-checkbox" onClick={() => onChange(value)}>
      <div className={border ? `ui-checkbox__rect border ${checked ? 'active' : ''}` : `ui-checkbox__rect ${checked ? 'active' : ''}`}>
        <SvgCheckbox />
      </div>
      <div className={checked ? 'ui-checkbox__label checked' : 'ui-checkbox__label'}>{label}</div>
    </div>
  )
}

export default UiCheckbox;