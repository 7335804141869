import { useRouter } from 'next/router';
import Feedback from '../form/feedback';

const BoockingPopupAuth = ({api, close, slug, objects}) => {
  const router = useRouter();
  return (
    <div className="popup">
      <div className="popup__bg" onClick={close} />
      <div className="popup__in">
        <div className="popup__close" onClick={close} />
        <Feedback api={api} slug={router.query.slug || slug} close={close} objects={objects} />
      </div>
    </div>
  )
}

export default BoockingPopupAuth;