import Slider from 'react-slick';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { SvgSliderArrowRight, SvgSliderArrowLeft } from '../../icons';
import { setPopupPhoto } from '../../redux/modules/site';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowLeft />
    </div>
  );
}

const AboutApartment = ({slider, list, title}) => {
  const mobile = useSelector(state => state.mobile)
  const dispatch = useDispatch();
  const router = useRouter();

  const settings = {
    arrows: !mobile,
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const setPopup = index => {
    const tmp = [];

    slider.forEach(item => {
      tmp.push({img: item});
    })

    dispatch(setPopupPhoto(
      {
        current: index,
        list: tmp
      }
    ))
  }

  const createDsc = () => (
    <div>
      <ul className="apartment-about__list">
        {Array.isArray(list) && list.map((item, index) => <li key={`item${index}`}>{item}</li>)}
      </ul>
      <Link href={`/${router.query.slug[0]}/${router.query.slug[1]}`}><a className="btn btn--border no-border-link"><span>Подробнее об объекте</span></a></Link>
    </div>
  )

  return(
    <div className="apartment-about">
      <div className="frame">
        {title && <div className="title" dangerouslySetInnerHTML={{ __html: title }} />}

        <div className="apartment-about__in">
          {mobile && createDsc()}
          <div>
            <Slider {...settings}>
              {slider.map((item, index) => (
                <div key={`item${index}`}>
                  <div className="apartment-about__slide" onClick={() => setPopup(index)} style={{ backgroundImage: `url('${item}')` }}></div>
                </div>
              ))}
            </Slider>
          </div>
          {!mobile && createDsc()}
        </div>
      </div>
    </div>
  )
}

export default AboutApartment;