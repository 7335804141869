import { SvgNote } from '../../../icons';
import { useRouter } from 'next/router';
import { SvgNoPhoto } from '../../../icons';
import {useState} from "react";
import AddFavorite from "../../_app/AddFavorite";
import BoockingPopupAuth from "../BoockingPopupAuth";

const Table = ({data, api, parking, tabindex}) => {
    const frm = new Intl.NumberFormat('ru-RU');
    const router = useRouter();
    const [limit, setLimit] = useState(tabindex===0 ? 3 : 10);

    const to = id => {
        window.open(`/${router.query.slug[0]}/${router.query.slug[1]}/${id}`, '_blank');
    }

    const corpusSize = data.filter(item => !!item.corpus).length > 0;
    const termSize = data.filter(item => !!item.term).length > 0;
    const sectionSize = data.filter(item => !!item.section).length > 0;
    const floorSize = data.filter(item => !!item.floor).length > 0;
    const numberOnFloorSize = data.filter(item => !!item.numberOnFloor).length > 0;
    const numberSize = data.filter(item => !!item.number).length > 0;
    const roomsSize = data.filter(item => !!item.rooms).length > 0;
    const areaSize = data.filter(item => !!item.area).length > 0;
    const priceSize = data.filter(item => !!item.price || !!item.rent).length > 0;
    const finishingSize = data.filter(item => !!item.finishing).length > 0;
    const planSize = data.filter(item => !!item.plan).length > 0;
    const noteSize = data.filter(item => !!item.note).length > 0;

    const [popupBookingAuth, setPopupBookingAuth] = useState(false);
    const [objectpopup, setObjectpopup] = useState('');
    const [sort, setSort] = useState(1);

    const setPopup = (trigger, item) => {

        if(item){
            setObjectpopup(JSON.stringify([{"zhk":item.object_guid,"flats":[item.guid]}]));
            if(typeof gtag != 'undefined'){
                gtag('event', 'order_flate_click', {'event_category': 'order_flate_click'});
            }
            if(typeof ym != 'undefined'){
                ym(4831804, 'reachGoal', 'click_on_order_flate');
            }
        }

        setPopupBookingAuth(trigger);
    }

    const sorting = () => {
        if(sort == 1){
            data.sort((a, b) => {
                if (parseFloat(a.area) < parseFloat(b.area)) return -1;
                if (parseFloat(a.area) > parseFloat(b.area)) return 1;
                return 0;
            })
        }

        if(sort == 2){
            data.sort((a, b) => {
                if (parseFloat(a.area) > parseFloat(b.area)) return -1;
                if (parseFloat(a.area) < parseFloat(b.area)) return 1;
                return 0;
            })
        }

        if(sort == 3){
            data.sort((a, b) => {
                if (parseInt(a.price) < parseInt(b.price)) return -1;
                if (parseInt(a.price) > parseInt(b.price)) return 1;
                return 0;
            })
        }

        if(sort == 4){
            data.sort((a, b) => {
                if (parseInt(a.price) > parseInt(b.price)) return -1;
                if (parseInt(a.price) < parseInt(b.price)) return 1;
                return 0;
            })
        }

        if(sort == 5){
            data.sort((a, b) => {
                if (parseInt(a.corpus) < parseInt(b.corpus)) return -1;
                if (parseInt(a.corpus) > parseInt(b.corpus)) return 1;
                return 0;
            })
        }

        if(sort == 6){
            data.sort((a, b) => {
                if (parseInt(a.corpus) > parseInt(b.corpus)) return -1;
                if (parseInt(a.corpus) < parseInt(b.corpus)) return 1;
                return 0;
            })
        }

        if(sort == 7){
            data.sort((a, b) => {
                if (parseInt(a.term) < parseInt(b.term)) return -1;
                if (parseInt(a.term) > parseInt(b.term)) return 1;
                return 0;
            })
        }

        if(sort == 8){
            data.sort((a, b) => {
                if (parseInt(a.term) > parseInt(b.term)) return -1;
                if (parseInt(a.term) < parseInt(b.term)) return 1;
                return 0;
            })
        }

        if(sort == 9){
            data.sort((a, b) => {
                if (parseInt(a.section) < parseInt(b.section)) return -1;
                if (parseInt(a.section) > parseInt(b.section)) return 1;
                return 0;
            })
        }

        if(sort == 10){
            data.sort((a, b) => {
                if (parseInt(a.section) > parseInt(b.section)) return -1;
                if (parseInt(a.section) < parseInt(b.section)) return 1;
                return 0;
            })
        }

        if(sort == 11){
            data.sort((a, b) => {
                if (parseInt(a.floor) < parseInt(b.floor)) return -1;
                if (parseInt(a.floor) > parseInt(b.floor)) return 1;
                return 0;
            })
        }

        if(sort == 12){
            data.sort((a, b) => {
                if (parseInt(a.floor) > parseInt(b.floor)) return -1;
                if (parseInt(a.floor) < parseInt(b.floor)) return 1;
                return 0;
            })
        }

        if(sort == 13){
            data.sort((a, b) => {
                if (parseInt(a.number) < parseInt(b.number)) return -1;
                if (parseInt(a.number) > parseInt(b.number)) return 1;
                return 0;
            })
        }

        if(sort == 14){
            data.sort((a, b) => {
                if (parseInt(a.number) > parseInt(b.number)) return -1;
                if (parseInt(a.number) < parseInt(b.number)) return 1;
                return 0;
            })
        }

        if(sort == 15){
            data.sort((a, b) => {
                if (parseInt(a.numberOnFloor) < parseInt(b.numberOnFloor)) return -1;
                if (parseInt(a.numberOnFloor) > parseInt(b.numberOnFloor)) return 1;
                return 0;
            })
        }

        if(sort == 16){
            data.sort((a, b) => {
                if (parseInt(a.numberOnFloor) > parseInt(b.numberOnFloor)) return -1;
                if (parseInt(a.numberOnFloor) < parseInt(b.numberOnFloor)) return 1;
                return 0;
            })
        }

        if(sort == 17){
            data.sort((a, b) => {
                if (parseInt(a.finishing) < parseInt(b.finishing)) return -1;
                if (parseInt(a.finishing) > parseInt(b.finishing)) return 1;
                return 0;
            })
        }

        if(sort == 18){
            data.sort((a, b) => {
                if (parseInt(a.finishing) > parseInt(b.finishing)) return -1;
                if (parseInt(a.finishing) < parseInt(b.finishing)) return 1;
                return 0;
            })
        }

        if(sort == 19){
            data.sort((a, b) => {
                if (parseInt(a.rooms) < parseInt(b.rooms)) return -1;
                if (parseInt(a.rooms) > parseInt(b.rooms)) return 1;
                return 0;
            })
        }

        if(sort == 20){
            data.sort((a, b) => {
                if (parseInt(a.rooms) > parseInt(b.rooms)) return -1;
                if (parseInt(a.rooms) < parseInt(b.rooms)) return 1;
                return 0;
            })
        }

        return data;
    }
    const sortData = sorting();

    const setActiveSort = (e) => {
        const asc = e.target.getAttribute('data-asc');
        const desc = e.target.getAttribute('data-desc');
        if(sort == asc){
            setSort(desc);
        }else{
            setSort(asc);
        }
    }


    if(parking){
        return(
            <div className="building-result-table-wrap">
                <table className="building-result-table building-result-table-parking">
                    <thead className="building-result-table__head">
                    <tr>
                        {corpusSize && <td>Корпус</td>}
                        {termSize && <td>Срок ввода</td>}
                        {sectionSize && <td>Секция</td>}
                        {floorSize && <td>Этаж</td>}
                        {numberSize && <td>Номер</td>}
                        {areaSize && <td>Площадь,м2</td>}
                        {priceSize && <td>Цена, ₽</td>}
                        {planSize && <td>Планировка</td>}
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index) => {
                        if (index >= limit) return null
                        return <tr key={`item${item.id}`}>
                            {corpusSize && <td>{item.corpus}</td>}
                            {termSize && <td>{item.term}</td>}
                            {sectionSize && <td>{item.section}</td>}
                            {floorSize && <td>{item.floor}</td>}
                            {numberSize && <td>{item.number}</td>}
                            {areaSize && <td>{item.area}</td>}
                            {priceSize && (
                              <td>
                                  {!!item.price &&
                                  <div className="building-result-table__percent">{frm.format(item.price)}{item.sale &&
                                  <span>%{item.discountName && <i>{item.discountName}</i>}</span>}</div>}
                                  {!!item.rent &&
                                  <div className="building-result-table__percent">от {frm.format(item.rent)}</div>}
                              </td>
                            )}
                            {planSize && (<td className="building-result-no-photo">
                                {item.plan && <img src={`${api}${item.plan}`}/>}
                                {!item.plan && <SvgNoPhoto/>}
                            </td>)}
                            {noteSize && (
                              <td>
                                  {item.note && (
                                    <div className="building-result-table__note">
                                        <div>{item.note}</div>
                                        <SvgNote/>
                                    </div>
                                  )}
                              </td>
                            )}
                        </tr>
                    })}
                    </tbody>
                </table>
                {data.length > limit && <div className="btn btn--border news-feed__load-more no-border-link apartment-mortgage-programm__button" onClick={() => setLimit(limit + 10)}><span>Показать еще</span></div>}

            </div>
        )
    }

    return(
        <div className="building-result-table-wrap">
            <table className="building-result-table">
                <thead className="building-result-table__head">
                <tr>
                    {corpusSize && <td onClick={(e) => setActiveSort(e)} data-asc="5" data-desc="6" className={'sort'+(sort==5 ? ' asc' : sort==6 ? ' desc' : '')}>Корпус<i/></td>}
                    {termSize && <td onClick={(e) => setActiveSort(e)} data-asc="7" data-desc="8" className={'sort'+(sort==7 ? ' asc' : sort==8 ? ' desc' : '')}>Срок ввода<i/></td>}
                    {sectionSize && <td onClick={(e) => setActiveSort(e)} data-asc="9" data-desc="10" className={'sort'+(sort==9 ? ' asc' : sort==10 ? ' desc' : '')}>Секция<i/></td>}
                    {floorSize && <td onClick={(e) => setActiveSort(e)} data-asc="11" data-desc="12" className={'sort'+(sort==11 ? ' asc' : sort==12 ? ' desc' : '')}>Этаж<i/></td>}
                    {numberSize && <td onClick={(e) => setActiveSort(e)} data-asc="13" data-desc="14" className={'sort'+(sort==13 ? ' asc' : sort==14 ? ' desc' : '')}>Номер<i/></td>}
                    {numberOnFloorSize && <td onClick={(e) => setActiveSort(e)} data-asc="15" data-desc="16" className={'sort'+(sort==15 ? ' asc' : sort==16 ? ' desc' : '')}>Номер на площадке<i/></td>}
                    {roomsSize && <td onClick={(e) => setActiveSort(e)} data-asc="19" data-desc="20" className={'sort'+(sort==19 ? ' asc' : sort==20 ? ' desc' : '')}>Комнат<i/></td>}
                    {areaSize && <td onClick={(e) => setActiveSort(e)} data-asc="1" data-desc="2" className={'sort'+(sort==1 ? ' asc' : sort==2 ? ' desc' : '')}>Площадь,м2<i/></td>}
                    {priceSize && <td onClick={(e) => setActiveSort(e)} data-asc="3" data-desc="4" className={'sort'+(sort==3 ? ' asc' : sort==4 ? ' desc' : '')}>Цена, ₽<i/></td>}
                    {finishingSize && <td onClick={(e) => setActiveSort(e)} data-asc="17" data-desc="18" className={'sort'+(sort==17 ? ' asc' : sort==18 ? ' desc' : '')}>Отделка<i/></td>}
                    {planSize && <td>Планировка</td>}
                    <td>В избранное</td>
                    <td/>
                    <td/>
                </tr>
                </thead>
                <tbody>
                {sortData.map((item, index) =>{
                        return <tr key={`item${item.id}`} onClick={() => to(item.id)} data-hidden={index >= limit}>
                            {corpusSize && <td>{item.corpus}</td>}
                            {termSize && <td>{item.term}</td>}
                            {sectionSize && <td>{item.section}</td>}
                            {floorSize && <td>{item.floor}</td>}
                            {numberSize && <td>{item.number}</td>}
                            {numberOnFloorSize && <td>{item.numberOnFloor}</td>}
                            {roomsSize && <td>{item.rooms == 0 ? 'Студия' : item.rooms}</td>}
                            {areaSize && <td>{item.area}</td>}
                            {priceSize && (
                                <td>
                                    {!!item.price && <div className="building-result-table__percent">{frm.format(item.price)}{item.sale && <span>%{item.discountName && <i>{item.discountName}</i>}</span>}</div>}
                                    {!!item.rent && <div className="building-result-table__percent">от {frm.format(item.rent)}</div>}
                                </td>
                            )}
                            {finishingSize && <td>{item.finishing}</td>}
                            {planSize && (<td className="building-result-no-photo">
                                {item.plan && <img src={`${api}${item.plan}`} />}
                                {!item.plan && <SvgNoPhoto />}
                            </td>)}
                            <td><AddFavorite data={{id: item.id}} type={0} api={''} ky="flats" /></td>
                            <td className="td_but">
                                <a href={`/${router.query.slug[0]}/${router.query.slug[1]}/${item.id}`} target={'_blank'} className={'-no_vis_link'}>{item?.number}</a>
                                <div className="appartment-by-id__header-button appartment-by-id__header-button--red" onClick={e => {e.stopPropagation(); setPopup(true, item)}}><div>Оставить заявку</div><div /></div>
                            </td>
                            {noteSize && (
                                <td>
                                    {item.note && (
                                        <div className="building-result-table__note">
                                            <div>{item.note}</div>
                                            <SvgNote />
                                        </div>
                                    )}
                                </td>
                            )}
                        </tr>
                    }
                )}
                </tbody>
            </table>
            {data.length > limit && <div className="btn btn--border news-feed__load-more no-border-link apartment-mortgage-programm__button" onClick={() => setLimit(limit + 10)}><span>Показать еще</span></div>}

            {popupBookingAuth && <BoockingPopupAuth api={api} close={() => setPopup(false)} objects={objectpopup} />}
        </div>
    )
}

export default Table;
