import {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { SvgSelectArrow } from '../../../icons';
import Container from './Container';
import { declOfNum } from '../../../utils';

const Tab = ({room, data, api, name_list, parking, index}) => {



    const router = useRouter();
    const mobile = useSelector(state => state.mobile);

    const frm = new Intl.NumberFormat('ru-RU');

    const [active, setActive] = useState(index === 0);

    const areaMin = [];
    const priceMin = [];

    data.forEach(item => {
        areaMin.push(item.area);
        priceMin.push(item.price ? item.price : item.rent);
    });

    const areaMinResult = Math.min(...areaMin);
    const priceMinResult = Math.min(...priceMin);

    const getPrice = () => {
      if (!isNaN(priceMinResult) && typeof priceMinResult == 'number') return `от ${frm.format(priceMinResult)} ₽`
      // return frm.format(priceMinResult)
      return ''
    }

  useEffect(() => {
    window.scrollTo(0,window.scrollY+1);
  },[active]);

    if(parking){
        return(
            <>
                <div className={active ? 'building-result-tab active' : 'building-result-tab'} onClick={() => setActive(!active)}>
                    {room == 1 && (
                        <div>
                            <div className="building-result-tab__title">Индивидуальные машиноместа</div>
                            <div>({data.length} {declOfNum(data.length, name_list)})</div>
                        </div>
                    )}
                    {room == 2 && (
                        <div>
                            <div className="building-result-tab__title">Зависимые машиноместа</div>
                            <div>({data.length} {declOfNum(data.length, name_list)})</div>
                        </div>
                    )}
                    {room == 0 && (
                        <div>
                            <div className="building-result-tab__title">Машиноместа</div>
                            <div>({data.length} {declOfNum(data.length, name_list)})</div>
                        </div>
                    )}
                    <div>
                        <div className="building-result-tab__area">от {areaMinResult} м²</div>
                        <div className="building-result-tab__price">{getPrice()}</div>
                        <div className="building-result-tab__arrow">
                            <SvgSelectArrow />
                        </div>
                    </div>
                </div>
{/*
              {active && <Container tabindex={index} data={data} api={api} parking />}
*/}
              <Container tab_active={active} tabindex={index} data={data} api={api} parking />
            </>
        )
    }

    if(router.query.slug[0] === 'catalog_cre' || router.query.slug[0] === 'catalog_country'){
        return(
            <>
                <div className={active ? 'building-result-tab active' : 'building-result-tab'} onClick={() => setActive(!active)}>
                    <div>
                        {room?<div className="building-result-tab__title">{room}-{mobile?'к':'комнатные'} {name_list[3]!=='все' && name_list[3]}</div>:<div className="building-result-tab__title capital">{name_list[3]!=='все' && name_list[3]}</div>}
                        {name_list[3]!=='все' ?
                            <div>({data.length} {declOfNum(data.length, name_list)})</div>
                            :
                            <div>({data.length} {declOfNum(data.length, ['дом', 'дома', 'домов'])})</div>
                        }
                    </div>
                    <div>
                        <div className="building-result-tab__area">от {areaMinResult} м²</div>
                        <div className="building-result-tab__price">{getPrice()}</div>
                        <div className="building-result-tab__arrow">
                            <SvgSelectArrow />
                        </div>
                    </div>
                </div>
{/*
              {active && <Container tabindex={index} data={data} api={api} />}
*/}
              <Container tab_active={active} tabindex={index} data={data} api={api} />
            </>
        )
    }

    return(
        <>
            <div className={active ? 'building-result-tab active' : 'building-result-tab'} onClick={() => setActive(!active)}>
                {room == 0 && (
                    <div>
                        <div className="building-result-tab__title">Студии</div>
                        <div>({data.length} {declOfNum(data.length, ['студия', 'студии', 'студий'])})</div>
                    </div>
                )}
                {room != 0 && (
                    <div>
                        <div className="building-result-tab__title">
                            {name_list[3]==='кладовки'
                                ?
                                'Кладовки'
                                :
                                room+'-комнатные '+(!mobile && name_list[3]!=='все' ? name_list[3]:'')
                            }

                        </div>
                        <div>({data.length} {declOfNum(data.length, name_list)})</div>
                    </div>
                )}
                <div>
                    <div className="building-result-tab__area">от {areaMinResult} м²</div>
                    <div className="building-result-tab__price">{getPrice()}</div>
                    <div className="building-result-tab__arrow">
                        <SvgSelectArrow />
                    </div>
                </div>
            </div>
{/*
          {active && <Container tabindex={index} data={data} api={api} />}
*/}
          <Container tab_active={active} tabindex={index} data={data} api={api} />
        </>
    )
}

export default Tab;
