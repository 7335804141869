import { useScrollAnimation } from '../../hooks/use-scroll-animation'
import React from "react";
import { useEffect, useState } from "react";

import Link from '../_app/link'
import HeadMeta from '../_app/HeadMeta';

const NotFound = ({mobile}) => {
  const [ready, setReady] = useState(false);
  useScrollAnimation();
	
	useEffect(() => {
		if(window.ym) ym(4831804,'reachGoal','webit_404_error'); 
	}, [])

  return (
    <>
      <HeadMeta data={{title: '404'}} />
      <div className='error frame'>
        <div className="error__content">
          <div className="error__numbers">
            <span>4</span>
            <span>0</span>
            <span>4</span>
          </div>
          <div className="error__text">
            <div className="error__title">
              Объект не найден
            </div>
            Неправильно набран адрес или такой страницы на сайте не существует. <br/>
            Перейдите на <Link to="/" className="border-link"><span>главную страницу</span></Link> или воспользуйтесь меню.
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
