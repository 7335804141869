import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router'
import Select from '@material-ui/core/Select';
import { useScrollAnimation } from '../../hooks/use-scroll-animation'
import { SvgSaveSearch } from '../../icons';
import UiRangeField from '../_app/filter-fields/UiRangeField';
import UiSelectField from '../_app/filter-fields/UiSelectField';
import UiCheckBoxes from '../_app/filter-fields/UiCheckBoxes';
import UiSelectFieldMultiple from '../_app/filter-fields/UiSelectFieldMultiple';
import UiSelectFieldMultipleChip from '../_app/filter-fields/UiSelectFieldMultipleChip';
import UiCheckbox from '../_app/filter-fields/UiCheckbox';
import UiVoiceSearch from '../_app/filter-fields/UiVoiceSearch';
import InputSearchWord from '../_app/filter-fields/InputSearchWord';
import { SvgSelectArrow, SvgMobileSelectArrows } from '../../icons';
import {
  moduleName,
  changeTab,
  setFilterValue,
  fetchData,
  fetchDataMap
} from '../../redux/modules/apartment-search';
import { setSaveSearch } from '../../redux/modules/site'

const Filter = ({filter, filterTop, selectButton, api, sort, type, setSimilar, setType, minMaxLatLng}) => {

  const router = useRouter();
  const dispatch = useDispatch();
  const tab = useSelector(state => state[moduleName].tab);
  const values = useSelector(state => state[moduleName].values);
  const mobile = useSelector(state => state.mobile);
  const [objectId, setObjectId] = useState(router.query.objectId || '');

  const [more, setMore] = useState(false)

  useEffect(() => {
    if(Array.isArray(minMaxLatLng)){
      setMore(false);

      const oid = objectId || '';
      const srch = values.search || '';
      const valuesTmp = {...values};

      for(let i in valuesTmp){
        if(!valuesTmp[i] || valuesTmp[i] == 0){
          delete valuesTmp[i];
        }
      }

      const fd = new FormData();
      fd.append('pg', 1);
      fd.append('sort', sort);
      fd.append('view', type);
      fd.append('catalog', router.query.slug[0]);

      const a = filter[tab].filters.filter(item => item.type === 'slider');
      const b = filter[tab].extras.filter(item => item.type === 'slider');
      const r = a.concat(b)

      for(let i in values){
        if(Array.isArray(values[i])){
          values[i].forEach(k => {
            const t = r.filter(item => item.name == i)[0]
            if(t){
              if(values[i][0] != t.data[0] || values[i][1] != t.data[1]){
                fd.append(i + '[]', k);
              }
            }
            else{
              fd.append(i + '[]', k);
            }
          });
        }
        else{
          if(i == 'search' &&  srch){
            fd.append(i, srch);
          }
          else{
            if(values[i] && values[i] != 0){
              fd.append(i, values[i]);
            }
          }
        }
      }

      if(oid){
        fd.append('objectId', oid);
      }

      fd.append('minLat',minMaxLatLng[0][0])
      fd.append('minLon',minMaxLatLng[0][1])
      fd.append('maxLat',minMaxLatLng[1][0])
      fd.append('maxLon',minMaxLatLng[1][1])

      router.replace(router.query.slug[0], undefined, { shallow: true });
      dispatch(fetchData(api, fd));
    }
  },[minMaxLatLng]);

  const clearValues = (wr) => {
    if(filter){
      const tmp = {};

      //return  false;

      filter[tab].filters.forEach(item => {
        if(item.type === 'slider'){
          if(router.query[item.name] && !wr){
            tmp[item.name] = router.query[item.name];
          }
          else{
            tmp[item.name] = item.data;
          }
        }
        if(item.type === 'tabs' || item.type === 'multiple-select' || item.type === 'multiple-chips' || item.type === 'checkboxes'){
          if(router.query[item.name] && !wr){
            tmp[item.name] = Array.isArray(router.query[item.name]) ? router.query[item.name] : router.query[item.name].split()
          }
          else{
            tmp[item.name] = []
          }
        }
        if(item.type === 'single-select'){
          if(router.query[item.name] && !wr){
            tmp[item.name] = router.query[item.name];
          }
          else{
            tmp[item.name] = item.data[0].value;
          }
        }
        if(item.type === 'voice-search'){
          if(router.query[item.name] && !wr){
            tmp[item.name] = router.query[item.name];
          }
          else{
            tmp[item.name] = '';
          }
        }
        if(item.type === 'hidden'){
          tmp[item.name] = item.value;
        }
      });

      filter[tab].extras.forEach(item => {
        if(item.type === 'slider'){
          if(router.query[item.name] && !wr){
            tmp[item.name] = router.query[item.name];
          }
          else{
            tmp[item.name] = item.data;
          }
        }
        if(item.type === 'tabs' || item.type === 'multiple-select' || item.type === 'multiple-chips' || item.type === 'checkboxes'){
          if(router.query[item.name] && !wr){
            tmp[item.name] = Array.isArray(router.query[item.name]) ? router.query[item.name] : router.query[item.name].split()
          }
          else{
            tmp[item.name] = []
          }
        }
        if(item.type === 'single-select'){
          if(router.query[item.name] && !wr){
            tmp[item.name] = router.query[item.name];
          }
          else{
            tmp[item.name] = item.data[0].value;
          }
        }
        if(item.type === 'voice-search'){
          if(router.query[item.name] && !wr){
            tmp[item.name] = router.query[item.name];
          }
          else{
            tmp[item.name] = '';
          }
        }
        if(item.type === 'hidden'){
          tmp[item.name] = item.value;
        }
      });

      return tmp;
    }
  }

  const setQueries = (params, index) => {
    const catalog = index ? filter[index].type : filter[tab].type ;
    router.push({
      pathname: `/${catalog}`,
      query: {...params, view: type},
      //query: {...values, view: type},
      //pathname: `/${filter[index].type}`,
    }, undefined, {shallow: true })


  }

  const createValues = () => {
    if(filter){
      const tmp = clearValues();
      dispatch(setFilterValue(tmp))
    }
  }

  useEffect(() => {
    if(filter[tab].type == router.query.slug[0]){
      const tmp = clearValues();
      const fd = new FormData();
      fd.append('pg', (router?.query?.page ? router?.query?.page : 1));
      fd.append('sort', sort);
      fd.append('view', type);
      fd.append('catalog', filter[tab].type);

      const a = filter[tab].filters.filter(item => item.type === 'slider');
      const b = filter[tab].extras.filter(item => item.type === 'slider');
      const r = a.concat(b)

      for(let i in tmp){
        if(Array.isArray(tmp[i])){
          tmp[i].forEach(k => {
            const t = r.filter(item => item.name == i)[0]
            if(t){
              if(tmp[i][0] != t.data[0] || tmp[i][1] != t.data[1]){
                fd.append(i + '[]', k);
              }
            }
            else{
              fd.append(i + '[]', k);
            }
          });
        }
        else{
          if(tmp[i] && tmp[i] != 0){
            fd.append(i, tmp[i]);
          }
        }
      }

      for(let i in router.query){
        if(values){
          if(i != 'slug' && i != 'view' && i != 'objectId'){
            if(tmp[i] === undefined){
              fd.append(i, router.query[i]);
            }
          }
        }
      }

      if(values){
        for(let i = 0; i < r.length; i++){
          const name = r[i].name;
          const val = r[i].data;

          if(val && values[name]){
            if( (val[0] == values[name][0] ) && ( val[1] == values[name][1] )){
              fd.delete(name + '[]')
            }
          }
        }
      }

      if(objectId){
        fd.append('objectId', objectId);
      }

      dispatch(fetchData(api, fd));
      dispatch(fetchDataMap(api, fd));
      createValues();
      setSimilar(null);
    }
  },[tab, type, router.query.type, router.query.gk]);

  useEffect(() => {
    if(router.query.slug){
      filter.forEach((item, index) => {
        if(item.type == router.query.slug[0]){
          dispatch(changeTab(index));
        }
      })
    }
  },[router.query]);

  /*useEffect(() => {
    if(router.query.slug){
      filter.forEach((item, index) => {
        if(item.type == router.query.slug[0]){
          dispatch(changeTab(index));
        }
      })
    }
  },[router.query.type, router.query.gk]);*/

  useScrollAnimation()

  const saveSearchParams = () => {
    if(window){
      if(window.localStorage){
        setQueries({...values});

        setTimeout(() => {
          window.localStorage.setItem('saveSearch', window.location.href);
        },1000);
      }
    }

    const element = document.querySelector('.filter__save-search > div > svg');
    const elementAnim = document.querySelector('.filter__save-search span');
    const fileIcon = document.querySelector('.header .file-icon');

    const fileIconX = fileIcon.getBoundingClientRect().x;
    const fileIconY = fileIcon.getBoundingClientRect().y;
    const elementX = element.getBoundingClientRect().x;
    const elementY = element.getBoundingClientRect().y;

    const x = Math.abs(elementX - fileIconX);
    const y = Math.abs(elementY - fileIconY);

    const speed = 500;

    elementAnim.classList.add('active');
    elementAnim.style.transition = `${speed}ms transform, ${speed}ms opacity`;
    elementAnim.style.transform = `translateY(-${y}px) translateX(${x}px)`;
    elementAnim.style.opacity = 0;

    setTimeout(() => {
      elementAnim.classList.remove('active');
      elementAnim.removeAttribute("style")
    },speed);

    dispatch(setSaveSearch(router.asPath));

		if(window.ym) ym(4831804,'reachGoal','webit_click_saveSerch');
  }

  const valueChange = (value, array) => {
    const tmp = [...array];
    const index = tmp.indexOf(value);

    if(index === -1){
      tmp.push(value);
    }
    else{
      tmp.splice(index, 1);
    }

    return tmp;
  }

  const apply = (search, objId) => {
    setMore(false);

    const oid = objId || objectId;
    const srch = typeof search === 'string' ? search : values.search;
    const valuesTmp = {...values};

    for(let i in valuesTmp){
      if(!valuesTmp[i] || valuesTmp[i] == 0){
        delete valuesTmp[i];
      }
    }

    if(srch){
      //setQueries({...valuesTmp, search: srch});

      if(oid){
        //setQueries({...valuesTmp, search: srch, objectId: oid});
      }
      else{
        //setQueries({...valuesTmp, search: srch});
      }
    }
    else{
      //setQueries({...valuesTmp});
      //setQueries({...values});
    }
    const fd = new FormData();
    fd.append('pg', 1);
    fd.append('sort', sort);
    fd.append('view', type);
    fd.append('catalog', router.query.slug[0]);

    const a = filter[tab].filters.filter(item => item.type === 'slider');
    const b = filter[tab].extras.filter(item => item.type === 'slider');
    const r = a.concat(b)

    for(let i in values){
      if(Array.isArray(values[i])){
        values[i].forEach(k => {
          const t = r.filter(item => item.name == i)[0]
          if(t){
            if(values[i][0] != t.data[0] || values[i][1] != t.data[1]){
              fd.append(i + '[]', k);
            }
          }
          else{
            fd.append(i + '[]', k);
          }
        });
      }
      else{
        if(i == 'search' &&  srch){
          fd.append(i, srch);
        }
        else{
          if(values[i] && values[i] != 0){
            fd.append(i, values[i]);
          }
        }
      }
    }

    if(oid){
      fd.append('objectId', oid);
    }

    router.replace(router.query.slug[0], undefined, { shallow: true });
    dispatch(fetchData(api, fd));
    dispatch(fetchDataMap(api, fd));
    console.log('fd', fd)


    const top = document.querySelector('.apartment-search__frame').getBoundingClientRect().top + window.scrollY - (mobile ? 0 : 80);
    window.scrollTo({
      top,
      behavior: "smooth"
    });

		if(window.ym) ym(4831804,'reachGoal','webit_click_filter');
  }

  const clear = () => {
    setMore(false);
    setQueries({});
    const tmp = clearValues(true);
    dispatch(setFilterValue(tmp))
    setObjectId('')

    const fd = new FormData();
    fd.append('pg', 1);
    fd.append('sort', sort);
    fd.append('view', type);
    fd.append('catalog', router.query.slug[0]);

    const a = filter[tab].filters.filter(item => item.type === 'slider');
    const b = filter[tab].extras.filter(item => item.type === 'slider');
    const r = a.concat(b)

    for(let i in tmp){
      if(Array.isArray(tmp[i])){
        tmp[i].forEach(k => {
          const t = r.filter(item => item.name == i)[0]
          if(t){
            if(tmp[i][0] != t.data[0] || tmp[i][1] != t.data[1]){
              fd.append(i + '[]', k);
            }
          }
          else{
            fd.append(i + '[]', k);
          }
        });
      }
      else{
        if(tmp[i] && tmp[i] != 0){
          fd.append(i, tmp[i]);
        }
      }
    }

    router.replace(router.query.slug[0], undefined, { shallow: true });
    dispatch(fetchData(api, fd));
    dispatch(fetchDataMap(api, fd));
  }

  const clearTab = () => {
    createValues();
  }

  const changeTabFilter = index => {
    setMore(false);
    //dispatch(changeTab(index));
    setObjectId('');
    //setType(filter[index].types[0].value)
    clearTab();
    router.push({
      pathname: `/${filter[index].type}`,
      //query: {...values, view: type},
    })
  }

  const changeElementValue = (value, name, type) => {
    const tmp = {...values};

    let result = value;

    if(type === 'tabs' || type === 'checkboxes'){
      result = valueChange(value, tmp[name]);
    }

    tmp[name] = result;

    dispatch(setFilterValue(tmp));
    //setQueries(tmp);
  }

  const chipRemove = (value, name) => {
    const tmp = {...values};
    const result = valueChange(value, tmp[name]);
    tmp[name] = result;
    dispatch(setFilterValue(tmp));
  }

  const checkTargetElement = item => {
    if(item.targetElement){
      const tmp = {...values};

      const val = tmp[item.targetElement];

      if(val){
        if(item.targetValue.indexOf(val + '') != -1){
          return true;
        }
      }
      return false;
    }

    return true;
  }

  const createFilters = currentFilter => {
    if(filter && values){
      const array = [];

      currentFilter.forEach(item => {
        const options = currentFilter.filter(k => k.name == item.name)[0].data;
        const cl = item.single ? 'filter__fields-cell single' : 'filter__fields-cell';

        if(item.type === 'voice-search' && checkTargetElement(item)){
          //if(values[item.name]){
            array.push(
              <div className={cl} key={`item${item.name}`}>
                <div className="filter-field">
                  <div className="filter-field__title">{item.label}</div>
                  <InputSearchWord
                    //search={search}
                    val={values.search || ''}
                    api={api}
                    defaultValue={router.query.search ? {name: router.query.search, value: router.query.search} : null}
                    setSearch={value => {
                      changeElementValue(value.text, item.name, item.type)
                      if(value.text){
                        apply(value.text, value.objectId);
                        setObjectId(value.objectId);
                      }
                    }}
                    changeSearchValue={value => {
                      changeElementValue(value, item.name, item.type)
                      setObjectId('');
                    }}
                    apply={apply}
                  />
                </div>
              </div>
            )
          //}
        }

        if(item.type === 'voice-search-input' && checkTargetElement(item)){
          if(values[item.name] !== undefined){
            array.push(
              <div className={cl} key={`item${item.name}`}>
                <UiVoiceSearch
                  value={values[item.name]}
                  title={item.label}
                  onChange={value => {
                    changeElementValue(value, item.name, item.type)
                  }}
                />
              </div>
            )
          }
        }

        /*if(item.type === 'voice-search' && checkTargetElement(item)){
          if(values[item.name] !== undefined){
            array.push(
              <div className={cl} key={`item${item.name}`}>
                <UiVoiceSearch
                  value={values[item.name]}
                  title={item.label}
                  onChange={value => {
                    changeElementValue(value, item.name, item.type)
                  }}
                />
              </div>
            )
          }
        }*/

        if(item.type === 'single-select' && checkTargetElement(item)){
          if(values[item.name] !== undefined){
            array.push(
              <div className={cl} key={`item${item.name}`}>
                <UiSelectField
                  options={[...options]}
                  value={values[item.name]}
                  onChange={e => {
                    changeElementValue(e.target.value, item.name, item.type)
                  }}
                  title={item.label}
                />
              </div>
            )
          }
        }
        if(item.type === 'slider' && checkTargetElement(item)){
          if(values[item.name] !== undefined){
            array.push(
              <div className={cl} key={`item${item.name}`}>
                <UiRangeField
                  defaultValue={values[item.name]}
                  min={options[0]}
                  max={options[1]}
                  onChange={vals => {
                    changeElementValue(vals, item.name, item.type)
                  }}
                  title={item.label}
                />
              </div>
            )
          }
        }
        if(item.type === 'tabs' && checkTargetElement(item)){
          if(values[item.name] !== undefined){
            array.push(
              <div className={cl} key={`item${item.name}`}>
                <UiCheckBoxes
                  options={options}
                  values={values[item.name]}
                  onChange={val => {
                    changeElementValue(val, item.name, item.type)
                  }}
                  title={item.label}
                />
              </div>
            )
          }
        }
        if(item.type === 'multiple-select' && checkTargetElement(item)){
          if(values[item.name] !== undefined){
            array.push(
              <div className={cl} key={`item${item.name}`}>
                <UiSelectFieldMultiple
                  options={options}
                  values={values[item.name]}
                  onChange={e => {
                    changeElementValue(e.target.value, item.name, item.type);
                  }}
                  title={item.label}
                />
              </div>
            )
          }
        }
        if(item.type === 'multiple-chips' && checkTargetElement(item)){
          if(values[item.name] !== undefined){
            array.push(
              <div className={cl} key={`item${item.name}`}>
                <UiSelectFieldMultipleChip
                  options={options}
                  values={values[item.name]}
                  onChange={e => {
                    changeElementValue(e.target.value, item.name, item.type);
                  }}
                  title={item.label}
                  chipRemove={val => {
                    chipRemove(val, item.name);
                  }}
                />
              </div>
            )
          }
        }
        if(item.type === 'checkboxes' && checkTargetElement(item)){
          if(values[item.name] !== undefined){
            array.push(
              <div className={cl} key={`item${item.name}`}>
                <div>
                  <div className="filter-field__title">{item.label}</div>
                  <div className="filter__checkbox-row">
                    {options.map(k => (
                      <UiCheckbox
                        key={`item${k.value}`}
                        label={k.name}
                        checked={values[item.name].includes(k.value)}
                        onChange={val => {
                          changeElementValue(val, item.name, item.type);
                        }}
                        value={k.value}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )
          }
        }
      })

      return array;
    }
  }

  if (!filter || !values) return null;

  const countExtrasValue = () => {
    const tmp = {...values};
    const extras = filter[tab].extras.filter(item => item.type === 'slider')

    filter[tab].filters.forEach(item => {
      delete tmp[item.name]
    });

    let count = 0;

    for(let i in tmp){
      if(Array.isArray(tmp[i])){
        const slider = extras.filter(item => item.name == i)[0];
        if(slider){
          if(slider.data[0] != tmp[i][0] || slider.data[1] != tmp[i][1]){
            count++;
          }
        }
        else{
          if(tmp[i].length){
            count++;

          }
        }
      }
      else{
        if(tmp[i] && tmp[i] != 0){
          count++;
        }
      }
    }

    if(count){
      return <i>{count}</i>
    }
  }

  return (
    <div className="filter">
      <div className="frame">

        {filterTop && filterTop}

        {mobile && !filterTop && (
          <div className="filter-mobile-tabs">
            <Select
              native
              value={tab}
              onChange={e => {
                changeTabFilter(e.target.value)
              }}
              IconComponent={() => <SvgMobileSelectArrows />}
            >
              {filter.map((item, index) => (
                <option
                  key={`item${index}`}
                  value={index}
                >
                  {item.name}
                </option>
              ))}
            </Select>

            недвижимость
          </div>
        )}

        {!mobile && !filterTop && (
          <div className="filter__top">
            <div className="filter__menu">
              {filter.map((item, index) => (
                <div
                  key={`item${index}`}
                  className={index == tab ? 'active' : ''}
                  onClick={() => {
                    if(tab != index){
                      changeTabFilter(index)
                    }
                  }}
                >
									{index == tab
										? <h1 className="active">{item.name}{filter.length > 1 ? ' недвижимость' : ''}</h1>
										: item.name
									}
                </div>
              ))}
            </div>
            <div className="filter__save-search" onClick={saveSearchParams}>
              <div><SvgSaveSearch /><span><SvgSaveSearch /></span></div><div>Сохранить поиск</div>
            </div>
          </div>
        )}

        <div className="filter__center">
          <div className="filter__fields">
            {createFilters(filter[tab].filters)}
            {selectButton && (
              <div className="building-filter-select-button">
                <div className="filter-field__title">&nbsp;</div>
                {selectButton}
              </div>
            )}
            {!mobile && (
              <>
                {/*<div className="filter-sep" />*/}
                <div>
                  <div className="filter-field__title">&nbsp;</div>
                  <div className="btn btn--border no-border-link apartment-search__btn apartment-search__btn-filter" onClick={apply}>
                    <span>Применить</span>
                  </div>
                </div>
                <div>
                  <div className="filter-field__title">&nbsp;</div>
                  <div className="filter-clear filter-clear_top" onClick={clear}>Сбросить фильтры</div>
                </div>
              </>
            )}
          </div>
          <div className="filter__more-wrap">
            {filter[tab].extras.length !== 0 && (
              <div className={more ? 'filter__more active' : 'filter__more'} onClick={() => setMore(!more)}>
                <div>Ещё фильтры</div>
                <span><SvgSelectArrow /></span>
                {countExtrasValue()}
              </div>
            )}
          </div>
        </div>
      </div>

      {(more && filter[tab].extras.length !== 0) && (
        <div className="filter-hidden">
          <div className="filter-hidden-extras">
            {createFilters(filter[tab].extras)}
          </div>

          {!mobile && (
            <div className="filter__result-row">
              <div className="btn btn--border no-border-link apartment-search__btn" onClick={apply}>
                <span>Применить</span>
              </div>
              {/*<div className="filter-clear" onClick={clear}>Сбросить фильтры</div>*/}
            </div>
          )}
        </div>
      )}

      {mobile && (
        <div className="filter__result-row">
          <div className="btn btn--border no-border-link apartment-search__btn" onClick={apply}>
            <span>Применить</span>
          </div>
          <div className="filter-clear" onClick={clear}>Сбросить фильтры</div>
        </div>
      )}
    </div>
  )
}

export default Filter;
