import { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import UiCheckBoxes from '../_app/filter-fields/UiCheckBoxes';
import UiCheckbox from '../_app/filter-fields/UiCheckbox';
import { SvgRightArrow } from '../../icons';
// import dataAjax from '../../redux/mock/building-chart';
import {useSelector} from "react-redux";
import axios from "axios";

const Charts = ({data, api, query}) => {
  const frm = new Intl.NumberFormat('ru-RU');
  const [active, setActive] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [price, setPrice] = useState(null);
  const [period, setPeriod] = useState(null);
  const [dataAjax, setDataAjax] = useState([]);
  const mobile = useSelector(state => state.mobile)
  if(!data) return null;

  useEffect(() => {
    if (data.price && data.price[0]) setPrice(data.price[0].value);
    if (data.period && data.period[0]) setPeriod(data.period[0].value);
  },[]);

  // https://estatet2.multicontent.ru
  // /ajax/json.php
  // ?page=apartment-building-averageprice
  // &catalog=catalog_new
  // &code=baires_zhk
  // &price=stoimost
  // &period=month

  useEffect(async () => {
    if (price && period) {
      const response = await axios.get(api + `/ajax/json.php?page=apartment-building-averageprice&catalog=${query.slug[0]}&code=${query.slug[1]}&price=${price}&period=${period}`);
      setDataAjax(response.data[0])
    }
  },[price,period]);


  const changeRooms = value => {
    const tmp = [...rooms];
    const index = tmp.indexOf(value);

    if(index === -1){
      tmp.push(value);
    }
    else{
      tmp.splice(index, 1);
    }

    setRooms(tmp);
  }

  const createCustomDot = (dotProps) => {
    if (dotProps.index < dataAjax.length - 1) return null
    return <svg>
      <circle r="7" strokeWidth="0" stroke="none" fill={dotProps.stroke} cx={dotProps.cx} cy={dotProps.cy} />
      <text x={dotProps.cx} y={dotProps.cy} textAnchor="middle"  className="text-small" dy=".3em">{dotProps.rooms === 'Студия' ? 'c' : dotProps.rooms}</text>
      {/*<text x={dotProps.cx - 2} y={dotProps.cy + 3} className="text-small">{dotProps.rooms}</text>*/}
    </svg>
  }
  const createCharts = () => {
    if (!data.rooms) return [<Line strokeWidth={3} dataKey="value" stroke="#ed1c24" activeDot={false} />]
    const array = [];
    {data.rooms.forEach(item => {
      if(rooms.length === 0 || rooms.includes(item.value)){
        array.push(<Line key={item.value} strokeWidth={3} dataKey={item.value} stroke={item.activeColor} activeDot={false} dot={(props) => {
          return createCustomDot({...props, rooms: item.name})
        }} />)
      }
    })}

    return array;
  }


  if(!price || !period) return null;
  return(
      <div className="building-chart-wrapper">
        <div className="building-chart-title frame">
          <div>График изменения цены</div>
          <div>
            <div className="building-chart-title__button" onClick={() => setActive(!active)}>
              {mobile && <div>{active ? 'Скрыть график цен' : 'Показать график цен'}</div>}
              {!mobile && <div>{active ? 'Скрыть график изменения цены' : 'Показать график изменения цены'}</div>}
              <SvgRightArrow />
            </div>
          </div>
        </div>
        {active && <div className="building-chart-filter frame">
          <div>
            {data.rooms && <div className="building-chart-filter__checkboxes">
              <UiCheckBoxes
                  options={data.rooms}
                  values={rooms}
                  onChange={value => changeRooms(value)}
                  title="Комнат"
              />
            </div>}
          </div>
          <div>
            {data.price.map(item => (
                <UiCheckbox
                    key={item.value}
                    label={item.name}
                    checked={item.value === price}
                    onChange={() => setPrice(item.value)}
                    value={item.value}
                />
            ))}
          </div>
          <div>
            {data.period.map(item => (
                <UiCheckbox
                    key={item.value}
                    label={item.name}
                    checked={item.value === period}
                    onChange={() => setPeriod(item.value)}
                    value={item.value}
                />
            ))}
          </div>
        </div>}
        {active && (
            <div className="building-chart frame">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    width={500}
                    height={300}
                    data={dataAjax}
                    margin={{
                      top: 10,
                      right: 20,
                      left: 50,
                      bottom: 30,
                    }}
                >
                  <CartesianGrid />

                  <YAxis
                      axisLine={false}
                      tickLine={false}
                      domain={['auto', 'auto']}
                      style={{fontSize: '10px',fontFamily: 'Travels', fontWeight: 500}}
                      label={{ value: 'млн ₽', position: 'insideTop', offset: -30, fontSize: '10px',fontFamily: 'Travels', fontWeight: 500 }}
                      tickFormatter={value => {
                        if(value){
                          const val = mobile ? value / 1000000 : value
                          return frm.format(val);
                        }
                        return '';
                      }}
                  />
                  <XAxis
                      dataKey="name"
                      axisLine={false}
                      tickLine={false}
                      style={{fontSize: '11px',fontFamily: 'Travels', fontWeight: 500}}
                      tickFormatter={value => {
                        if(value){
                          return value;
                        }
                        return '';
                      }}
                  />
                  <Tooltip
                      itemStyle={{fontSize: '13px',fontFamily: 'Travels', fontWeight: 600, lineHeight: 1}}
                      contentStyle={{fontSize: '15px',fontFamily: 'Travels', fontWeight: 600}}
                      formatter={(value, name, props) => {
                        if (!data.rooms)  return [frm.format(value)]
                        const label = data.rooms.filter(item => item.value == name)[0].chartLabel;
                        return [frm.format(value), label]
                      }}
                  />
                  {createCharts()}
                </LineChart>
              </ResponsiveContainer>
            </div>
        )}
      </div>
  )
}

export default Charts;
