import { useSelector } from 'react-redux';

const Developer = ({data}) => {

  const mobile = useSelector(state => state.mobile);

  const createDsc = () => {
    if(data.about){
      return(
        <div>
          <div className="building-developer__dsc">
            <div className="title">О застройщике</div>
            <div dangerouslySetInnerHTML={{ __html: data.about }} />
          </div>
        </div>
      )
    }
  }

  return(
    <div>
      {(data.about || data.logo || data.img) && (
        <div className="building-developer__top">
          <div className="frame">
            <div className="building-developer__top-in">
              {mobile && createDsc()}
              <div>
                <div className="building-developer__top-imgs">
                  {data.logo && <img className="building-developer__top-logo" src={data.logo} />}
                  {data.img && <img className="building-developer__top-img" src={data.img} />}
                </div>
              </div>
              {!mobile && createDsc()}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Developer;