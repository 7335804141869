import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  SvgInfra1, //кофейня
  SvgInfra2, //торговые центры
  SvgInfra3, //парки
  SvgInfra4, //школы
  SvgInfra5, //детские сады
  SvgInfra6, //рестораны
  SvgInfra7, //Банки
  SvgInfra8, //Салоны красоты
  SvgInfra9, //Спорт
  SvgInfra10, //Почта
  SvgInfra11, //Аптеки
  SvgInfra12, //Культура
  SvgInfra13, //Храмы и церкви
  SvgInfra14, //Вузы
  SvgInfra100, //другое
} from '../../icons';
import {useRouter} from "next/router";

let myMap = null;

const Infra = ({markers, menu, dsc1, dsc2}) => {
  const router = useRouter()
  const estatet = markers.filter(item => !item.type)[0];

  const mobile = useSelector(state => state.mobile);

  const [active, setActive] = useState([]);
  const [geo, setGeo] = useState(null);

  const [first, setFirst] = useState(null);

  const list = {
    '1': <SvgInfra1 />,
    '2': <SvgInfra2 />,
    '3': <SvgInfra3 />,
    '4': <SvgInfra4 />,
    '5': <SvgInfra5 />,
    '6': <SvgInfra6 />,
    '7': <SvgInfra7 />,
    '8': <SvgInfra8 />,
    '9': <SvgInfra9 />,
    '10': <SvgInfra10 />,
    '11': <SvgInfra11 />,
    '12': <SvgInfra12 />,
    '13': <SvgInfra13 />,
    '14': <SvgInfra14 />,
  }

  useEffect(() => {
    ymaps.ready(function () {
      if(!myMap){
        myMap = new ymaps.Map('appartment__infra-map', {
          center: [55.751574, 37.573856],
          zoom: 12,
          controls: ['zoomControl'],
        })


        if(mobile){
          myMap.behaviors.disable('scrollZoom');
          myMap.behaviors.disable('drag');
        }
      }

      const clusterer = new ymaps.Clusterer({
        preset: 'islands#redClusterIcons',
        groupByCoordinates: false,
        clusterDisableClickZoom: false,
        clusterHideIconOnBalloonOpen: false,
        geoObjectHideIconOnBalloonOpen: false,
        //clusterBalloonContentLayout: false
      });

      const markersList = [];

      myMap.geoObjects.removeAll();

      let n_lats;
      markers.forEach(item => {

        let icon = ['/static/img/apartment-search-by-id/marker.svg', [40, 40], [-20, -20]];

        if(item.type){
          icon = [`/static/img/apartment-search-by-id/icon${item.type}.svg`, [30, 30], [-15, -15] ];
        }else{
          n_lats = item.latLng;
        }

        if(item.marker){
          icon = [item.marker, [30, 30], [-15, -15]];
        }

        const myPlacemark = new ymaps.Placemark(item.latLng, {
          hintContent: item.name,
          balloonContentHeader: `<div class="apartment-search-by-id-infra-baloon-title">${item.name}</div>`,
          balloonContentBody: `<div class="apartment-search-by-id-infra-baloon-title">${item.name}</div>`,
        }, {
            iconLayout: 'default#image',
            iconImageHref: icon[0],
            iconImageSize: icon[1],
            iconImageOffset: icon[2]
        });


        myPlacemark.events.add('click', function (e) {
          //console.log(e)
        })

        if(active.includes(item.type) || !active.length || !item.type){
          //myMap.geoObjects.add(myPlacemark);
          markersList.push(myPlacemark)
        }
      });

      clusterer.add(markersList);

      //console.log(active);
        /*
        myMap.setBounds(clusterer.getBounds(), {
          checkZoomRange: true,
          zoomMargin: [35, 400, 35, 35]
        });

         */
      //console.log(typeof mobile);
      myMap.setBounds(clusterer.getBounds(), {
        checkZoomRange: true,
        zoomMargin: [35, 400, 35, 35],
      }).then(function(){
        if(!first){
          myMap.setZoom(15);
          n_lats && myMap.setCenter(n_lats);

        }



      });

      if(mobile){
        myMap.setBounds(clusterer.getBounds(), {
          checkZoomRange: true,
          zoomMargin: [0, 0, 0, 0]
        });
      }
      myMap.geoObjects.add(clusterer);




      if(geo){
        if(estatet){
          const multiRoute = new ymaps.multiRouter.MultiRoute({
            referencePoints: [
              geo,
              estatet.latLng,
            ]
          }, {
            wayPointFinishVisible: false,
            //wayPointVisible: false,
            boundsAutoApply: true
          });

          myMap.geoObjects.add(multiRoute);
        }
      }
      setFirst(true);
    });

    return () => {
      if(myMap){
        myMap.destroy();
      }
      myMap = null;
    }
  },[active, geo, mobile]);


  const change = value => {
    const index = active.indexOf(value);
    const tmp = [...active];

    if(index === -1){
      tmp.push(value);
    }
    else{
      tmp.splice(index, 1);
    }

    setGeo(null);
    setActive(tmp);
  }

  const route = () => {
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((e) => {
        setGeo([e.coords.latitude, e.coords.longitude]);
      })
    }
  }

  return (
    <div className="appartment__infra">
      <div className="frame">
        <div className="title">Инфраструктура</div>
      </div>
      <div className="appartment__infra-map-wrap">
        <div id="appartment__infra-map"></div>
        <div className="appartment__infra-map-list">
          <div className="appartment__infra-map-items">
            {menu && menu.map(item => (
              <div
                key={`item${item.value}`}
                className={active.includes(item.value) ? 'appartment__infra-map-item active' : 'appartment__infra-map-item'}
                onClick={() => change(item.value)}
              >
                {!item.icon && <div>{list[item.value] || <SvgInfra100 />}</div>}
                {item.icon && <div dangerouslySetInnerHTML={{ __html: item.icon }} />}
                <div>{item.name}</div>
              </div>
            ))}
          </div>
          <div>
            {estatet && <div className="btn btn--border news-feed__load-more no-border-link" onClick={route}><span>Проложить маршрут</span></div>}
          </div>
        </div>
      </div>

      <div className="appartment__infra-map-dsc frame">
        <div dangerouslySetInnerHTML={{ __html: dsc1 }} />
        <div dangerouslySetInnerHTML={{ __html: dsc2 }} />
      </div>
    </div>
  )
}

export default Infra;
