import Breadcrumbs from '../_app/breadcrumbs'
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useScrollAnimation } from '../../hooks/use-scroll-animation'
import Slider from 'react-slick';
import Item from '../apartment-search/Item';
import { SvgDownload, SvgPedestrian, SvgAuto } from '../../icons';
import Gallery from './Gallery';
import Infra from './Infra';
import EventsNew from '../index/events/EventsNew';
//import MortgageProgramm from './MortgageProgramm';
import AboutApartment from './AboutApartment';
//import CalcApartment from './CalcApartment';
import CalcApartment from './CalcApartmentFlat';
import HeaderCalcValue from './HeaderCalcValue';
import Sharing from '../_app/Sharing';
//import Programms from '../mortgage/Programms';
import AddFavorite from '../_app/AddFavorite';
import AddRecent from '../_app/AddRecent';

import BoockingPopupAuth from '../building/BoockingPopupAuth';
import BoockingPopupNoAuth from '../building/BoockingPopupNoAuth';

import HeadMeta from '../_app/HeadMeta';
import PromoLabel from '../building/PromoLabel';

import dynamic from 'next/dynamic'
import AddCompare from "../_app/addCompare";
const PrintNoSSR = dynamic(
  () => import('../building/Print'),
  { ssr: false }
)


const ApartmentSearchById = ({data, api}) => {
  const router = useRouter();
  const mobile = useSelector(state => state.mobile);
  const auth = useSelector(state => state.auth);

  const [fixed, setFixed] = useState(false);
  const [upscroll, setUp] = useState(false);
  const [downscroll, setDown] = useState(false);
  const format = new Intl.NumberFormat('ru-RU');

  const [popupBookingAuth, setPopupBookingAuth] = useState(false);
  const [popupBookingNoAuth, setPopupBookingNoAuth] = useState(false);

  const [activeMenu, setActiveMenu] = useState(null);

  const calcAll = useSelector(state => state.calc.calcAll);

  const menu_ipo = useRef();
  const menu_ipo_fix = useRef();





  useEffect(() => {
    window.scrollTo(0,0);
    const detectElement = document.querySelector('.appartment-menu-fixed-detect');

    let lastScrollTop = 0;

    const scroll = () => {
      if(detectElement){
        const scr = window.scrollY;
        if(scr >= detectElement.offsetTop){
          setFixed(true);
        }
        else{
          setFixed(false);
        }
      }
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop){
        setUp(false)
        setDown(true)
      }
      else {
        setUp(true)
        setDown(false)
      }
      lastScrollTop = st;

      const boxes = document.querySelectorAll('div[data-id-boxes]');

      boxes.forEach(item => {
        const hfix = document.querySelector('.appartment-menu-fixed');
        const hfix2 = document.querySelector('.header-menu__links');
        const height = item.getBoundingClientRect().height;

        const h1 = hfix ? hfix.offsetHeight : 0
        const h2 = hfix2 ? hfix2.offsetHeight : 0

        const top = item.getBoundingClientRect().top + window.scrollY - h1 - h2;
        const end = top + height;

        if(window.scrollY > top - 80 && window.scrollY < end - 80){
          setActiveMenu(item.dataset.id)
        }
      })
    }
    scroll();
    window.addEventListener('scroll', scroll);

    return () => window.removeEventListener('scroll', scroll);
  },[mobile])

  if(!data) return null;

  useScrollAnimation();

  const settings = {
    dots: true,
    infinite: mobile,
    speed: 500,
    slidesToShow: mobile ? 1 : 4,
    slidesToScroll: mobile ? 1 : 4,
    arrows: false,
  };

  const setPopup = trigger => {
    setPopupBookingAuth(trigger);
    if(typeof gtag != 'undefined'){
      gtag('event', 'order_flate_click', {'event_category': 'order_flate_click'});
    }
    if(typeof ym != 'undefined'){
      ym(4831804, 'reachGoal', 'click_on_order_flate');
    }
    // if(trigger){
    //   if(auth){
    //     setPopupBookingAuth(trigger);
    //   }
    //   else{
    //     setPopupBookingNoAuth(trigger);
    //   }
    // }
    // else{
    //   setPopupBookingAuth(trigger);
    //   setPopupBookingNoAuth(trigger);
    // }
  }

  const scrollTo = type => {
    const el = document.querySelector(`[data-id="${type}"]`)
    if(el){
      const top = el.offsetTop - 80;

      window.scrollTo({
        top,
        behavior: "smooth"
      });
    }
  }

  const toObject = () => {
    router.push(`/${router.query.slug[0]}/${router.query.slug[1]}`)
  }

  const menuData = data.filter(item => item.type === 'menu')[0];
  if (menuData.data.find(item => item.value === 'events') && menuData.data.find(item => item.value === 'articles')) {
    menuData.data = menuData.data.filter(item => item.value !== 'articles');
  }

  const create = () => {
    const array = [];

    const selectData = {
      catalog_new: 'Жилая недвижимость',
      catalog_cre: 'Коммерческая недвижимость',
      catalog_country: 'Загородная недвижимость'
    }
    let flat_price = null

    data.forEach((item => {
      if(item.type === 'seo'){
        array.push(<HeadMeta key={`item${item.type}`} data={item} />);
      }

      if(item.type === 'appartment-info'){
        if(!mobile){
          let pathName
          if (router.query.slug[0] === 'catalog_new') {
            let rooms = item.infoHead.find(el => el.name === 'Комнаты').value;
            pathName = (rooms !== 'Студия' ? (rooms+'-комнатная квартира') : rooms)+(item.num ? (' № '+item.num) : '')
          } else {
            pathName = item.addr
          }
          array.push(
            <div className="page-title frame">
              <Breadcrumbs api={api} items={[{name:'Главная',link:'/'},{name:selectData[router.query.slug[0]],link:'/'+router.query.slug[0]},{name:item.name,link:'/' + router.query.slug[0] + '/' + router.query.slug[1]},{name:pathName}]} />
            </div>);
        }
        let h1_val;
        if(router.query.slug[0] === 'catalog_new'){
          let rooms = item.infoHead.filter(el => el.name === 'Комнаты')[0].value;
          let area = item.infoHead.filter(el => el.name === 'Площадь')[0].value.split(' ')[0];
          let floor = item.infoHead.filter(el => el.name === 'Этаж')[0].value;
          h1_val = 'Продажа '+(rooms !=='Студия' ? rooms+'-комнатной квартиры' : 'студии')+(item.num ? ' № '+item.num : '')+', '+area+' кв.м, этаж '+floor+' в '+item.name;
        }else{
          h1_val = item.addr;
        }
        if (item.price) flat_price = <div>
          <div className="appartment-by-id__header-price-wrap-first">
            <div className={item.discountPrice ? 'appartment-by-id__header-price underline' : 'appartment-by-id__header-price'}>
              {!!item.price  ? ((typeof item.price == 'number' && item.price != 0) ? `${format.format(item.price)} ₽` : item.price) : ''}
              {(!!item.rent && item.rent != 0) && `от ${format.format(item.rent)} ₽ / мес`}
            </div>
          </div>
          {(item.discountPrice && item.discountPrice != 0) ? (<div className="appartment-by-id__header-price-wrap">
            <div className="appartment-by-id__header-price percent">
              {format.format(item.discountPrice)} ₽<span className="appartment-by-id__header-price-percent">%<span>{item.discountPriceText}</span></span>
            </div>
          </div>) : ''}
          <HeaderCalcValue />
        </div>
        array.push(
          <div key={`item${item.type}`} data-id={item.type} className="appartment-by-id__header-wrap" data-id-boxes={item.type}>
            <script type='application/ld+json' dangerouslySetInnerHTML={{
              __html: `   
        {
"@context": "http://schema.org/",
"@type":"Product",
"name":"${h1_val.replace(/^"(.*)"$/, '$1').replace('«', '').replace('»', '')}",
"image":"${item.gallery && item.gallery[0]?.data ? api+item.gallery[0].data : ''}",
"offers": {
"@type": "Offer",
"price" : "${item.price ? item.price : ''}",
"priceCurrency" : "RUB",
"availability": "http://schema.org/InStock"
}, 
"aggregateRating": {
"@type": "aggregateRating",
"bestRating":"5",
"worstRating":"0",
"ratingValue": "${Math.round((Math.random() * (5 - 4) + 4) * 100) / 100}",
"reviewCount": "${Math.floor(Math.random() * (200 - 100) + 100)}"
}
}
        `
            }}></script>
            {!mobile && (
              <>
                <div className="appartment-by-id__header">
                  <div className="frame">
                    <div className="appartment-by-id__header-in">
                      <div>
                        {mobile && <div className="appartment-by-id__header-catalog">{selectData[router.query.slug[0]]}</div>}

                        {/*
                        <div className="appartment-by-id__header-title"><h1 dangerouslySetInnerHTML={{__html: item.addr}}/></div>
*/}
                        <div className="appartment-by-id__header-title"><h1 dangerouslySetInnerHTML={{__html: h1_val}}/></div>

                        <div className={"appartment-by-id__header-addr"}>
                          {item.addr_city ? item.addr_city : ''}{item.addr_district ? ', '+item.addr_district : ''}{item.addr_area ? ', '+item.addr_area : ''}{', '+item.addr}
                        </div>

                        <div className="appartment-by-id__header-metro-wrap">
                          {item.metro && item.metro.map((i, ind) => (
                            <div key={`item${ind}`} className="appartment-by-id__header-metro">
                              <div>
                                <span style={{backgroundColor: i.color}}>м</span>
                              </div>
                              <div>{i.name}</div>
                              {i.pedestrian &&
                                <div className={'app_metro_distance'}>
                                  <SvgPedestrian/>
                                  <div>{i.pedestrian} мин.</div>
                                </div>
                              }
                              {i.auto &&
                                <div className={'app_metro_distance'}>
                                  <SvgAuto/>
                                  <div>{i.auto} мин.</div>
                                </div>
                              }
                            </div>
                          ))}
                        </div>
                        {item.object_id != 1 && <div className="appartment-by-id__header-name" onClick={toObject}>{item.name}</div>}
                        {item.object_id == 1 && <div className="appartment-by-id__header-name disabled">{item.name}</div>}
                      </div>
                      <div>
                        <div className="appartment-by-id__header-in-last">
                          {item.priceUp && (
                            <div>
                              <div className="appartment-menu-fixed__priceup">
                                <div>{item.priceUp}</div>
                                осталось до повышения цены
                              </div>
                            </div>
                          )}
                          <div>
                          {flat_price}
                          </div>
                          <div>

                            <div className="appartment-by-id__header-button appartment-by-id__header-button--red" onClick={() => setPopup(true)}>
                              <div>Оставить заявку</div>
                              <div/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="appartment-by-id__header-bottom">
                      <div>
                        <div className="appartment-by-id__header-bottom-menu">
                          {menuData.data.map(item => (
                            <div ref={item.value==='apartment-calc' ? menu_ipo : null} className={item.value == activeMenu ? 'active' : ''} data-key={item.value} key={`item${item.value}`} onClick={() => scrollTo(item.value)}>{item.name}</div>
                          ))}
                        </div>
                      </div>
                      <div>
                        <div className="appartment-by-id__header-bottom-icons">
                          {/*<div>*/}
                          {/*  <AddCompare id={router.query.slug[2]} type={0} api={api} />*/}
                          {/*  <span>Сравнение</span>*/}
                          {/*</div>*/}
                          <div>
                            <AddFavorite data={{id: router.query.slug[2]}} type={0} api={api}/>
                            <span>Избранное</span>
                          </div>
                          <PrintNoSSR url={`${api}/pdf/?page=flat&catalog=${router.query.slug[0]}&id=${router.query.slug[2]}&ipo=${calcAll}`}/>
                          <a
                            target="_blank"
                            href={`${api}/pdf/?page=flat&catalog=${router.query.slug[0]}&id=${router.query.slug[2]}&ipo=${calcAll}`}
                            onClick={() => {
                              if (window.ym) ym(4831804, 'reachGoal', 'webit_click_dowload')
                            }}
                          >
                            <SvgDownload/>
                            <span>Скачать</span>
                          </a>
                          <Sharing/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="appartment-menu-fixed-detect"/>

                <div className={fixed ? (upscroll ? 'appartment-menu-fixed scrollup active' : 'appartment-menu-fixed scrolldown active') : 'appartment-menu-fixed'}>
                  <div className="frame">
                    <div className="appartment-menu-fixed__in">
                      <div>
                        <div className="appartment-menu-fixed__top">
                          <div>
                            <div className="appartment-menu-fixed__addr">{item.addr}</div>
                            {item.object_id != 1 && <div className="appartment-menu-fixed__name" onClick={toObject}>{item.name}</div>}
                            {item.object_id == 1 && <div className="appartment-menu-fixed__name disabled">{item.name}</div>}
                          </div>
                          <div>
                            <div className="appartment-menu-fixed__top-right">
                              <div>
                                {item.priceUp && (
                                  <div>
                                    <div className="appartment-menu-fixed__priceup">
                                      <div>{item.priceUp}</div>
                                      осталось до повышения цены
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div>
                                <div className={item.discountPrice ? 'appartment-by-id__header-price underline' : 'appartment-by-id__header-price'}>
                                  {(!!item.price && item.price != 0) && `${format.format(item.price)} ₽`}
                                  {(!!item.rent && item.rent != 0) && `от ${format.format(item.rent)} ₽ / мес`}
                                </div>
                              </div>
                              <div>
                                {item.discountPrice && <div className="appartment-by-id__header-price percent">{format.format(item.discountPrice)} ₽<span className="appartment-by-id__header-price-percent">%<span>{item.discountPriceText}</span></span></div>}
                                <HeaderCalcValue/>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="appartment-menu-fixed__menu">
                          {menuData.data.map(item => (
                            <div
                              ref={item.value==='apartment-calc' ? menu_ipo_fix : null}
                              key={`item${item.value}`}
                              data-key={item.value}
                              className={item.value == activeMenu ? 'appartment-menu-fixed__menu-item active' : 'appartment-menu-fixed__menu-item'}
                              onClick={() => scrollTo(item.value)}
                            >
                              {item.name}
                            </div>
                          ))}

                          <div>
                            <div className="appartment-by-id__header-bottom-icons">
                              <div>
                                <AddFavorite data={{id: router.query.slug[2]}} type={0} api={api}/>
                                <span>Избранное</span>
                              </div>
                              <PrintNoSSR url={`${api}/pdf/?page=flat&catalog=${router.query.slug[0]}&id=${router.query.slug[2]}`}/>
                              <a
                                target="_blank"
                                href={`${api}/pdf/?page=flat&catalog=${router.query.slug[0]}&id=${router.query.slug[2]}`}
                                onClick={() => {
                                  if (window.ym) ym(4831804, 'reachGoal', 'webit_click_dowload')
                                }}
                              >
                                <SvgDownload/>
                                <span>Скачать</span>
                              </a>
                              <Sharing/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {/*<div className="appartment-menu-fixed-button" onClick={() => setPopup(true)}><div>Оставить заявку</div><div /></div>
                        <div className="no-border-link" onClick={() => openModal('videoCall')}><span>Заказать виделзвонок</span></div>*/}
                        <div className="appartment-by-id__header-button appartment-by-id__header-button--red" onClick={() => setPopup(true)}>
                          <div>Оставить заявку</div>
                          <div/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <Gallery data={item} api={api} price={flat_price}>
              {mobile && (
                <div className="appartment-by-id-mobile-info">
                  <div className={item.discountPrice ? 'appartment-by-id__header-price underline' : 'appartment-by-id__header-price'}>
                    {(!!item.price && item.price != 0) && `${format.format(item.price)} ₽`}
                    {(!!item.rent && item.rent != 0) && `от ${format.format(item.rent)} ₽ / мес`}
                  </div>
                  <br/>
                  {item.discountPrice && <div className="appartment-by-id__header-price percent">{format.format(item.discountPrice)} ₽<span className="appartment-by-id__header-price-percent">%<span>{item.discountPriceText}</span></span></div>}
                  {item.priceUp && (
                    <div className="appartment-menu-fixed__priceup">
                      <div>{item.priceUp}</div>
                      осталось до повышения цены
                    </div>
                  )}

                  <div className="appartment-by-id__header-button appartment-by-id__header-button--red" onClick={() => setPopup(true)}>
                    <div>Оставить заявку</div>
                    <div/>
                  </div>
                  {mobile && <div className="appartment-by-id__header-catalog">{selectData[router.query.slug[0]]}</div>}
                  {/*
                  <div className="appartment-by-id__header-title"><h1 dangerouslySetInnerHTML={{__html: item.addr}}/></div>
*/}
                  <div className="appartment-by-id__header-title"><h1 dangerouslySetInnerHTML={{__html: h1_val}}/></div>

                  <div className={"appartment-by-id__header-addr"}>
                    {item.addr_city ? item.addr_city : ''}{item.addr_district ? ', ' + item.addr_district : ''}{item.addr_area ? ', ' + item.addr_area : ''}{', ' + item.addr}
                  </div>
                  <div className="appartment-by-id__header-metro-wrap">
                    {item.metro && item.metro.map((i, ind) => (
                      <div key={`item${ind}`} className="appartment-by-id__header-metro">
                        <div>
                          <span style={{backgroundColor: i.color}}>м</span>
                        </div>
                        <div>{i.name}</div>
                        {i.pedestrian &&
                          <div className={'app_metro_distance'}>
                            <SvgPedestrian/>
                            <div>{i.pedestrian} мин.</div>
                          </div>
                        }
                        {i.auto &&
                          <div className={'app_metro_distance'}>
                            <SvgAuto/>
                            <div>{i.auto} мин.</div>
                          </div>
                        }
                      </div>
                    ))}
                  </div>
                  {item.object_id != 1 && <div className="appartment-by-id__header-name" onClick={toObject}>{item.name}</div>}
                  {item.object_id == 1 && <div className="appartment-by-id__header-name disabled">{item.name}</div>}
                  <div className="appartment-by-id__header-bottom-icons">
                    <div>
                      <AddFavorite data={{id: router.query.slug[2]}} type={0} api={api}/>
                      <span>Избранное</span>
                    </div>
                    <PrintNoSSR url={`${api}/pdf/?page=flat&catalog=${router.query.slug[0]}&id=${router.query.slug[2]}`}/>
                    <a
                      target="_blank"
                      href={`${api}/pdf/?page=flat&catalog=${router.query.slug[0]}&id=${router.query.slug[2]}`}
                      onClick={() => {
                        if (window.ym) ym(4831804, 'reachGoal', 'webit_click_dowload')
                      }}
                    >
                      <SvgDownload/>
                      <span>Скачать</span>
                    </a>
                    <Sharing/>
                  </div>
                </div>
              )}
            </Gallery>

            <div className="frame">
              <div className="appartment-by-id__row appartment-by-id__row-info-table">
                <div>
                  <div className="appartment-by-id__table">
                    <div>
                      {item.optionsHead && item.optionsHead.map((item, index) => (
                        <div key={`item${index}`}>
                        <div className="appartment-by-id__table-box">
                            {item.name}
                            <div dangerouslySetInnerHTML={{__html: item.value}}/>
                          </div>
                        </div>
                      ))}
                    </div>
                    {item.options && item.options && (
                      <div className="appartment-by-id__table-last">
                        {item.options.map((item, index) => (
                          <div key={`item${index}`}>
                            <div className="appartment-by-id__table-box">
                              {item.name}
                              <div dangerouslySetInnerHTML={{__html: item.value}}/>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  {item.seler && (
                    <div className="appartment-by-id__seller">
                      <div className="appartment-by-id__seller-title">Продавец</div>
                      <div className="appartment-by-id__seller-info">
                        <div>
                          <div className="appartment-by-id__seller-info-img" style={{backgroundImage: `url('${item.seler.photo}')`}}/>
                        </div>
                        <div>
                          {item.seler.name}
                          <a href={`tel:${item.seler.phone}`} className="no-under">{item.seler.phone}</a>
                          <a href={`mailto:${item.seler.email}}`}>{item.seler.email}</a>
                        </div>
                      </div>
                    </div>
                  )}
                  {item.militaryMortgage && (
                    <div className="appartment-by-id__military">
                      Военная ипотека
                    </div>
                  )}
                  {item.promo && <PromoLabel data={item.promo}/>}
                </div>
              </div>

              {item.dsc && (
                <div className="appartment-by-id__desc">
                  {Array.isArray(item.dsc) && item.dsc.map((i, index) => <div key={`item${index}`} dangerouslySetInnerHTML={{__html: i}}/>)}
                  {!Array.isArray(item.dsc) && <div className="appartment-by-id__desc-single" dangerouslySetInnerHTML={{__html: item.dsc}}/>}
                </div>
              )}
            </div>
          </div>
        )
      }
      if (item.type === 'similar-appartments') {
        array.push(
          <div key={`item${item.type}`} data-id={item.type} data-id-boxes={item.type}>
            <div className="frame">
              <div className="apartment-search__view">
                <div className="apartment-search__view-title">
                  <div className="apartment-search__view-title-line"/>
                  <div>Похожие квартиры</div>
                </div>
              </div>

              <Slider {...settings} className="apartment-search__slider">
                {item.list.map(i => <div className="apartment-search__view-slide" key={`item${i.id}`}><Item data={i} slide api={api}/></div>)}
              </Slider>
            </div>
          </div>
        )
      }
      if(item.type === 'events'){
        array.push(<div data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><EventsNew api={api} filterDefault={item.filter || []} type={'more1'} articles={data.find(el => el.type === 'articles')} moreUrl={'/ajax/json.php?page=apartment-building-flat'} /></div>)
      }
      if(item.type === 'infra'){
        array.push(<div data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Infra markers={item.list} menu={item.menu} dsc1={item.dsc1} dsc2={item.dsc2} /></div>)
      }
      /*if(item.type === 'mortgage-programm'){
        array.push(<div data-id={item.type} key={`item${item.type}`}><MortgageProgramm data={item.table} /></div>)
      }
      if(item.type === 'banks'){
        array.push(<div data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><Programms key={item.type} list={item.list} title="Ипотечные программы" /></div>)
      }*/
      if(item.type === 'about-apartment'){
        array.push(<div data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><AboutApartment slider={item.slider} list={item.list} title={item.title} /></div>)
      }
      if(item.type === 'apartment-calc'){
        const priceData = data.filter(k => k.type === 'appartment-info')[0];
        const p = priceData ? (priceData.discountPrice || priceData.price || priceData.rent) : null;
        array.push(<div data-id={item.type} data-id-boxes={item.type} key={`item${item.type}`}><CalcApartment api={api} item={item} key={item.type} buildingId={item.id} defaultPriceMax={p} menu_ipo={menu_ipo} menu_ipo_fix={menu_ipo_fix}/></div>)
      }
    }));

    return array;
  }

  const info = data.filter(item => item.type === 'appartment-info')[0];
  const zhkGuid = info ? info.object_guid : '';
  const flatGuid = info ? info.guid : '';

  const objects = JSON.stringify([{"zhk":zhkGuid,"flats":[flatGuid]}]);

  return(
    <>
      <AddRecent data={{id: router.query.slug[2]}} type={0} api={api} />
      {mobile && (
        <div className="appartment-by-id__mobile-menu">
          <div>
            {menuData.data.map(item => (
              <div className={item.value == activeMenu ? 'active' : ''} key={`item${item.value}`} onClick={() => scrollTo(item.value)}>{item.name}</div>
            ))}
          </div>
        </div>
      )}
      {popupBookingAuth && <BoockingPopupAuth api={api} close={() => setPopup(false)} objects={objects} />}
      {popupBookingNoAuth && <BoockingPopupNoAuth api={api} close={() => setPopup(false)} objects={objects} />}
      <div className="inner-page appartment-by-id">
        {create()}
      </div>
    </>
  )
}

export default ApartmentSearchById;
