import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { SvgSelectArrow, SvgCheckbox, SvgClose } from '../../../icons';

const UiSelectFieldMultipleChip = ({options, values, onChange, title, chipRemove}) => (
  <div className="ui-select ui-select-chips">
    {title && <div className="filter-field__title">{title}</div>}
    <Select
      multiple
      value={values}
      renderValue={selected => {

        if(selected.length === 0){
          return <div className="ui-select-chips-placeholder">Все</div>
        }

        const tmp = options.filter(item => selected.includes(item.value))

        return tmp.map(item => (
          <div
            className="ui-chip"
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            key={`item${item.value}`}
            onClick={() => {
              chipRemove(item.value);
            }}
          >
            <div>{item.name}</div>
            <SvgClose />
          </div>
        ));
      }}
      onChange={onChange}
      IconComponent={SvgSelectArrow}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      displayEmpty={true}
    >
      {options.map((item, index) => (
        <MenuItem key={`item${index}`} value={item.value}>
          <div className="ui-checkbox">
            <div className={values.indexOf(item.value) !== -1 ? 'ui-checkbox__rect border active' : 'ui-checkbox__rect border'}>
              <SvgCheckbox />
            </div>
            <div className={values.indexOf(item.value) !== -1 ? 'ui-checkbox__label checked' : 'ui-checkbox__label'}>{item.name}</div>
          </div>
        </MenuItem>
      ))}
    </Select>
  </div>
)

export default UiSelectFieldMultipleChip;