import { SvgCalc } from '../../icons';
import { useSelector } from 'react-redux';

const HeaderCalcValue = () => {
  const calcAll = useSelector(state => state.calc.calcAll);

  if(!calcAll) return null;

  return(
    <div className="appartment-by-id__header-calc">
      <div>
        <SvgCalc />
      </div>
      <div>{calcAll} ₽/мес</div>
    </div>
  )
}

export default HeaderCalcValue;