import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { useRouter } from 'next/router'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import UiRangeField from '../_app/filter-fields/UiRangeField';
import UiSelectField from '../_app/filter-fields/UiSelectField';
import UiCheckBoxes from '../_app/filter-fields/UiCheckBoxes';
import UiSelectFieldMultiple from '../_app/filter-fields/UiSelectFieldMultiple';
import UiSelectFieldMultipleChip from '../_app/filter-fields/UiSelectFieldMultipleChip';
import UiCheckbox from '../_app/filter-fields/UiCheckbox';
import UiVoiceSearch from '../_app/filter-fields/UiVoiceSearch';
import { SvgSelectArrow } from '../../icons';
import { setPopupChangePlan, clearPopupChangePlan } from '../../redux/modules/site';

const Filter = ({setFlats, filter, filterTop, selectButton, api, tab, setTab, mobileSubscr, hash, flatsLoad}) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const mobile = useSelector(state => state.mobile);
    const [values, setValues] = useState(null);
    const [more, setMore] = useState(false)
    const popupChangePlan = useSelector(state => state.popupChangePlan);
    const [loaded, setLoaded] = useState(false)


    useEffect(() => {
        const carplaceIndex = filter.findIndex(el => el.name==='машиноместо')
        const flatsIndex = filter.findIndex(el => el.name==='квартиру')
        if (carplaceIndex > -1 && hash === 'carplace') {
            setTab(carplaceIndex);
        }
        if (flatsIndex > -1 && hash === 'filter') {
            setTab(flatsIndex);
        }
        //не понятно для чего эта проверка, но пока оставим
        if (!filter[tab].name && tab === 0) setTab(1);
    },[hash]);

    useEffect(() => {
        clear();
    },[]);

    const checkParams = (o, p) => {

        if(o[0] && o[1] && p[0] && p[1]){
            let first = o[0]
            let last = o[1]

            if(p[0] > o[0] && p[0] < o[1]){
                first = p[0]
            }

            if(p[1] > first && p[1] < o[1]){
                last = p[1]
            }

            return [first, last]
        }

        return o;
    }

    const clearValues = () => {
        if(filter){
            const tmp = {};

            //return  false;

            filter[tab].filters.forEach(item => {
                if(item.type === 'slider'){
                    if(router.query[item.name]){
                        tmp[item.name] = checkParams(item.data, router.query[item.name]);
                    }
                    else{
                        tmp[item.name] = item.data;
                    }
                }
                if(item.type === 'tabs' || item.type === 'multiple-select' || item.type === 'multiple-chips' || item.type === 'checkboxes'){
                    if(router.query[item.name]){
                        tmp[item.name] = router.query[item.name];
                    }
                    else{
                        tmp[item.name] = []
                    }
                }
                if(item.type === 'single-select'){
                    if(router.query[item.name]){
                        tmp[item.name] = router.query[item.name];
                    }
                    else{
                        tmp[item.name] = item.data[0].value;
                    }
                }
                if(item.type === 'image-select'){
                    if(router.query[item.name]){
                        tmp[item.name] = router.query[item.name];
                    }
                    else{
                        tmp[item.name] = [];
                    }
                }
                if(item.type === 'voice-search'){
                    if(router.query[item.name]){
                        tmp[item.name] = router.query[item.name];
                    }
                    else{
                        tmp[item.name] = '';
                    }
                }
            });

            filter[tab].extras.forEach(item => {
                if(item.type === 'slider'){
                    if(router.query[item.name]){
                        tmp[item.name] = checkParams(item.data, router.query[item.name]);
                    }
                    else{
                        tmp[item.name] = item.data;
                    }
                }
                if(item.type === 'tabs' || item.type === 'multiple-select' || item.type === 'multiple-chips' || item.type === 'checkboxes'){
                    if(router.query[item.name]){
                        tmp[item.name] = router.query[item.name];
                    }
                    else{
                        tmp[item.name] = []
                    }
                }
                if(item.type === 'single-select'){
                    if(router.query[item.name]){
                        tmp[item.name] = router.query[item.name];
                    }
                    else{
                        tmp[item.name] = item.data[0].value;
                    }
                }
                if(item.type === 'image-select'){
                    if(router.query[item.name]){
                        tmp[item.name] = router.query[item.name];
                    }
                    else{
                        tmp[item.name] = [];
                    }
                }
                if(item.type === 'voice-search'){
                    if(router.query[item.name]){
                        tmp[item.name] = router.query[item.name];
                    }
                    else{
                        tmp[item.name] = '';
                    }
                }
            });

            return tmp;
        }
    }

    const req = (tmp, rtype) => {

        //console.log(api + '/ajax/json.php?page=apartment-building-flats&code=' + router.query.slug[1]);

      if(flatsLoad){
        setFlats(null);

        const fd = new FormData();
        fd.append('catalog', router.query.slug[0]);
        fd.append('rtype', typeof rtype !== 'undefined' ? filter[rtype]?.value : filter[tab].value);
        //fd.append('rtype', 8);

        filter[tab].filters.forEach(item => {
          if(item.type === 'hidden'){
            fd.append(item.name, item.value);
          }
        })

        if (typeof filter[tab].value === 'object') {
          if (tmp['area']) tmp['area'].forEach(k => fd.append('area' + '[]', k));
        }

        else {
          const imgSelect = filter[tab].filters.filter(item => item.type === 'image-select')[0]

          if(filter[tab].name !== 'Не выбрано'){
            for(let i in tmp){
              if(imgSelect){
                if(i == imgSelect.name){
                  popupChangePlan.select.forEach(k => {
                    fd.append(i + '[]', k);
                  });
                }
                else{
                  if(Array.isArray(tmp[i])){
                    tmp[i].forEach(k => {
                      fd.append(i + '[]', k);
                    });
                  }
                  else{
                    fd.append(i, tmp[i]);
                  }
                }
              }
              else{
                if(Array.isArray(tmp[i])){
                  tmp[i].forEach(k => {
                    fd.append(i + '[]', k);
                  });
                }
                else{
                  fd.append(i, tmp[i]);
                }
              }
            }
          }
        }

        axios.post(api + '/ajax/json.php?page=apartment-building-flats&code=' + router.query.slug[1], fd)
          .then(function (response) {
            if(response.data){
              setFlats(response.data.list);

              if(document.location.hash){
                const hash = document.location.hash.substr(1);
                const elementTop = document.querySelector(`[data-id="${hash}"]`);
                if(elementTop){
                  window.scrollTo({
                    top: elementTop.offsetTop,
                    behavior: "smooth"
                  });
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }else{
        //setFlatsLoad(true)
      }


    }

    useEffect(() => {
        const tmp = clearValues();
        setValues(tmp)

        if (!loaded) {
            const carplaceIndex = filter.findIndex(el => el.name==='машиноместо')
            const flatsIndex = filter.findIndex(el => el.name==='квартиру')
            if (carplaceIndex > -1 && document.location.hash === '#carplace') {
                req(tmp, carplaceIndex);
            }
            if (flatsIndex > -1 && document.location.hash === '#filter') {
                req(tmp, flatsIndex);
            }
            setLoaded(true)
        }
        else {
            req(tmp);
        }


        return () => {
            dispatch(clearPopupChangePlan())
        }
    },[filter, tab]);

    const valueChange = (value, array) => {
        const tmp = [...array];
        const index = tmp.indexOf(value);

        if(index === -1){
            tmp.push(value);
        }
        else{
            tmp.splice(index, 1);
        }

        return tmp;
    }

    const apply = () => {
        req(values)
        setMore(false);

        const header = document.querySelector('.appartment-menu-fixed__in').offsetHeight;
        const top = document.querySelector('.building-result').getBoundingClientRect().top + window.scrollY - header;
        window.scrollTo({
            top,
            behavior: "smooth"
        });
    }

    const clear = () => {
        const tmp = clearValues();
        setValues(tmp);
        req(tmp);
        setMore(false);
    }

    const changeElementValue = (value, name, type) => {
        const tmp = {...values};

        let result = value;

        if(type === 'tabs' || type === 'checkboxes'){
            result = valueChange(value, tmp[name]);
        }

        tmp[name] = result;

        setValues(tmp);
    }

    const chipRemove = (value, name) => {
        const tmp = {...values};
        const result = valueChange(value, tmp[name]);
        tmp[name] = result;
        setValues(tmp);
    }

    const checkTargetElement = item => {
        if(item.targetElement){
            const tmp = {...values};
            const val = tmp[item.targetElement];
            if(item.targetValue.includes(val)){
                return true;
            }
            return false;
        }

        return true;
    }

    const createFilters = currentFilter => {
        if(filter && values){
            const array = [];
            currentFilter.forEach(item => {
                const draw = (typeof filter[tab].value === 'object' && (item.name === 'price' || item.name === 'area'))
                    || typeof filter[tab].value !== 'object'

                if (draw) {
                    const options = currentFilter.filter(k => k.name == item.name)[0].data;
                    const cl = item.single ? 'filter__fields-cell single' : 'filter__fields-cell';

                    if(item.type === 'voice-search' && checkTargetElement(item)){
                        if(values[item.name]){
                            array.push(
                                <div className={cl} key={`item${item.name}`}>
                                    <UiVoiceSearch
                                        value={values[item.name]}
                                        title={item.label}
                                        onChange={value => {
                                            changeElementValue(value, item.name, item.type)
                                        }}
                                    />
                                </div>
                            )
                        }
                    }

                    if(item.type === 'single-select' && checkTargetElement(item)){
                        if(values[item.name]){
                            array.push(
                                <div className={cl} key={`item${item.name}`}>
                                    <UiSelectField
                                        options={[...options]}
                                        value={values[item.name]}
                                        onChange={e => {
                                            changeElementValue(e.target.value, item.name, item.type)
                                        }}
                                        title={item.label}
                                    />
                                </div>
                            )
                        }
                    }
                    if(item.type === 'image-select' && checkTargetElement(item)){
                        if(values[item.name]){
                            array.push(
                                <div className={cl} key={`item${item.name}`} className="ui-image-select">
                                    <div className="filter-field__title"></div>
                                    <div className="filter-clear filter-clear_top" onClick={() => dispatch(setPopupChangePlan(item))}>Выбрать планировку</div>
                                </div>
                            )
                        }
                    }
                    if(item.type === 'slider' && checkTargetElement(item)){
                        if(values[item.name]){
                            array.push(
                                <div className={cl} key={`item${item.name}`}>
                                    <UiRangeField
                                        defaultValue={values[item.name]}
                                        min={options[0]}
                                        max={options[1]}
                                        onChange={vals => {
                                            changeElementValue(vals, item.name, item.type)
                                        }}
                                        title={item.label}
                                    />
                                </div>
                            )
                        }
                    }
                    if(item.type === 'tabs' && checkTargetElement(item)){
                        if(values[item.name]){
                            array.push(
                                <div className={cl} key={`item${item.name}`}>
                                    <UiCheckBoxes
                                        options={options}
                                        values={values[item.name]}
                                        onChange={val => {
                                            changeElementValue(val, item.name, item.type)
                                        }}
                                        title={item.label}
                                    />
                                </div>
                            )
                        }
                    }
                    if(item.type === 'multiple-select' && checkTargetElement(item)){
                        if(values[item.name]){
                            array.push(
                                <div className={cl} key={`item${item.name}`}>
                                    <UiSelectFieldMultiple
                                        options={options}
                                        values={values[item.name]}
                                        onChange={e => {
                                            changeElementValue(e.target.value, item.name, item.type);
                                        }}
                                        title={item.label}
                                    />
                                </div>
                            )
                        }
                    }
                    if(item.type === 'multiple-chips' && checkTargetElement(item)){
                        if(values[item.name]){
                            array.push(
                                <div className={cl} key={`item${item.name}`}>
                                    <UiSelectFieldMultipleChip
                                        options={options}
                                        values={values[item.name]}
                                        onChange={e => {
                                            changeElementValue(e.target.value, item.name, item.type);
                                        }}
                                        title={item.label}
                                        chipRemove={val => {
                                            chipRemove(val, item.name);
                                        }}
                                    />
                                </div>
                            )
                        }
                    }
                    if(item.type === 'checkboxes' && checkTargetElement(item)){
                        if(values[item.name]){
                            array.push(
                                <div className={cl} key={`item${item.name}`}>
                                    <div>
                                        <div className="filter-field__title">{item.label}</div>
                                        <div className="filter__checkbox-row">
                                            {options.map(k => (
                                                <UiCheckbox
                                                    key={`item${k.value}`}
                                                    label={k.name}
                                                    checked={values[item.name].includes(k.value)}
                                                    onChange={val => {
                                                        changeElementValue(val, item.name, item.type);
                                                    }}
                                                    value={k.value}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }
                }
            })

            return array;
        }
    }

    const createSelectTabName = string => {
        if (string) return string.charAt(0).toUpperCase() + string.slice(1);
        return ''
    }

    const createSelectTab = () => {
        if(filter.length > 1){
            const options = filter.map(item => ({name: createSelectTabName(item.name_list[3]), value: item.index}))
            return(
                <div className="filter__fields-cell" key={`item-zero`}>
                    <UiSelectField
                        options={[...options]}
                        value={tab}
                        onChange={e => {
                            setTab(e.target.value)
                        }}
                        title="Тип помещения"
                    />
                </div>
            )
        }
    }

    if (!filter || !values) return null;

    return (
        <div className="filter">
            <div className="frame">

                <div className="building-filter-title">{filter[tab] ? 'Подобрать ' + (filter[tab].name!=='Все' ? (filter[tab].name ? filter[tab].name : '') : '') : ''}</div>

                {filterTop && filterTop}

                <div className="filter__center">
                    <div className="filter__fields">
                        {createSelectTab()}


                        {/*{filter[tab].name !== 'Все' && createFilters(filter[tab].filters)}*/}
                        {createFilters(filter[tab].filters)}

                        {selectButton && (
                            <div className="building-filter-select-button">
                                <div className="filter-field__title">&nbsp;</div>
                                {selectButton}
                            </div>
                        )}
                        {!mobile && (
                            <div>
                                <div className="filter-field__title">&nbsp;</div>
                                <div className="btn btn--border no-border-link apartment-search__btn apartment-search__btn-filter" onClick={apply}>
                                    <span>Применить</span>
                                </div>
                            </div>
                        )}

                    </div>
                    <div>
                        {filter[tab].extras.length !== 0 && (
                            <div className={more ? 'filter__more active' : 'filter__more'} onClick={() => setMore(!more)}>
                                <div>Ещё фильтры</div>
                                <span><SvgSelectArrow /></span>
                            </div>
                        )}
                    </div>

                </div>
            </div>

            {(more && filter[tab].extras.length !== 0) && (
                <div className="filter-hidden"
                     onMouseLeave={() => {
                         if(!mobile){
                             setMore(false)
                         }
                     }}
                >
                    <div className="filter-hidden-extras">
                        {createFilters(filter[tab].extras)}
                    </div>

                    {!mobile && (
                        <div className="filter__result-row">
                            <div className="btn btn--border no-border-link apartment-search__btn" onClick={apply}>
                                <span>Применить</span>
                            </div>
                            <div className="filter-clear" onClick={clear}>Сбросить фильтры</div>
                        </div>
                    )}
                </div>
            )}

            {mobile && (
                <>
                    <div className="filter__result-row">
                        <div className="btn btn--border no-border-link apartment-search__btn" onClick={apply}>
                            <span>Применить</span>
                        </div>
                        <div className="filter-clear" onClick={clear}>Сбросить фильтры</div>
                        {mobileSubscr}
                    </div>
                </>
            )}
        </div>
    )
}

export default Filter;
