import { useRouter } from 'next/router';
import OnlineRegistrationForm from '../form/online-registration-form';

const BoockingPopupNoAuth = ({api, close, slug, objects}) => {
  const router = useRouter();
  return (
    <div className="popup">
      <div className="popup__bg" onClick={close} />
      <div className="popup__in popup__in-b">
        <div className="popup__close" onClick={close} />
        <div className="popup-auth"><OnlineRegistrationForm api={api} objects={objects} slug={router.query.slug || slug} close={close} /></div>
      </div>
    </div>
  )
}

export default BoockingPopupNoAuth;