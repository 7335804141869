import Tab from './Tab';

const Row = ({data, api, filter, tab, name, parking, firstRowName}) => {
  const index = filter.findIndex(el => el.name===name)
  const filterItem = filter.find(el => el.name===name)
  const allIndex = filter.findIndex(el => el.name==="Все")

  let list = data

  if (!filterItem) return null

  if (filterItem.value && allIndex === +tab) {
    list = data.filter(el => +el.realtype === filterItem.value)
  }

  const showList = index > -1 && (allIndex === +tab || index === +tab)

  const tmp = [...new Set(list.map(item => item.rooms))];
  const rooms = Array.from(tmp).sort((a, b) => a - b);

  return showList && rooms.length >= 1 && rooms.map((item, i) => <Tab index={allIndex !== +tab || (allIndex === +tab && firstRowName===name) ? i : 2} name_list={filterItem.name_list} key={`item${item}`} room={item} data={list.filter(k => k.rooms === item)} api={api} parking={parking} />)
}

export default Row;
