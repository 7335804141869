import { useState, useEffect, useRef } from 'react';
import { Svg3DTour } from '../../icons';
const Video = ({data, api}) => {

  const [start, setStart] = useState(false);
  const video = useRef(null);

  useEffect(() => {
    if(video.current){
      video.current.onended = function(){
        setStart(false);
      }
    }
  },[start]);

  if(data.aero && (data.video || data.youtube)){
    return(
      <div className="frame building-video-wrap">
        <div className="building-video">
          <div>
            <div className="title">Видео</div>
            <div className="building-video__box-wrap">
              {data.video && (
                <div className="building-video__box" style={{backgroundImage: `url('${api}${data.poster}')`}}>
                  {!start && <div className="building-video__button" onClick={() => setStart(true)} />}
                  {start && (
                    <video ref={video} autoPlay="true" playsInline controls>
                      <source src={data.video} type="video/mp4" />
                    </video>
                  )}
                </div>
              )}
              {data.youtube && (
                <iframe src={data.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              )}
            </div>
          </div>
          <div>
            <div className="title">Аэрофотосъемка</div>
            <a target="_blank" href={data.aeroLnk} className="building-video__aero" style={{backgroundImage: `url('${api}${data.aero}')`}}>
              <div className="building-video__aero-button"><Svg3DTour /></div>
            </a>
          </div>
        </div>
      </div>
    )
  }

  if(data.video || data.youtube){
    return(
      <div className="frame building-video-wrap">
        <div className="title">Видео</div>
        <div className="building-video">
          <div>
            <div className="building-video__box-wrap">
              {data.video && (
                <div className="building-video__box" style={{backgroundImage: `url('${api}${data.poster}')`}}>
                  {!start && <div className="building-video__button" onClick={() => setStart(true)} />}
                  {start && (
                    <video ref={video} autoPlay="true" playsInline controls>
                      <source src={data.video} type="video/mp4" />
                    </video>
                  )}
                </div>
              )}
              {data.youtube && (
                <iframe src={data.youtube} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              )}
            </div>
          </div>
          <div className="building-video__last" dangerouslySetInnerHTML={{ __html: data.dsc }} />
        </div>
      </div>
    )
  }

  if(data.aero){
    return(
      <div className="frame building-video-wrap">
        <div className="title">Аэрофотосъемка</div>
        <div className="building-video">
          <div>
            <a target="_blank" href={data.aeroLnk} className="building-video__aero" style={{backgroundImage: `url('${api}${data.aero}')`}}>
              <div className="building-video__aero-button"><Svg3DTour /></div>
            </a>
          </div>
          <div className="building-video__last" dangerouslySetInnerHTML={{ __html: data.dsc }} />
        </div>
      </div>
    )
  }

  return null;
}

export default Video;