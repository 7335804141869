import { declOfNum } from '../../../utils';
import Row from './result-row'

const Result = ({data, api, filter, tab}) => {

  if(!data || filter.length === 0) return null;
  const firstRowName = filter.length > 1 && filter[0].name === 'Все' ? filter[1].name : filter[0].name
  return(
    <div className="frame building-result fade-in">
      <div className="building-result-value">{data.length} {declOfNum(data.length, ['результат','результата','результатов'])}</div>
      {filter.map(el => {
        if (el.name === 'Все') return null
        return <Row data={data} api={api} filter={filter} tab={tab} name={el.name} firstRowName={firstRowName} parking={el.name==="машиноместо"} />
      })}
   </div>
  )
}

export default Result;
