const UiCheckBoxes = ({options, values, onChange, title}) => {
  if(!values) return null;
  return (
    <>
      {title && <div className="filter-field__title">{title}</div>}
      <div className="ui-checkboxes">
        {options.map(item => (
          <div
            key={`item${item.value}`}
            onClick={() => onChange(item.value)}
            className={values.includes(item.value) ? 'active' : ''}
          >
            {item.name}
          </div>
        ))}
      </div>
    </>
  )
}

export default UiCheckBoxes;