import { useEffect } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

const UiVoiceSearch = ({title, value, onChange}) => {
  const { transcript } = useSpeechRecognition()

  useEffect(() => {
    SpeechRecognition.startListening({ language: 'ru-RU' })
    onChange(value || transcript)
    return () => {
      SpeechRecognition.stopListening
    }
  }, [transcript]);

  const createSpeech = () => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      return null;
    }
  
    return <span className="voice-field__mike ico-8" onClick={SpeechRecognition.startListening} />
  }

  return(
    <div className="filter-field">
      <div className="filter-field__title">{title}</div>
      <div className="filter-field__body">
        <input placeholder="Расположение или название" value={value} onChange={e => onChange(e.target.value)} />
        {createSpeech()}
      </div>
    </div>
  )
}

export default UiVoiceSearch;