import getConfig from 'next/config';
import axios from 'axios';
import Wrapper from '../components/apartment-search/Wrapper';
import { fetchCalc } from "../redux/modules/calc";
import { fetchFilter, fetchDataOnFirstLoad } from '../redux/modules/apartment-search'
import {fetchMenuBottom, fetchRedirects} from "../redux/modules/site";
import {redir_n} from "../utils";

const WrapperPage = (props) => <Wrapper {...props} />

WrapperPage.getInitialProps = async({ reduxStore, req, res, query }) => {
  const { publicRuntimeConfig } = getConfig();
  const { api } = publicRuntimeConfig
  let store = reduxStore.getState()
  if (!store.redirects?.redirectsLoaded) {
    await reduxStore.dispatch(fetchRedirects(api))
    store = reduxStore.getState()
    redir_n(res, req, store.redirects?.redirects)
  }else{
    redir_n(res, req, store.redirects?.redirects)
  }
  if (!store.menu?.menuBottomLoaded) {
    await reduxStore.dispatch(fetchMenuBottom(api))
  }
  const _api = api && typeof api !== 'undefined' ? api : ''

    let data = null;
  let type = null;
  let build_flats = null;

  let build_events = null;
  if(query.slug.length === 1){
    if(query.slug[0] === 'catalog_new' || query.slug[0] === 'catalog_cre' || query.slug[0] === 'catalog_country'){
      type = 'filter';
      await reduxStore.dispatch(fetchDataOnFirstLoad(_api, query.slug[0], (query?.page ? query?.page : '')))
    }else{
      res.statusCode = 404;
    }
  }
  if(query.slug.length === 2){
    type = 'object';
  }
  if(query.slug.length === 3){
    type = 'flat';
  }

  if(query.slug.length >= 4){
    if (query.slug[0] === 'catalog_new' || query.slug[0] === 'catalog_cre' || query.slug[0] === 'catalog_country') {
      res.redirect('/'+query.slug[0], '301');
    }
    if(query.slug[0] !== 'ajax' && query.slug[0] !== 'images' && query.slug[0] !== 'catalog_new' && query.slug[0] !== 'catalog_cre' && query.slug[0] !== 'catalog_country'){
      res.statusCode = 404;
    }
    return {}
  }

  try {
    if(query.slug.length === 2 && query.slug[1] !=='json.php'){
      const response = await axios.get(_api + `/ajax/json.php?page=apartment-building&catalog=${query.slug[0]}&code=${query.slug[1]}`);
      //const build_flats_response = await axios.get(_api + `/ajax/json.php?page=apartment-building-flats&catalog=${query.slug[0]}&code=${query.slug[1]}&target=1&area[]=1&area[]=1000&rtype[]=8&rtype[]=2&rtype[]=4`);
      const build_flats_response = await axios.get(_api + `/ajax/json.php?page=apartment-building-flats&catalog=${query.slug[0]}&code=${query.slug[1]}`+(query.slug[0]==='catalog_new' ? '&target=1&area[]=1&area[]=1000&rtype[]=8&rtype[]=2&rtype[]=4':''));

      if(query.slug[0] === 'catalog_new' || query.slug[0] === 'catalog_cre' || query.slug[0] === 'catalog_country'){
        if (res && response.data?.length === 0) {
          res.redirect('/'+query.slug[0], '301');
        }
      }

      if(query.slug[0] !== 'ajax' && query.slug[0] !== 'images' && query.slug[0] !== 'catalog_new' && query.slug[0] !== 'catalog_cre' && query.slug[0] !== 'catalog_country'){
        if(Array.isArray(response.data)){
          if(!response.data.length){
            res.statusCode = 404;
          }
        }
      }
      data = response.data;
      build_flats = build_flats_response?.data?.list;

      build_events = await axios.get(_api + `/ajax/json.php?page=events&PAGEN_1=1&object=${query.slug[1]}`);
    }
    if(query.slug.length === 3){
      const response = await axios.get(_api + `/ajax/json.php?page=apartment-building-flat&catalog=${query.slug[0]}&code=${query.slug[1]}&id=${query.slug[2]}`);

      if(query.slug[0] === 'catalog_new' || query.slug[0] === 'catalog_cre' || query.slug[0] === 'catalog_country'){
        if(Array.isArray(response.data)){
          if(!response.data.length){
            res.redirect('/'+query.slug[0]+'/'+query.slug[1], '301');
          }
        }
      }

      if(query.slug[0] !== 'ajax' && query.slug[0] !== 'images' && query.slug[0] !== 'catalog_new' && query.slug[0] !== 'catalog_cre' && query.slug[0] !== 'catalog_country'){
        if(Array.isArray(response.data)){
          if(!response.data.length){
            res.statusCode = 404;
          }
        }
      }

      data = response.data;
    }
  } catch (err) {
    console.error(err);
  }


  if(!store.apartmentSearch.filter){
    await reduxStore.dispatch(fetchFilter(_api))
  }
  if(!store.calc.loaded){
    await reduxStore.dispatch(fetchCalc(_api))
  }

  return {...data, api: _api, type, query, build_flats: build_flats, build_events: build_events?.data}
}


export default WrapperPage;
