import Countdown from 'react-countdown';

const CountDownTimer = ({priceUpDate, priceUpText}) => {

  if(!priceUpDate) return null;

  const pud = priceUpDate.split('-').join('/')
  const d = new Date(pud).getTime();
  const now = Date.now();
  const range = d - now;
  if(range <= 0) return null;

  return(
    <div>
      <div className="appartment-menu-fixed__priceup">
        <div>
          <Countdown date={d} renderer={e => {
            return `${e.days} дн : ${e.hours} ч : ${e.minutes} мин`;
          }} />
        </div>
        {priceUpText ? priceUpText : 'осталось до повышения цены'}
      </div>
    </div>
  )
}

export default CountDownTimer;