import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { SvgSliderArrowLeft, SvgSliderArrowRight, SvgNoPhoto } from '../../icons';
import { setPopupPhoto } from '../../redux/modules/site';
import Genplan from "./genplan";
import {useRouter} from "next/router";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowRight />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
      <SvgSliderArrowLeft />
    </div>
  );
}

const Gallery = ({data, children, api, price}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const mobile = useSelector(state => state.mobile)

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const [active, setActive] = useState(0);

  const settings1 = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settings2 = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    focusOnSelect: true
  };

  const current = data.gallery ? data.gallery[active] : null;

  const setPopup = index => {
    const tmp = [];

    current.data.forEach(item => {
      tmp.push({img: item});
    })

    dispatch(setPopupPhoto(
      {
        current: index,
        list: tmp
      }
    ))
  }

  return (
    <div className="frame appartment-by-id__photo-wrapper">
      <div className="appartment-by-id__row">
        <div>
          <div className="appartment-by-id__photo-menu-wrapper">
            {data.real == 0 && <div className="appartment-by-id__photo-menu-title">{(data.hidePlan || data.gallery?.length > 1) ? '' : 'Планировка'}</div>}
            <div className="appartment-by-id__photo-menu">
              {data.gallery && data.gallery.map((item, index) => <div key={`item${index}`} className={index === active ? 'active' : ''} onClick={() => setActive(index)}>{item.name}</div>)}
            </div>
          </div>
        </div>
        <div></div>
      </div>

      <div className="appartment-by-id__row appartment-by-id__row-info">
        <div>
          {current && current.sheme && current.data && <Genplan data={current} api={api} />}
          {((current && !current.sheme) || !current) && <Slider {...settings1} asNavFor={nav2} ref={slider1 => setNav1(slider1)}>
            {current && !current.sheme && current.data && current.data.map((item, index) => (
              <div key={`item${index}`}>
                <div className="appartment-by-id__row-info-slide" onClick={() => setPopup(index)}>
                  <img src={`${api}${item}`} />
                </div>
              </div>
            ))}
            {!current && (
              <div key={`item-0`}>
                <div className="appartment-by-id__row-info-slide">
                  <SvgNoPhoto />
                </div>
              </div>
            )}
          </Slider>}
        </div>
        {children}
        <div className="appartment-by-id__column-mobile">
          {!mobile && price ? <div className="appartment-by-id__column-info-price">{price}</div> : null}
          <div>
            {data.infoHead && data.infoHead.map((item, index) => (
              <div key={`item${index}`} className="appartment-by-id__column-info-box">
                {item.name}
                <div dangerouslySetInnerHTML={{ __html: item.value}} />
              </div>
            ))}
          </div>
          <div className="appartment-by-id__column-info-line" />
          <div>
            {data.info && data.info.map((item, index) => (
              (item.name === 'Номер квартиры' && router?.asPath.indexOf('/sec/')!='undefined')
                ? <></> :
              <div key={`item${index}`} className="appartment-by-id__column-info-box">
                {item.name}
                <div dangerouslySetInnerHTML={{ __html: item.value}} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {current && current.data.length > 1 && !mobile && (
        <div className="appartment-by-id__row appartment-by-id__row-slider-preview">
          <div>
            <Slider {...settings2} asNavFor={nav1} ref={slider2 => setNav2(slider2)}>
              {current.data.map((item, index) => <div key={`item${index}`}><div className="appartment-by-id__row-info-slide-prev"><div style={{ backgroundImage: `url('${api}${item}')`}} /></div></div>)}
            </Slider>
          </div>
          <div></div>
        </div>
      )}
    </div>
  )
}

export default Gallery;
