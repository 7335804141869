import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { declOfNum } from '../../utils';
import { SvgGenplanLeft, SvgGenplanRight, SvgNoPhoto } from '../../icons';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const Genplan = ({data, flats, api, stickyTop}) => {

  const router = useRouter();
  const format = new Intl.NumberFormat('ru-RU');
  const [size, setSize] = useState(data.imgSize
                                                ? (data.imgSize.width && data.imgSize.height ? [data.imgSize.width, data.imgSize.height] : data.imgSize)
                                                : [0, 0]);
  const [active, setActive] = useState(0);

  useEffect(() => {
    if(data){
      if(data.img && !data.imgSize){
        const img = new Image();
        img.onload = () => {
          //setSize([img.width, img.height]);
        }
        img.src = api + data.img;
      }
    }
  },[data]);

  if(!data || !flats || !data.data || !size) return null;

  const d = data.data.filter(item => {
    if(flats){
      const fl = flats.filter(i => i.corpus == item.corpus && i.section == item.section);
      if(fl.length){
        return item;
      }
    }
  })
  if(!d.length) return null;

  const max = d.length - 1;

  const corpus = d[active].corpus;
  const corpus_id = d[active].corpus_id;
  const section = d[active].section;

  const prev = () => {
    let pos = active;
    pos--;
    if(pos < 0){
      pos = max;
    }
    setActive(pos);
  }

  const next = () => {
    let pos = active;
    pos++;
    if(pos > max){
      pos = 0;
    }
    setActive(pos);
  }

  const to = id => {
    const storage = window.localStorage.getItem('building');

    if(!storage){
      window.localStorage.setItem('building', [id].join(','));
    }
    else{
      const tmp = storage.split(',')
      if(tmp.indexOf(id.toString()) === -1){
        tmp.push(id);
        window.localStorage.setItem('building', tmp.join(','));
      }
    }

    //router.push(`/${router.query.slug[0]}/${router.query.slug[1]}/${id}`)
    window.open(`/${router.query.slug[0]}/${router.query.slug[1]}/${id}`, '_blank');
  }

  const getStorage = id => {
    if(window){
      const storage = window.localStorage.getItem('building');
      if(storage){
        const tmp = storage.split(',');

        if(tmp.indexOf(id.toString()) === -1){
          return false;
        }
        return true;
      }
      else{
        return false;
      }
    }

    return false;
  }

  const changeCorpus = id => {
    for(let i = 0; i < d.length; i++){
      if(id == d[i].corpus_id){
        setActive(i);
        break;
      }
    }
  }

  const createClass = () => {
    const size = d.filter(item => item.corpus == corpus && item.section == section)[0].size;
    if(size[0] > 16){
      return 'building-genplan__in building-genplan__in-overflow';
    }

    return 'building-genplan__in';
  }

  const createGrid = () => {
    const size = d.filter(item => item.corpus == corpus && item.section == section)[0].size;

    const greed = [];

    for(let i = size[1]; i >= 1 ; i--){
      const center = parseInt(size[0]/2);
      const row = [<div className="building-genplan__cell-floor" key={`row-head`}>{i}</div>];
      for(let j = 1; j <= size[0]; j++){

        const flat = flats.filter(item => item.corpus_id == corpus_id && item.section == section && item.floor == i && item.numberOnFloor == j)[0]
        if(flat){
          row.push(
            <div
              className={getStorage(flat.id) ? 'building-genplan__cell building-genplan__cell-viewed' : 'building-genplan__cell building-genplan__cell-active'}
              onClick={() => to(flat.id)}
              key={`item${i}-${j}`}
            >
              {flat.rooms == 0 ? 'С' : flat.rooms}
              <div className={j < center ? 'building-genplan__cell-popup left' : 'building-genplan__cell-popup'}>
                <div>
                  <div className="building-genplan__cell-popup-row">
                    Номер квартиры:
                    <div>{flat.number}</div>
                  </div>
                  <div className="building-genplan__cell-popup-row">
                    Стоимость:
                    <div>{format.format(flat.price)} ₽</div>
                  </div>
                  <div className="building-genplan__cell-popup-row">
                    Площадь:
                    <div>{flat.area} м&sup2;</div>
                  </div>
                </div>
                <div>
                  {flat.plan && <img src={flat.plan} />}
                  {!flat.plan && <SvgNoPhoto />}
                </div>
              </div>
            </div>
          )
        }
        else{
          row.push(<div className="building-genplan__cell" key={`item${i}-${j}`}></div>)
        }

      }
      greed.push(<div className="building-genplan__row" key={`row${i}`}>{row}</div>);
    }

    return <div className="building-genplan__greed">{greed}</div>
  }

  return(
    <div className="building-genplan frame fade-in">
      <div className="building-result-value">{flats.length} {declOfNum(flats.length, ['результат','результата','результатов'])}</div>

      <div className={createClass()}>
        <div>
          <div className="building-genplan__arrows">
            <div onClick={prev}><SvgGenplanLeft /></div>
            <div>Корпус {corpus}, секция {section}</div>
            <div onClick={next}><SvgGenplanRight /></div>
          </div>
          {createGrid()}
        </div>
        <div>
          <TransformWrapper
            onInit={() => {
              const timer = setInterval(() => {
                const text = document.querySelectorAll('.building-genplan__image text');
                if(text.length != 0){
                  clearInterval(timer);
                  for(let i = 0; i < text.length; i++){
                    const width = text[i].getBoundingClientRect().width;
                    const w = text[i].getAttribute('w')
                    const l = (w - width)/2.1;
                    text[i].style.transform = `translateX(${l}px)`
                  }
                }
              }, 100);
            }}
          >
            <TransformComponent>
              {data.img && data.imgSize && <div className="building-genplan__image" style={{ top: stickyTop + 'px' }}>
                <img src={api + data.img} width={size[0]} height={size[1]} />
                {data.markers &&
                  <svg width={size[0]} height={size[1]} viewBox={`0 0 ${data.imgSize[0] ? data.imgSize[0] : size[0]} ${data.imgSize[1] ? data.imgSize[1] : size[1]}`} xmlns="http://www.w3.org/2000/svg">
{/*
                    <svg width={size[0]} height={size[1]} viewBox={`0 0 ${data.imgSize[0]} ${data.imgSize[1]}`} xmlns="http://www.w3.org/2000/svg">
*/}
                    {data.markers.map((item, index) => {
                      return (
                        <g key={`item${index}`} onClick={() => changeCorpus(item.corpus_id)}>
                          <rect fill={item.corpus_id == corpus_id ? '#ed1c24' : '#000'} x={item.position[0]} y={item.position[1]} width={item.corpus.length <= 3 ? 30 : item.corpus.length * 12} height="30"/>
                          <text x={item.position[0]} w={item.corpus.length <= 3 ? 30 : item.corpus.length * 12} y={item.position[1] + 20}>{item.corpus}</text>
                        </g>
                      )
                    })}
                  </svg>}
              </div>}
            </TransformComponent>
          </TransformWrapper>


        </div>
      </div>
    </div>
  )
}

export default Genplan;
