import { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { useForm } from "../../hooks/use-form";
import InputField from "../_app/input-field";
import CheckboxField from "../_app/checkbox-field";
import { sendVideoCall, sendBookingRequest } from "../../redux/modules/form";
import FormFieldSelectValid from "../_app/FormFieldSelectValid";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@material-ui/core/TextField";
import ru from "date-fns/locale/ru";

import { setPopupMessage } from "../../redux/modules/site";

const VideoCallForm = ({ current_name, current_id, api }) => {
  const [current_id_now, setCurrent_id_now] = useState(2);
  const dispatch = useDispatch();
  const router = useRouter();
  const auth = useSelector((state) => state.auth);
  const data = useSelector((state) => state.calc.response);
  const getValues = () => {
    //if(slug){
    if (typeof auth == "object") {
      const data = {};

      if (auth.fio) {
        data.fio = auth.fio;
      }
      if (auth.phone) {
        data.phone = auth.phone;
      }
      if (auth.email) {
        data.email = auth.email;
      }

      return data;
    }
    //}

    return {
      fio: "",
      phone: "",
      email: "",
    };
  };

  if (current_id == true) {
    setCurrent_id_now(current_id);
  }

  const date = new Date();
  const hours = date.getHours();
  const minute = date.getMinutes();
  const [selectedDate, handleDateChange] = useState(new Date());

  const hoursMinute = Number(String(hours) + "." + String(minute));

  let miniteOptions = [
    {
      name: "10:30",
      value: "10.3010:30",
      form: true,
    },
    {
      name: "11:00",
      value: "11.0011:00",
      form: true,
    },
    {
      name: "11:30",
      value: "11.3011:30",
      form: true,
    },
    {
      name: "12:00",
      value: "12.0012:00",
      form: true,
    },
    {
      name: "12:30",
      value: "12.30:12.30",
      form: true,
    },
    {
      name: "13:00",
      value: "13.0013:00",
      form: true,
    },
    {
      name: "13:30",
      value: "13.3013:30",
      form: true,
    },
    {
      name: "14:00",
      value: "14.0014:00",
      form: true,
    },
    {
      name: "14:30",
      value: "14.3014:30",
      form: true,
    },
    {
      name: "15:00",
      value: "15.0015:00",
      form: true,
    },
    {
      name: "15:30",
      value: "15.3015:30",
      form: true,
    },
    {
      name: "16:00",
      value: "16.0016:00",
      form: true,
    },
    {
      name: "16:30",
      value: "16.3016:30",
      form: true,
    },
    {
      name: "17:00",
      value: "17.0017:00",
      form: true,
    },
    {
      name: "17:30",
      value: "17.3017:30",
      form: true,
    },
    {
      name: "18:00",
      value: "18.0018:00",
      form: true,
    },
    {
      name: "18:30",
      value: "18.3018:30",
      form: true,
    },
    {
      name: "19:00",
      value: "19.0019:00",
      form: true,
    },
    {
      name: "19:30",
      value: "19.3019:30",
      form: true,
    },
  ];
  const miniteOptionsFun = () => {
    if (
      selectedDate.getDate() === date.getDate() &&
      selectedDate.getMonth() === date.getMonth()
    ) {
      miniteOptions = miniteOptions.filter(
        (item) => !(Number(item.value.slice(0,5)) < hoursMinute)
      );
      return miniteOptions;
    } else {
      return miniteOptions;
    }
  };

  const [
    fields,
    setValue,
    setFieldValid,
    formIsValid,
    trigger,
    setTrigger,
    setCheckValue,
    clearForm,
  ] = useForm({
    fio: {
      value: getValues().fio,
      name: "fio",
      type: "text",
      placeholder: "Иванов Иван Иванович",
      validate: "text",
      valid: getValues().fio || false,
    },
    phone: {
      value: getValues().phone,
      name: "phone",
      type: "tel",
      placeholder: "+7 (123) 456-78-90",
      validate: "phone",
      valid: getValues().phone || false,
      
    },
    email: {
      value: getValues().email,
      name: "email",
      type: "email",
      placeholder: "ivanov@mail.ru",
      validate: 'email',
      valid: getValues().email || false
     
    },
    text: {
      value: "",
      name: "text",
      type: "textarea",
      placeholder: "",
      //validate: 'text',
      valid: true,
    },
    select: {
      value: 0,
      name: "select",
      valid: false,
    },
    check: {
      value: false,
      name: "check",
      valid: false,
    },
  });

  let minuteTime = " ";


  if(fields.select.value){
    minuteTime = fields.select.value.slice(-5);
    //console.log(fields.select.value.slice(0, 5))
  }


  let selectMonth = Number(selectedDate.getMonth()) + 1;

  //console.log(selectedDate);
  let dateSend =
    selectedDate.getFullYear() +
    "-" +
    selectMonth +
    "-" +
    selectedDate.getDate() +
    " " +
    minuteTime;

  //console.log(dateSend);

  const send = async () => {
    setTrigger(true);
    if (!formIsValid() ) return false;

    const fd = new FormData();
    fd.append("FIO", fields.fio.value);
    fd.append("PHONE", fields.phone.value);
    fd.append("EMAIL", fields.email.value);
    fd.append("AGREE", fields.check.value);
    fd.append("ZKH", current_id);
    fd.append("MESSAGE", fields.text.value);
    fd.append("TIME", dateSend);

    axios
      .post((api || "") + "/ajax/json.php?page=videocall-form", fd)
      .then(({ data }) => data)
      .then((response) => {
        if (response.error) {
          dispatch(
            setPopupMessage({
              title: "Ошибка",
              text: response.message,
            })
          );
        } else {
          dispatch(
            setPopupMessage({
              title: "Спасибо!",
              text: "Ваша заявка на видеоконсультацию отправлена. Менеджер свяжется с вами в ближайшее время",
            })
          );
        }
      });

    clearForm();
  };

  const buildingsData = data.buildings.filter((item) => item.form);

  return (
    <>
      <div className="form-block">
        <div className="popup__title">
          Запись <br />
          <span>на видеоконсультацию </span>{" "}
        </div>

        <div className="input-title">Имя</div>
        <InputField
          trigger={trigger}
          setFieldValid={setFieldValid}
          setValue={setValue}
          className="filter-field__body"
          {...fields.fio}
        />
        <div className="sign-up-for-central-office__field">
          <div className="input-title">Телефон</div>
          <InputField
            trigger={trigger}
            setFieldValid={setFieldValid}
            setValue={setValue}
            {...fields.phone}
          />
        </div>
        <div className="input-title">E-mail</div>
        <InputField
          trigger={trigger}
          setFieldValid={setFieldValid}
          setValue={setValue}
          {...fields.email}
        />
        <div className="input-title">ЖК</div>
        <div
          dangerouslySetInnerHTML={{ __html: current_name }}
          className="video-call-form-JK"
        ></div>

        <div className="video__call-feeld-wrap">
          <div className="video-call-wrap-colum">
            <div className="input-title">Дата</div>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ru}>
              <DatePicker
                mask="__.__.____"
                minDate={new Date()}
                value={selectedDate}
                onChange={(newValue) => {
                  handleDateChange(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="video-call-wrap-colum video-call-time">
            <div className="input-title">Время МСК</div>
            <FormFieldSelectValid
              trigger={trigger}
              setFieldValid={setFieldValid}
              setValue={setValue}
              {...fields.select}
              options={miniteOptionsFun()}
            />
          </div>
        </div>
        <div className="input-title">Сообщение</div>
        <InputField
          trigger={trigger}
          setFieldValid={setFieldValid}
          setValue={setValue}
          {...fields.text}
        />
        <CheckboxField
          trigger={trigger}
          setFieldValid={setFieldValid}
          setValue={setCheckValue}
          {...fields.check}
        />

        <div className="filter-button">
          <div className="btn btn--border no-border-link" onClick={send}>
            <span>Отправить</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { sendVideoCall, sendBookingRequest })(
  VideoCallForm
);
